// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-dashboard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-height: 100vh;
  margin: 1rem;
  gap: 1rem;
}

.admin-dashboard-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: calc(50vh - 1.5rem);
  gap: 1rem;
  width: 100%;
}

.admin-dashboard-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 50%;
  height: 100%;
  border: 1px black solid;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/admin-dashboard/admin-dashboard.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,iBAAA;EACA,YAAA;EACA,SAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;EACA,SAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,qBAAA;EACA,UAAA;EACA,YAAA;EACA,uBAAA;AACJ","sourcesContent":[".admin-dashboard-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    max-height: 100vh;\n    margin:1rem;\n    gap: 1rem;\n}\n\n.admin-dashboard-row {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    height: calc(50vh - 1.5rem);\n    gap: 1rem;\n    width: 100%;\n}\n\n.admin-dashboard-col {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    align-content: center;\n    width: 50%;\n    height: 100%;\n    border: 1px black solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
