import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Spinner from '../../../../utils/spinner/spinner';
import { updateReportPdf } from '../../../report/store/action';
import link from '../../../../utils/assets/link.svg';

export default function CreateFullPdf({ scanObject }) {
    const dispatch = useDispatch();
    const [disabledPage, setDisabledPage] = useState(false);
    const [fullPdfLink, setFullPdfLink] = useState('');

    const saveScrollPosition = () => {
        const scrollableDiv = document.querySelector(".scrollable-div-table");
        if (scrollableDiv) {
            localStorage.setItem("scrollable-div-table-scrollPosition", scrollableDiv.scrollTop);
        }
    };

    const createPDF = async () => {
        setDisabledPage(true)
        let scansIds = [];
        console.log('createPDF', scanObject);

        scanObject.scansList.map(scan => {
            if (scan.statusCode == 200)
                scansIds.push(scan.scanId)
        })
        if (scansIds.length > 0) {
            const reportId = scanObject.reportId;
            if (reportId == undefined) {
                alert('לא נמצא דוח')
                setDisabledPage(false)
            }
            else {
                const obj = {
                    reportId: reportId,
                    userId: scanObject.userId,
                    scansIdsToPdf: scansIds,
                    locale: 'he',
                    isAddBrokenLetter: true,
                    isFullPdf: true,
                    reportLogId: scanObject._id,
                    isShowImageInNoErrorScan: true
                }
                console.log('obj😊😊😊', obj);
                const res = await dispatch(updateReportPdf(obj))
                console.log('res', res);
                setFullPdfLink(res)
                setDisabledPage(false)
                if (res) {
                    console.log('res',res);
                    window.open(res, '_blank')
                }
            }
        }
        else {
            alert(' אין הפקת דוח על סריקת שנכשלה')
            setDisabledPage(false)
        }
    }

    return (
        <div>
            {fullPdfLink ?
                <div className={`table-col col image-link`} onClick={() => window.open(fullPdfLink, '_blank')} >
                    <div className="data-link">
                        <img src={link} alt='link'></img>
                    </div>
                </div>
                :
                <div onClick={createPDF}>
                    הפק דוח מלא
                </div>
            }



            {
                disabledPage == true &&
                <Spinner value={100}></Spinner>
            }
        </div>
    )
}