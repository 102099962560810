import React, { useEffect, useRef, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar
} from "recharts";
import { BASE_URL, useLanguage } from "../../../utils/constants";
import { useDispatch } from "react-redux";
import {
  getAllPayments,
  getLastMonthPayments
} from "../../payments/store/action";
import axios from "axios";
import { getAllUsers, getLastMonthUsers } from "../../user/store/action";
import "./analytics.scss";
export default function Analytics() {
  const t = useLanguage();
  const dispatch = useDispatch();
  const [paymentsData, setPaymentsData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [scansData, setScansData] = useState([]);
  const [data, setData] = useState([]);
  const [listToGraph, setListToGraph] = useState([]);
  const [listToGraphOption, setListToGraphOption] = useState("payments"); //payments, scans, users
  console.log("listToGraphOption", listToGraphOption);

  const [option, setOption] = useState("7days"); //7days, 30days, 12month
  const arrayToLoop = [
    { header: "סריקות במערכת", list: scansData },
    { header: "משתמשים במערכת", list: usersData },
    { header: "תשלומים במערכת", list: paymentsData }
  ];
  useEffect(() => {
    const getData = async () => {
      try {
        // Run API calls in parallel
        const [
          paymentsResponse,
          cuponUsesResponse,
          usersResponse,
          scanResponse
        ] = await Promise.all([
          dispatch(getLastMonthPayments()),
          axios.post(
            `${BASE_URL}/api/cuponCodeUses/get_last_month_cupons_uses/`
          ),
          dispatch(getLastMonthUsers()),
          axios.post(`${BASE_URL}/api/reportlogs/get_last_month_scans/`)
        ]);

        // Set state after all requests complete
        setPaymentsData([
          {
            name: "כרטיס אשראי",
            [t.analytics.currentMonth]: paymentsResponse.last30Days,
            [t.analytics.previousMonth]: paymentsResponse.previous30Days
          },
          {
            name: "קוד קופון",
            [t.analytics.currentMonth]: cuponUsesResponse.data.last30Days,
            [t.analytics.previousMonth]: cuponUsesResponse.data.previous30Days
          }
        ]);

        setUsersData([
          {
            name: "משתמשים חדשים",
            [t.analytics.currentMonth]: usersResponse.last30Days,
            [t.analytics.previousMonth]: usersResponse.previous30Days
          }
        ]);

        setScansData([
          {
            name: "תפילין",
            [t.analytics.currentMonth]:
              scanResponse.data.last30DaysTefillinCount,
            [t.analytics.previousMonth]:
              scanResponse.data.previous30DaysTefillinCount
          },
          {
            name: "מזוזות",
            [t.analytics.currentMonth]: scanResponse.data.last30DaysMezuzaCount,
            [t.analytics.previousMonth]:
              scanResponse.data.previous30DaysMezuzaCount
          }
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, []);

  useEffect(
    () => {
      const getListToGraphData = async () => {
        let list;
        if (listToGraphOption == "payments")
          list = await dispatch(getAllPayments());
        else if (listToGraphOption == "users")
          list = await dispatch(getAllUsers());
        else if (listToGraphOption == "scans"){
          const response=await axios.post(`${BASE_URL}/api/reportlogs/get_last_year_scans/`);
          list = response.data.lastYear;
        }
        setListToGraph(list);
      };
      getListToGraphData();
    },
    [listToGraphOption]
  );

  useEffect(
    () => {
      const getFullData = async () => {
        if (listToGraph) {
          let groupedList;
          if (option == "7days") groupedList = groupLastDays(7, listToGraph);
          else if (option == "30days")
            groupedList = groupLastDays(30, listToGraph);
          else groupedList = groupLastYearByMonths(listToGraph);
          setData(groupedList);
        }
      };
      getFullData();
    },
    [option, listToGraph]
  );

  const groupLastDays = (numberOfDays, data) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize to midnight

    // Get the date 7 days ago
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - numberOfDays + 1); // Keep today in range

    // Generate last 7 days array
    const lastDays = [];
    for (let i = 0; i < numberOfDays; i++) {
      const date = new Date(sevenDaysAgo);
      date.setDate(sevenDaysAgo.getDate() + i);
      lastDays.push(date.toISOString().split("T")[0]); // Format as YYYY-MM-DD
    }

    // Count occurrences by date
    const grouped = data.reduce((acc, item) => {      
      const date = new Date(item.createdAt).toISOString().split("T")[0];
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    // Fill missing dates with count = 0
    return lastDays.map(date => ({ date, count: grouped[date] || 0 }));
  };

  const groupLastYearByMonths = data => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const startDate = new Date(today);
    startDate.setMonth(today.getMonth() - 11);

    const formatMonthYear = date => {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}`;
    };

    const sections = Array.from({ length: 12 }, (_, i) => {
      const month = new Date(startDate);
      month.setMonth(startDate.getMonth() + i);

      return { date: formatMonthYear(month), count: 0 };
    });

    data.forEach(item => {
      const date = new Date(item.createdAt);
      const monthYear = formatMonthYear(date);

      const section = sections.find(s => s.date === monthYear);
      if (section) {
        section.count += 1;
      }
    });

    return sections;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: "#000",
            color: "#fff",
            padding: "5px 10px",
            borderRadius: "5px"
          }}
        >
          {payload[1].value} | {payload[0].value}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="analitics-container">
      <div className="row analytics-row ">
        {arrayToLoop.map(item => {
          return (
            <div className="col">
              <div>
                {item.header}
              </div>
              <ResponsiveContainer height={300}>
                <BarChart data={item.list} barSize={30}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend
                    verticalAlign="top"
                    align="right"
                    payload={[
                      {
                        value: t.analytics.currentMonth,
                        type: "line",
                        color: "#536DF6"
                      },
                      {
                        value: t.analytics.previousMonth,
                        type: "line",
                        color: "rgba(83, 109, 246, 0.26)"
                      }
                    ]}
                  />
                  <Bar
                    dataKey={t.analytics.previousMonth}
                    fill="rgba(83, 109, 246, 0.26)"
                  />
                  <Bar dataKey={t.analytics.currentMonth} fill="#536DF6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          );
        })}
      </div>
      {/* ===================================== */}
      <div className={`graph-header`}>
        <select
          onChange={e => setListToGraphOption(e.target.value)}
          className="no-border-select"
        >
          <option value="payments">
            {t.analytics.payments}
          </option>
          <option value="users">
            {t.analytics.users}
          </option>
          <option value="scans">
            {t.analytics.scans}
          </option>
        </select>

        <div className={`options-wrapper`}>
          <div
            type="button"
            className={`option-button ${option == "7days" ? "active" : ""}`}
            onClick={() => setOption("7days")}
          >
            7 ימים
          </div>
          <div
            type="button"
            className={`option-button ${option == "30days" ? "active" : ""}`}
            onClick={() => setOption("30days")}
          >
            30 ימים
          </div>
          <div
            type="button"
            className={`option-button ${option == "12month" ? "active" : ""}`}
            onClick={() => setOption("12month")}
          >
            12 חודשים
          </div>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          <Line
            type="monotone"
            dataKey="count"
            stroke="#8884d8"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
