import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react";
import Header from "../../header/header";
import { fetchAllReportLogs } from "../../scans/store/action";
import './scan-management.scss'
import DataManagement from "../data-management/data-management";

export default function ScanManagement() {
    const dispatch = useDispatch();
    const scansListStore = useSelector((store) => store.scanReducer.allScans)
    const [allScansNumber, setAllScansNumber] = useState(0)
    console.log('scansListStore', scansListStore);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchAllReportLogs());
        };
        fetchData();
    }, []);

    useEffect(() => {
        const totalScans = scansListStore.reduce((acc, scan) => acc + (scan.scansList?.length || 0), 0);
        setAllScansNumber(totalScans);
    }, [scansListStore]);
   
    const headerListSortAndFilter = [
        { label: 'שם משתמש', type: 'userName', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
        { label: 'מס שגיאות', type: 'errorsNum', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
        { label: 'לינק לסריקה' },
        { label: 'לינק לדוח', type: 'pdfLink', action: 'filter', subOptions: ['link', 'all'], index: 0 },
        { label: 'תאריך הסריקה', type: 'createdAt', action: 'sort', subOptions: ['order', 'reverse'], index: 0 },
        { label: 'סטטוס', type: 'statusCode', action: 'filter', subOptions: ['200', '400', '500', 'all'], index: 0 },
    ]

    return (
        <>
            <div>

                <Header page={'data-management'} context={['כל הסריקות']}></Header>
                <DataManagement
                    dataType={'scan'}
                    dataList={scansListStore}
                    header={`${scansListStore.length} העלאות ( ${allScansNumber} סריקות)`}
                    headerList={headerListSortAndFilter}
                ></DataManagement>

            </div>

        </>
    )
}