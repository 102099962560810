// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overflow-div {
  scrollbar-width: auto;
}

.overflow-div::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

.overflow-div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.overflow-div::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/utils/show-image/show-image-style.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AAEA;EACE,UAAA;EACA,WAAA;AACF;;AAEA;EACE,oCAAA;EACA,mBAAA;AACF;;AAEA;EACE,oCAAA;AACF","sourcesContent":[".overflow-div {\n  scrollbar-width: auto;\n}\n\n.overflow-div::-webkit-scrollbar {\n  width: 3px;\n  height: 5px; \n}\n\n.overflow-div::-webkit-scrollbar-thumb {\n  background-color: rgba(0, 0, 0, 0.3);\n  border-radius: 10px \n}\n\n.overflow-div::-webkit-scrollbar-track {\n  background-color: rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
