import produce from "immer";
import { SET_IMAGES, SCANS_Of_CURRENT_FOLDER, CREATE_SCAN, SET_CURRENT_SCAN, FETCH_SCANS, SET_ACTIVE_IMG_INDEX, SET_SEARCH_QUERY, SCAN_IDS_TO_UPLOAD, DELETE_SCAN, FETCH_SCANS_OF_FOLDER, FETCH_ALL_SCANS, REPORT_LOGS_NUMBERS } from "./types";


const initialState = {
    currentScan: {},
    imagesUrl: {},
    allScans: [],
    scans: [],
    scansOfFolder: [],
    locale: 'he',
    activeImgIndex: 0,
    searchQuery: '',
    scansIdsToUpload: [],
    imageUrl: '',
    reportLogsNumbers: []
};

export const scanReducer = produce((initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_ALL_SCANS:
            initialState.allScans = payload;
            return initialState;
        case REPORT_LOGS_NUMBERS:
            initialState.reportLogsNumbers = payload;
            return initialState;
        case SCAN_IDS_TO_UPLOAD:
            initialState.scansIdsToUpload = payload;
            return initialState;
        case SET_IMAGES:
            initialState.imagesUrl[payload.imageId] = payload.url;
            return initialState;
        case SET_SEARCH_QUERY:
            initialState.searchQuery = payload.query;
            return initialState;
        case CREATE_SCAN:
            initialState.scans = payload.data;
            initialState.scansOfFolder = payload.data;
            initialState.scansIdsToUpload = [];
            return initialState;
        case SCANS_Of_CURRENT_FOLDER:
            initialState.scansOfFolder = payload;
            console.log('initialState.scansOfFolder❌❌❌',initialState.scansOfFolder);
            return initialState;
        case FETCH_SCANS:
            initialState.scans = payload;
            const currentProjectId = JSON.parse(sessionStorage.getItem('currentProjectId'))
            const scansOfFolder = payload?.filter(scan => scan.relatedProject == currentProjectId)
            initialState.scansOfFolder = scansOfFolder;
            return initialState;
        case FETCH_SCANS_OF_FOLDER:
            console.log('payload in FETCH_SCANS_OF_FOLDER', payload);
            initialState.scansOfFolder = payload;
            return initialState;
        case SET_CURRENT_SCAN:
            initialState.currentScan = payload
            return initialState;
        case SET_ACTIVE_IMG_INDEX:
            initialState.activeImgIndex = payload
            return initialState;
        case DELETE_SCAN:
            initialState.scans = payload.scans;
            initialState.scansOfFolder = payload.scansOfFolder;
            return initialState;
        default:
            return initialState;
    }
}, initialState)



