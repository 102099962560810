import { useRef, useState } from "react";
import './edit-user.scss'
import dropdownButton from '../../../utils/assets/dropdown-button.svg'
import { deleteUser, getCurrentUserById, updateUserDashboard } from "../../user/store/action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLanguage } from "../../../utils/constants";

export default function EditUser({ user, closeModal, updateUserInDataList, deleteUserInDataList }) {
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const isCurrentUserEffect = useRef(true);
    const t = useLanguage();
    const dispatch = useDispatch();
    const [isDelete, setIsDelete] = useState(false);
    const roleList = ['user', 'premium', 'admin', 'super-admin']
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        userId: user._id,
        customerName: user.customerName || '',
        email: user.email || '',
        phone: user.phone || '',
        balance: user.balance || 0,
        balanceInProgress: user.balanceInProgress || '',
        Role: user.Role || ''
    });

    useEffect(() => {
        if (currentUserId && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId, currentUser])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            ...(name === 'balance' && { balanceInProgress: value }),
        }));
    };

    const setUserData = (key, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [key]: value,
        }));
    }

    const updateUserFunc = async () => {
        closeModal()
        const res = await dispatch(updateUserDashboard(formData))
        console.log('res', res);

        if (res) {
            updateUserInDataList(res);
        }
    }

    const deleteUserFunc = async () => {
        closeModal()
        const res = await dispatch(deleteUser(user._id))
        if (res) {
            deleteUserInDataList(user);
        }
    }

    return (
        <>
            <form className="edit-user-container">
                <div >
                    <div className="edit-user-inputs-wrapper">
                        {Object.entries(formData).map(([key, value]) => (
                            !['Role', 'userId', 'balanceInProgress'].includes(key) &&
                            <div key={key}>
                                <input
                                    className="edit-user-input"
                                    type="text"
                                    name={key}
                                    value={value}
                                    onChange={handleChange}
                                    placeholder={value ? '' : t.admin[key]}
                                />
                            </div>
                        ))}
                    </div>

                    <div>
                        <div className="dropdown-container">
                            <div className="dropdown-button" onClick={() => setIsOpen(!isOpen)}>
                                <div>{formData.Role}</div>
                                <img src={dropdownButton} className="dropdown-button-img" alt='dropdown-button-img'></img>
                            </div>
                            {isOpen && (
                                <ul className="dropdown-list">
                                    {roleList.map((role) => {
                                        return (
                                            <li onClick={() => { setUserData('Role', role); setIsOpen(false) }}>{role}</li>
                                        )
                                    })}
                                </ul>
                            )}
                        </div>
                        <div type='button' className={`delete-user ${currentUser.Role == 'admin' ? 'disabled' : ''}`}
                            onClick={currentUser.Role === 'super-admin' ? () => setIsDelete(true) : undefined}
                        >מחיקת משתמש</div>

                    </div>
                </div>

                {isDelete && <div className='are-you-sure-delete'>
                    האם אתה בטוח שאתה רוצה למחוק
                    את המשתמש לצמיתות מהמערכת?
                    <div className="row-with-gap">
                        <div type='button' className='small-ok' onClick={deleteUserFunc}>אישור</div>
                        <div type='button' className="small-cancel" onClick={() => setIsDelete(false)}>ביטול</div>
                    </div>
                </div>}

                <div>
                    <button type="button" className={`blue-button `} onClick={updateUserFunc}>שמירת שינויים</button>
                    <button type="button" className="white-button" onClick={() => closeModal()}>ביטול</button>
                </div>
            </form>
        </>
    )
}