
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './crop-image-style.scss';
import nullImage from './../assets/null-image.svg';

const ImageCropper = ({ coordinates, originalImageWidth, originalImageHeight, showOriginalImage }) => {
    const canvasRef = useRef(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState('');
    const imageUrl = useSelector((store) => store.reportReducer.imageUrl);

    // Calculate the enlarged coordinates while ensuring they stay within image boundaries
    const calculateEnlargedCoordinates = () => {
        const x = Math.max(coordinates.x - 50, 0);
        const y = Math.max(coordinates.y - 30, 0);

        const width = coordinates.width + 100; // Add padding for width
        const height = coordinates.height + 60; // Add padding for height

        const adjustedWidth = Math.min(width, originalImageWidth - x); // Ensure it doesn't exceed image width
        const adjustedHeight = Math.min(height, originalImageHeight - y); // Ensure it doesn't exceed image height

        return { x, y, width: adjustedWidth, height: adjustedHeight };
    };

    const [enlargedCoordinates, setEnlargedCoordinates] = useState(calculateEnlargedCoordinates());

    useEffect(() => {
        setEnlargedCoordinates(calculateEnlargedCoordinates());
    }, [coordinates, originalImageWidth, originalImageHeight]);
    
    useEffect(() => {
        const image = new Image();
        image.crossOrigin = 'Anonymous';

        image.onload = function () {
            const width = this.width;
            const height = this.height;
            const widthScale = width / originalImageWidth;
            const heightScale = height / originalImageHeight;

            const canvas = canvasRef.current;
            if (canvas) {
                const context = canvas.getContext('2d');

                // Set the canvas size to match the enlarged area with red square boundary
                canvas.width = enlargedCoordinates.width * widthScale;
                canvas.height = enlargedCoordinates.height * heightScale;

                // Draw the enlarged area onto the canvas
                context.drawImage(
                    image,
                    enlargedCoordinates.x * widthScale,
                    enlargedCoordinates.y * heightScale,
                    enlargedCoordinates.width * widthScale,
                    enlargedCoordinates.height * heightScale,
                    0,
                    0,
                    enlargedCoordinates.width * widthScale,
                    enlargedCoordinates.height * heightScale
                );

                context.strokeStyle = 'red';
                context.lineWidth = 3;

                // Adjust the position of the red square within the enlarged area
                const redSquareX = (coordinates.x - enlargedCoordinates.x) * widthScale - 5;
                const redSquareY = (coordinates.y - enlargedCoordinates.y) * heightScale - 5;
                const redSquareWidth = coordinates.width * widthScale + 10;
                const redSquareHeight = coordinates.height * heightScale + 10;

                // Draw the red square
                context.strokeRect(redSquareX, redSquareY, redSquareWidth, redSquareHeight);

                const croppedUrl = canvas.toDataURL('image/jpeg');
                setCroppedImageUrl(croppedUrl);
            }
            else {
                console.error("Canvas element not found");
            }
        };
        image.src = imageUrl;

    }, [imageUrl, coordinates])
    return (
        <div>
            <img
                src={showOriginalImage ? croppedImageUrl : nullImage}
                style={{ width: 'auto', height: '4rem' }}
                alt='image'
            />
            <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>
    );
};

export default ImageCropper;
