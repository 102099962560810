import { useState } from 'react';
import './sponsers.scss'
import Header from '../../header/header';
import { BASE_URL, smallScreen } from '../../../utils/constants';
import axios from 'axios';
import trashcan from "../../../utils/assets/trashcan.svg";

export default function Sponsers() {
    const isSmallScreen = smallScreen();
    const [cuponCodeList, setCuponCodeList] = useState(false);

    const [sponserFormData, setSponserFormData] = useState({
        sponserName: '',
        validity: '',
        sponserLink: '',
        image: '',
        imageData: '',
    });

    const sponserHandleChange = (e) => {
        const { name, value } = e.target;
        setSponserFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const isSponserFormValid = () => {
        const { sponserName, validity, imageData } = sponserFormData;

        return (
            imageData &&
            sponserName.trim() !== '' &&
            validity.trim() !== ''
        );
    };
    console.log('isSponserFormValid', isSponserFormValid());

    const deleteCuponCode = async (cuponCodeId) => {
        const object = { cuponCodeId: cuponCodeId }
        const userConfirm = window.confirm('האם אתה בטוח?')
        if (userConfirm) {
            const response = await axios.post(`${BASE_URL}/api/cuponCode/delete/`, object);
            console.log('response 🙏🙏🙏🙏🙏', response);
            const updatedCuponCodeList = [...cuponCodeList].filter(item => item._id != cuponCodeId)
            setCuponCodeList(updatedCuponCodeList)
        }
    }


    const onSubmit = async () => {
        try {
            if (isSponserFormValid()) {
                console.log(sponserFormData);
                const response = await axios.post(`${BASE_URL}/api/sponser/add/`, sponserFormData);
                if (response) {
                    alert('ספונסר נוסף בהצלחה')
                    setSponserFormData({
                        sponserName: '',
                        validity: '',
                        sponserLink: '',
                        image: ''
                    })
                }
                else {
                    alert('ספונסר לא נוסף')
                }
            }
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Update the state with the file
            setSponserFormData((prevData) => ({
                ...prevData,
                image: file,
            }));

            // Generate a preview URL
            const reader = new FileReader();
            reader.onload = (event) => {
                setSponserFormData((prevData) => ({
                    ...prevData,
                    imageData: event.target.result,
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    return (

        <div className={`admin-contaier ${isSmallScreen ? 'mobile' : ''}`}>

            <Header page={'admin-edit'} context={['עריכה']}></Header>


            <div className={`admin-wrapper ${isSmallScreen ? 'mobile' : ''}`}>

                <div className={`admin-edit-data ${isSmallScreen ? 'mobile' : ''}`}>
                    <div className='cupon-code-header'>נותן חסות </div>
                    <form className='admin-edit-from-wrapper'>
                        <input
                            className="admin-edit-input"
                            type="text"
                            name={'sponserName'}
                            value={sponserFormData.sponserName}
                            onChange={sponserHandleChange}
                            placeholder={'שם החסות'}
                        />
                        <input
                            className="admin-edit-input"
                            type="text"
                            name={'sponserLink'}
                            value={sponserFormData.sponserLink}
                            onChange={sponserHandleChange}
                            placeholder={'לינק של בעל החסות'}
                        />
                        <input
                            className="admin-edit-input"
                            type="date"
                            name={'validity'}
                            value={sponserFormData.validity}
                            onChange={sponserHandleChange}
                            placeholder={'משך זמן החסות'}
                        />

                        {sponserFormData.imageData ? (
                            <img
                                src={sponserFormData.imageData}
                                alt="Sponser Preview"
                                className="image-preview"
                            />
                        )
                            :
                            <div className='file-upload-wrapper'>
                                <div className={'file-upload-container'}>
                                    <input
                                        type="file"
                                        id="file-upload"
                                        accept=".png, .jpg, .svg"
                                        className={'file-upload-input'}
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="file-upload" className={'file-upload-label'} >
                                        העלאת לוגו png / svg
                                    </label>
                                </div>
                            </div>
                        }


                    </form>
                </div>

                <div className={`admin-edit-data ${isSmallScreen ? 'mobile' : ''}`}>
                    <div className="cupon-code-header">קופונים פעילים במערכת</div>
                    <div className="cupon-list">
                        {cuponCodeList && cuponCodeList?.length > 0 ?
                            cuponCodeList.map((cupon) => {
                                return (
                                    <div className="name-trash-row">
                                        <div className="cupon-name"> {cupon.cuponCodeName} </div>
                                        <img src={trashcan} type='button' onClick={() => { deleteCuponCode(cupon._id) }} alt='trashcan button'></img>
                                    </div>
                                )
                            })
                            :
                            <div>לא נמצאו קופונים במערכת</div>
                        }
                    </div>

                </div>

            </div>

            <div className='admin-edit-button'>
                <button
                    type="button"
                    className={`blue-button ${isSponserFormValid() ? '' : 'disabled'} `}
                    onClick={onSubmit}
                    disabled={!isSponserFormValid()}
                >
                    שמירה
                </button>
            </div>


        </div>

    )
}