import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './scans-page-style.scss'
import ScanList from "../scan-list/scan-list";
import { useLocation, useNavigate } from "react-router-dom";
import { smallScreen, useLanguage } from "../../../utils/constants";
import Header from "../../header/header";
import { fetchScans, fetchScansByProjectId, scansIdsToUpload } from "../store/action";
import { getCurrentUserById } from "../../user/store/action";
import { useRef } from "react";
import { createReport, fetchReportByProjectId, setConfirmedScansIds, setPdf, setSortReportOcroutput, updateReportPdf } from "../../report/store/action";
import addScan from '../../../utils/assets/add-scan.svg'
import ViewPdf from "../../../utils/pdf/pdf-modal";
import ReactGA from 'react-ga4';
import Spinner from "../../../utils/spinner/spinner";
import Modal from "react-bootstrap/Modal";

export default function ScansPage({ }) {
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const currentProjectId = JSON.parse(sessionStorage.getItem('currentProjectId'));
    const currentProjectName = JSON.parse(sessionStorage.getItem('currentProjectName'));
    const scanType = JSON.parse(sessionStorage.getItem('scanType'));
    const reportStore = useSelector((store) => store.reportReducer.report);
    const currentReport = reportStore || {};
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const scanStore = useSelector((store) => store.scanReducer);
    const scansStore = useSelector((store) => store.scanReducer.scansOfFolder);
    let scansIdsToUploadArray = scanStore.scansIdsToUpload;
    const [disabledCreateReport, setDisabledCreateReport] = useState(false);
    const [disabledEditReport, setDisabledEditReport] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const t = useLanguage();
    const isSmallScreen = smallScreen();
    const isCurrentUserEffect = useRef(true);
    const isEffect = useRef(true);
    const [spinner, setSpinner] = useState(0)
    const [isShowPdf, setIsShowPdf] = useState('')
    const [reportId, setReportId] = useState('')
    const [pdfLinkIframe, setPdfLinkIframe] = useState('')
    const location = useLocation();
    const state = location?.state;
    const isShowErrorModalState = JSON.parse(sessionStorage.getItem('isShowErrorModal'))
    const [isShowErrorModal, setIsShowErrorModal] = useState(isShowErrorModalState || false);
    const isSome = state?.isSome || '';
    const isReportEffect = useRef(true)
    const [isShowList, setIsShowList] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const timerRef = useRef(null);
    useEffect(() => {
        setIsShowList(true);
        timerRef.current = setTimeout(() => {
            setIsShowList(false);
        }, 4000);

        return () => clearTimeout(timerRef.current);
    }, []);

    useEffect(() => {
        if (scansStore?.length > 0 && scansStore[0].relatedProject == currentProjectId) {
            console.log('👥👥👥👥👥👥👥👥');
            
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
            setIsShowList(false);
        }
    }, [scansStore]);

    useEffect(() => {
        if (currentUserId && !currentUser && isCurrentUserEffect.current) {
            isCurrentUserEffect.current = false;
            dispatch(getCurrentUserById(currentUserId));
        }
    }, [currentUserId])

    useEffect(() => {
        const fetchData = async () => {
            if (currentProjectId && currentUserId && isReportEffect.current) {
                await dispatch(fetchReportByProjectId({ userId: currentUserId, projectId: currentProjectId }))
                sessionStorage.setItem('isAddBrokenLetter', JSON.stringify(false))
            }
        }
        fetchData();
    }, [currentProjectId])

    useEffect(() => {
        const fetchData = async () => {
            if (currentProjectId && currentUserId && isReportEffect.current) {
                await dispatch(fetchReportByProjectId({ userId: currentUserId, projectId: currentProjectId }))
            }
        }
        fetchData();
    }, [])

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    useEffect(() => {
        async function fetchScansFunc() {
            console.log('scansStore', scansStore);
            
            if (currentProjectId && isEffect.current == true && scansStore.length == 0) {
                console.log('♥️😊♥️😊');

                isEffect.current = false
                await dispatch(fetchScansByProjectId(currentProjectId))
            }
        }

        fetchScansFunc()
    }, [currentProjectId]);

    useEffect(() => {
        scansIdsToUploadArray = scanStore?.scansIdsToUpload;
    }, [scanStore.scansIdsToUpload])

    const createReportAndEditReport = async () => {
        try {
            if (scansIdsToUploadArray?.length > 0) {
                setDisabledEditReport(true);
                setSpinner(scansIdsToUploadArray.length)
                const report = await createReportFunc();
                if (report) {
                    console.log('report😊😊', report);
                    setReportId(report._id)
                    sessionStorage.setItem('scansIdsToReport', JSON.stringify(scansIdsToUploadArray))
                    const updatedReport = {
                        ...report,
                        ocrOutput: report.ocrOutput?.filter((scan) => scansIdsToUploadArray.includes(scan.scanId))
                    };
                    console.log('❣️❣️❣️❣️', updatedReport);
                    dispatch(setSortReportOcroutput(updatedReport))
                    setDisabledEditReport(false);
                    setSpinner(0);
                    await dispatch(scansIdsToUpload([]));
                    navigate(`/report-page`);
                }
                if (currentUser.Role == 'user')
                    ReactGA.event({
                        category: 'Button',
                        action: 'סינון דוח שגיאות בעמוד סריקות',
                        label: 'עמוד סריקות',
                        value: 1
                    });
            }
            else {
                alert(t.scans.chooseScans)
            }

        }
        catch (error) {
            console.log('error in editReportFunc', error);
        }
    }

    const createReportAndExportPDF = async () => {
        try {
            if (scansIdsToUploadArray?.length > 0) {
                setDisabledCreateReport(true);
                setSpinner(scansIdsToUploadArray.length)
                const report = await createReportFunc();
                console.log('report', report);

                if (report) {
                    setReportId(report._id)
                    const pdfLink = await createUpdateReportPdf(report._id);
                    console.log('pdfLink', pdfLink);
                    setPdfLinkIframe(pdfLink)
                    if (pdfLink) {
                        await dispatch(scansIdsToUpload([]));
                        dispatch(setPdf(pdfLink))
                        setIsShowPdf(pdfLink);
                        setDisabledCreateReport(false);
                        setSpinner(0);
                    }
                    else (alert('תקלה'))
                }
                else (alert('תקלה'))
                if (currentUser.Role == 'user')
                    ReactGA.event({
                        category: 'Button',
                        action: 'הפקת דוח סיכום בעמוד סריקות',
                        label: 'עמוד סריקות',
                        value: 1
                    });
            }
            else {
                alert(t.scans.chooseScans)
            }

        }
        catch (error) {
            console.log('error', error);
        }
    }

    const createReportFunc = async () => {
        console.log('createReportFunc');
        try {
            if (currentProjectId === currentReport.relatedProject) { //the current report
                console.log('currentReport😊😊😊😊😊', currentReport);
                return currentReport;
            }
            else {//other project- check if it already have report or no
                console.log('other project- check if it already have report or no');
                const report = await dispatch(fetchReportByProjectId({ userId: currentUserId, projectId: currentProjectId }))
                console.log('report in createReportFunc❌❌❌❌', report);
                if (report != null && report != undefined) {//old report from database
                    console.log('old report from database');
                    return report;
                }
                else {//create new report
                    console.log('👍🏻create new report');
                    const allUsersScans = await dispatch(fetchScans(currentUserId));
                    const scans = allUsersScans?.filter((scan) => scansIdsToUploadArray.includes(scan._id));
                    const scansObjects = []
                    scans.map((scan => {
                        const obj = {
                            userId: currentUserId,
                            scanId: scan._id,
                            scanName: scan.name,
                            relatedProject: scan.relatedProject,
                            image: scan.images[0],
                            type: scan.type
                        }
                        scansObjects.push(obj)
                    }))
                    const report = await dispatch(createReport({ scans: scansObjects, scanType: scanType, locale: language, userName: currentUser?.customerName, projectName: currentProjectName }))
                    if (report) {
                        console.log('report❤️', report);
                    }
                    else {
                        setDisabledCreateReport(false);
                        //todo- why does it happen?- its happen when the first call to  pyhton failed, and this is the second call.
                        //here to send all scans again to python
                        console.log('nooooooooooooooooooooooooooooooooooo');
                    }
                    dispatch(setConfirmedScansIds([]))
                    return report;
                }

            }
        }
        catch (error) {
            console.log('🥴', error);
            return null;
        }
    }

    const createUpdateReportPdf = async (reportId) => {
        console.log('currentUser?.isShowImageInNoErrorScan', currentUser?.isShowImageInNoErrorScan, currentUser);

        const pdfReport = await dispatch(updateReportPdf({
            reportId: reportId, userId: currentUserId, scansIdsToPdf: scansIdsToUploadArray, locale: language, isAddBrokenLetter: currentUser?.isAddBrokenLetter || false, isShowImageInNoErrorScan: currentUser?.isShowImageInNoErrorScan, isAddBrokenLetter: currentUser?.isAddBrokenLetter || false, isShowImageInNoErrorScan: currentUser?.isShowImageInNoErrorScan
        }))
        setDisabledCreateReport(false);
        if (pdfReport) {
            return pdfReport;
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex + 1) % 3);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const closeErrModal = () => {
        setIsShowErrorModal(false);
        sessionStorage.setItem('isShowErrorModal', JSON.stringify(false))
    }

    return (
        <div className={`container`}>

            <div className={isSmallScreen ? 'row wrapperMobile  ' : "row wrapper "}>
                <div className="col">
                    <Header page={'scans-page'} context={scanType ? [' ', t.scanTypes[scanType], ">", currentProjectName] : ['']} type={scanType ? scanType : ''}></Header>

                    <div className='scans-button-wrapper'>
                        {!isSmallScreen && <div type="button" className={`createReport  ${disabledCreateReport == true ? ' disabled' : ''}`} disabled={disabledCreateReport == true} onClick={() => createReportAndExportPDF()}>
                            <div className={`txtCreate ${disabledCreateReport == true ? ' disabled' : ''}`}>{t.scans.createReport}</div>
                        </div>}

                        <div type="button" className={`editReport ${isSmallScreen ? 'col-12 mobile' : ''} ${disabledEditReport == true ? ' disabled' : ''} mb-1`} disabled={disabledEditReport == true} onClick={() => createReportAndEditReport()}>
                            <div className={`txtCreate edit ${disabledEditReport == true ? ' disabled' : ''}`} >{t.scans.editReport}</div>
                        </div>

                        {isSmallScreen && <div type="button" className={`createReport ${disabledCreateReport == true ? ' disabled' : ''}`} disabled={disabledCreateReport == true} onClick={() => createReportAndExportPDF()}>
                            <div className={`txtCreate ${isSmallScreen ? 'mobile' : ''} ${disabledCreateReport == true ? ' disabled' : ''}`}>{t.scans.createReport}</div>
                        </div>}
                    </div>

                    <div className={`scrollable-div ${isSmallScreen ? '' : 'folders'}`}>
                        {isShowList ?
                            <div className="slider">
                                <div className={`dot ${activeIndex === 0 ? 'active' : ''}`}></div>
                                <div className={`dot ${activeIndex === 1 ? 'active' : ''}`}></div>
                                <div className={`dot ${activeIndex === 2 ? 'active' : ''}`}></div>
                            </div>
                            : scansStore?.length > 0 ?
                                <ScanList scans={scansStore}></ScanList>
                                :
                                <div className="text-center mt-2">{t.scan.noScans}</div>
                        }
                    </div>

                    <div className={`addScan ${currentLanguage == 'en-US' ? 'ltr' : ''}`} onClick={() => { navigate('/upload-scan') }}>
                        <div className="addScanContent" type='button'>
                            <img src={addScan} className="add-scan-btn" alt='add-scan-btn'></img>
                        </div>
                    </div>


                </div>

            </div>

            {/* view pdf modal */}
            <ViewPdf isShowPdf={isShowPdf} setIsShowPdf={setIsShowPdf} reportId={reportId} projectName={currentProjectName}></ViewPdf>

            {/* spinner on disabled */}
            {(disabledCreateReport == true || disabledEditReport == true) && (
                <Spinner value={spinner}></Spinner>
            )}


            {/* error modal */}
            {/* <Modal
                show={isShowErrorModal}
                onHide={closeErrModal}
                centered
                className={`modal ${isSmallScreen ? "" : `right-margin ${currentLanguage == "en-US" ? "ltr" : ""}`}`}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="images-not-uploaded">
                        {isSome}
                    </div>
                    {isSmallScreen ? t.uploadScans.imagesNotUploadMessageMobile : t.uploadScans.imagesNotUploadMessage}
                </Modal.Body>
            </Modal> */}
        </div>

    )
}