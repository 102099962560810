// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analitics-container .analytics-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100vw - 16rem);
  max-width: calc(100vw - 16rem);
  gap: 1rem;
  flex-wrap: nowrap;
}
.analitics-container .graph-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.analitics-container .options-wrapper {
  display: flex;
  flex-direction: row;
}
.analitics-container .option-button {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--light-grey-light, #d9e1e7);
  background: var(--light-white, #fff);
}
.analitics-container .option-button.active {
  background: var(--primary-colors-light, #ebedfc);
  color: black;
}
.analitics-container .option-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
.analitics-container .no-border-select {
  border: none;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/analytics/analytics.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,uBAAA;EACA,0BAAA;EACA,8BAAA;EACA,SAAA;EACA,iBAAA;AAAJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAJ;AAEE;EACE,aAAA;EACA,mBAAA;AAAJ;AAEE;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,kDAAA;EACA,oCAAA;AAAJ;AAEE;EACE,gDAAA;EACA,YAAA;AAAJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;AAAJ;AAEE;EACE,YAAA;EACA,aAAA;AAAJ","sourcesContent":[".analitics-container {\n  .analytics-row {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: flex-start;\n    width: calc(100vw - 16rem);\n    max-width: calc(100vw - 16rem);\n    gap: 1rem;\n    flex-wrap: nowrap;\n  }\n  .graph-header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n  }\n  .options-wrapper {\n    display: flex;\n    flex-direction: row;\n  }\n  .option-button {\n    padding: 0.5rem;\n    display: flex;\n    flex-direction: column;\n    border: 1px solid var(--light-grey-light, #d9e1e7);\n    background: var(--light-white, #fff);\n  }\n  .option-button.active {\n    background: var(--primary-colors-light, #ebedfc);\n    color: black;\n  }\n  .option-arrow {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 0.5rem;\n    cursor: pointer;\n  }\n  .no-border-select {\n    border: none;\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
