import React from 'react';
import link from '../../../../utils/assets/link.svg';
import { formatDate } from '../../../../utils/constants';
import CreateFullPdf from '../create-full-pdf/create-full-pdf';
import trashcan from "../../../../utils/assets/trashcan.svg";

const ScanRow = ({ item, getUser }) => (
    <div className="table-row">
        {/* <div className="table-col chooseLine"><input type="checkbox" /></div> */}
        <div className="table-col col image-link" onClick={() => getUser ? getUser(item.userId) : undefined}>{item.userName}</div>
        <div className="table-col col">
            {item.scansList.map((scan, index) => (
                <div key={index} className='margin-6'>
                    {scan.statusCode == 200 && scan.numOfErrors}
                </div>
            ))}
        </div>
        <div className="table-col col">
            {item.scansList.map((scan, index) => (
                <div key={index} className="data-link scan margin-3" onClick={() => window.open(scan.scanLink, '_blank')}>
                    <div>{scan.scanName}</div>
                    <img src={link} alt='link'></img>
                </div>
            ))}
        </div>

        <div className={`table-col col ${item.pdfLink ? 'image-link' : ''}`} onClick={() => item.pdfLink ? window.open(item.pdfLink, '_blank') : null}>
            {item.pdfLink ?
                <div className="data-link">
                    <img src={link} alt='link'></img>
                </div>
                : 'לא הופק דוח'}
        </div>
        <div className={`table-col col image-link`} onClick={() => item.fullPdfLink ? window.open(item.fullPdfLink, '_blank') : null} >
            {item.fullPdfLink ?
                <div className="data-link">
                    <img src={link} alt='link'></img>
                </div>
                :
                <CreateFullPdf scanObject={item}></CreateFullPdf>
            }
        </div>
        <div className="table-col col">{formatDate(item.createdAt)}</div>
        <div className="table-col  col">
            {item.scansList.map((scan, index) => (
                <div key={index} className='dot-and-text margin-6'>
                    <div className={scan.statusCode === 200 ? 'dot green' : scan.statusCode === 400 ? 'dot red' : 'dot orange'}></div>
                    <div>{scan.statusCode === 200 ? 'הצליח' : scan.statusCode === 400 ? 'נכשל' : 'גישה נכשלה'}</div>
                </div>
            ))}
        </div>

    </div>

);

const UserRow = ({ item, setIsShowSideModal, editUser, isSuperAdmin }) => (
    <div className="table-row">
        {/* <div className="table-col chooseLine"><input type="checkbox" /></div> */}
        <div className="table-col col">{item.customerName}</div>
        <div className="table-col col">{item?.Role}</div>
        <div className="table-col col">{item.email}</div>
        <div className="table-col col">{item.phone}</div>
        <div className="table-col col">{formatDate(item.createdAt)}</div>
        <div className="table-col col">{item.balance}</div>
        {isSuperAdmin &&
            <div className="table-col edit-button" onClick={() => setIsShowSideModal(item)}><img src={editUser} alt="Edit" /></div>
        }
    </div>
);

const PaymentRow = ({ item, getUser, deletePayment, isSuperAdmin }) => (
    <div className="table-row">
        {/* <div className="table-col chooseLine"><input type="checkbox" /></div> */}
        <div className="table-col col image-link" onClick={() => getUser ? getUser(item.userId) : undefined}>{item.userName || item.userId}</div>
        <div className="table-col col">{item.paymentOption}</div>
        <div className="table-col col">{formatDate(item.createdAt)}</div>
        <div className="table-col col">{item.amount}</div>
        <div className="table-col  col">
            <div className='dot-and-text margin-6'>
                <div className={item.status === '000' ? 'dot green' : 'dot red'}></div>
                <div>{item.status === '000' ? 'הצליח' : 'נכשל'}</div>
            </div>
        </div>
        {isSuperAdmin &&
        <div className="table-col edit-button" type='button' onClick={() => deletePayment(item._id)}>
           <img src={trashcan} alt='trashcan button' className='red-trashcan'></img>
        </div>
        }
    </div>
);

const CuponRow = ({ item, getUser, deleteCuponUse ,isSuperAdmin}) => (
    <div className="table-row">
        {/* <div className="table-col chooseLine"><input type="checkbox" /></div> */}
        <div className="table-col col image-link" onClick={() => getUser(item.userId)}>{item.userName}</div>
        <div className="table-col col">{item.cuponCodeName}</div>
        <div className="table-col col">{item.sumBeforeCupon}</div>
        <div className="table-col col">{item.sum}</div>
        <div className="table-col col">{formatDate(item.createdAt)}</div>
        {isSuperAdmin &&
         <div className="table-col edit-button" type='button' onClick={() => deleteCuponUse(item._id)} >
            <img src={trashcan} alt='trashcan button' className='red-trashcan'></img>
         </div>
        }
    </div>
);


const TableRows = ({ dataType, items, getUser, deletePayment, deleteCuponUse, setIsShowSideModal, editUser, isSuperAdmin }) => {
    return (
        <div className="scrollable-div-table">
            {items.map((item, index) => (
                dataType === 'scan' ? (
                    <ScanRow key={index} item={item} getUser={getUser} />
                ) : dataType === 'user' ? (
                    <UserRow key={index} item={item} setIsShowSideModal={setIsShowSideModal} editUser={editUser} isSuperAdmin={isSuperAdmin} />
                ) : dataType === 'cupon' ? (
                    <CuponRow key={index} item={item} getUser={getUser} deleteCuponUse={deleteCuponUse} isSuperAdmin={isSuperAdmin}/>
                ) : dataType === 'payment' ? (
                    <PaymentRow key={index} item={item} getUser={getUser} deletePayment={deletePayment} isSuperAdmin={isSuperAdmin}/>
                ) : null
            ))}
        </div>
    );
};

export default TableRows;

