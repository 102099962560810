import './one-page-style.scss'
import React, { lazy, Suspense } from 'react';
import ReactPlayer from 'react-player';
import OnePage3 from '../../utils/assets/one-page-3.png'
import OnePage4 from '../../utils/assets/one-page-img4.svg'//////
import OnePageArrow from '../../utils/assets/one-page-arrow.svg'
import OnePageArrowUp from '../../utils/assets/one-page-arrow-up.svg'
import OnePageArrowPrice from '../../utils/assets/one-page-arrow-price.svg'
import OnePageArrowPriceMobile from '../../utils/assets/one-page-arrow-price-mobile.svg'
import step1 from '../../utils/assets/step1.svg'
import step1Mobile from '../../utils/assets/step1-mobile.svg'
import step1Num from '../../utils/assets/step-1-number.png'
import step2 from '../../utils/assets/step2.svg'
import step2Mobile from '../../utils/assets/step2-mobile.svg'
import step2Num from '../../utils/assets/step-2-number.png'
import step3 from '../../utils/assets/step3.svg'
import step3Mobile from '../../utils/assets/step3-mobile.svg'
import step3Num from '../../utils/assets/step-3-number.png'
import logo from '../../utils/assets/logo.svg'
import logo1 from '../../utils/assets/logo1.svg'
import gmail from '../../utils/assets/gmail.svg'
import watsapp from '../../utils/assets/watsapp.svg'
import otVehadar from '../../utils/assets/ot-vehadar.svg'
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage, getCurrentUserById } from '../user/store/action';
import { useEffect, useRef, useState } from 'react';
import { getEmail, getPhone, getPhoneNumber, getVideoLink, smallScreen, useLanguage } from '../../utils/constants';
import Modal from "react-bootstrap/Modal";
import copy from '../../utils/assets/copy.svg'
import confirmedCopy from '../../utils/assets/confirmed-copy.svg'
import concatImg from '../../utils/assets/concat-img.svg'
import menuMobile from '../../utils/assets/menu-mobile.svg';
import user from '../../utils/assets/user.svg';

export default function OnePage() {
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const videoUrl = getVideoLink();
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const isCurrentUserEffect = useRef(true)
    const [answerIndex, setAnswerIndex] = useState(0);
    const dispatch = useDispatch();
    const t = useLanguage();
    const isSmallScreen = smallScreen();
    const qAndaIndexes = [1, 2, 3, 4, 5]
    const [isFormClick, setIsFormClick] = useState(false);
    const [isCopyPhone, setIsCopyPhone] = useState(false);
    const [isCopyEmail, setIsCopyEmail] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [header, setHeader] = useState('');
    const [message, setMessage] = useState('');
    const [isMobileLinks, setIsMobileLinks] = useState(false);
    const [activeSection, setActiveSection] = useState(null);
    const sectionHomeRef = useRef(null);
    const sectionStepsRef = useRef(null);
    const sectionAboutRef = useRef(null);
    const sectionQuestionRef = useRef(null);
    const sectionVideoRef = useRef(null);
    const sectionConcatRef = useRef(null);
    const sectionPricesRef = useRef(null);

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    useEffect(() => {
        if (currentUserId && !currentUser && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId])

    const openGmail = () => {
        const recipient = getEmail();
        const mailto_link = 'https://mail.google.com/mail/?view=cm&fs=1&to=' + recipient;

        window.open(mailto_link, '_blank');
    }

    const openWhatsApp = () => {
        const phoneNumber = getPhoneNumber();
        const whatsappLink = 'https://wa.me/' + phoneNumber;

        window.open(whatsappLink, '_blank');
    }

    const scrollToSection = (sectionRef) => {
        setActiveSection(sectionRef)
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className=' one-page-container'>
            {/* header */}
            <div className={`one-page-header ${isSmallScreen ? 'mobile' : ''}`}>

                {(!isSmallScreen) && <div className={isSmallScreen ? 'one-page-header-links-mobile' : `one-page-header-links`}>
                    <div type='button' onClick={() => scrollToSection(sectionHomeRef)}><img className={isSmallScreen ? 'img-logo-1-mobile' : 'img-logo-1'} src={logo1} alt='img logo'></img></div>
                    <div type='button' className={`one-page-link ${isSmallScreen ? 'mobile' : ''} pointer ${activeSection == sectionHomeRef ? 'underline' : ''}`} onClick={() => scrollToSection(sectionHomeRef)}>{t.onePage.home}</div>
                    <div type='button' className={`one-page-link ${isSmallScreen ? 'mobile' : ''} pointer ${activeSection == sectionAboutRef ? 'underline' : ''}`} onClick={() => scrollToSection(sectionAboutRef)}>{t.onePage.about}</div>
                    <div type='button' className={`one-page-link ${isSmallScreen ? 'mobile' : ''} pointer ${activeSection == sectionVideoRef ? 'underline' : ''}`} onClick={() => scrollToSection(sectionVideoRef)}>{t.onePage.Instruction}</div>
                    <div type='button' className={`one-page-link ${isSmallScreen ? 'mobile' : ''} pointer ${activeSection == sectionQuestionRef ? 'underline' : ''}`} onClick={() => scrollToSection(sectionQuestionRef)}>{t.onePage.questions}</div>
                    <div type='button' className={`one-page-link ${isSmallScreen ? 'mobile' : ''} pointer ${activeSection == sectionConcatRef ? 'underline' : ''}`} onClick={() => scrollToSection(sectionConcatRef)}>{t.onePage.contact}</div>
                </div>}

                <div className={`one-page-header-buttons ${isSmallScreen ? 'mobile' : ''}`}>
                    {isSmallScreen && <img src={menuMobile} onClick={() => setIsMobileLinks(!isMobileLinks)} alt='menu mobile'></img>}

                    <div className='one-page-header-buttons-a'>
                        {isSmallScreen ?
                            <button className={'header-btn a mobile'} onClick={() => { window.location.pathname = '/login'; localStorage.setItem('isFirstTime', JSON.stringify(false)); }}>
                                <img src={user} alt='user'></img>
                            </button>
                            :
                            <button className={`header-btn a`} onClick={() => { window.location.pathname = '/login'; localStorage.setItem('isFirstTime', JSON.stringify(false)); }}>{t.onePage.login}</button>
                        }
                        <button className='header-btn ' onClick={() => { window.location.pathname = '/register'; localStorage.setItem('isFirstTime', JSON.stringify(false)); }}>{t.onePage.openAcount}</button>
                    </div>
                </div>
            </div>

            {((isSmallScreen && isMobileLinks == true)) && <div className={isSmallScreen ? 'one-page-header-links-mobile' : `one-page-header-links`}>
                <div type='button' onClick={() => scrollToSection(sectionHomeRef)}><img className={'img-logo-1-mobile'} src={logo1} alt='img logo'></img></div>
                <div type='button' className={`one-page-link ${isSmallScreen ? 'mobile' : ''} pointer ${activeSection == sectionHomeRef ? 'underline' : ''}`} onClick={() => scrollToSection(sectionHomeRef)}>{t.onePage.home}</div>
                <div type='button' className={`one-page-link ${isSmallScreen ? 'mobile' : ''} pointer ${activeSection == sectionAboutRef ? 'underline' : ''}`} onClick={() => scrollToSection(sectionAboutRef)}>{t.onePage.about}</div>
                <div type='button' className={`one-page-link ${isSmallScreen ? 'mobile' : ''} pointer ${activeSection == sectionVideoRef ? 'underline' : ''}`} onClick={() => scrollToSection(sectionVideoRef)}>{t.onePage.Instruction}</div>
                <div type='button' className={`one-page-link ${isSmallScreen ? 'mobile' : ''} pointer ${activeSection == sectionQuestionRef ? 'underline' : ''}`} onClick={() => scrollToSection(sectionQuestionRef)}>{t.onePage.questions}</div>
                <div type='button' className={`one-page-link ${isSmallScreen ? 'mobile' : ''} pointer ${activeSection == sectionConcatRef ? 'underline' : ''}`} onClick={() => scrollToSection(sectionConcatRef)}>{t.onePage.contact}</div>
            </div>}

            {/* big image */}
            <div className='background-div' ref={sectionHomeRef}>
                <div class={`content ${isSmallScreen ? 'mobile' : ''}`}>
                    <div className={`content1 ${isSmallScreen ? 'mobile' : ''}`}>STAM SCANNER</div>
                    <div className={`content4 ${isSmallScreen ? 'mobile' : ''}`}>{t.onePage.content4}</div>
                    <div className={`content2 ${isSmallScreen ? 'mobile' : ''}`}>{t.onePage.content2}</div>
                    <div className={`content3 ${isSmallScreen ? 'mobile' : ''}`}>{t.onePage.content3}</div>
                    <button className='content-button'>
                        <div type='button' className='pointer' onClick={() => scrollToSection(sectionPricesRef)}>{t.onePage.moreDetails}</div>
                    </button>
                </div>
            </div>

            {/* steps */}
            <div className='one-page-text' ref={sectionStepsRef}>{t.onePage.howItWorks}</div>
            <div className='steps-wrapper'>
                <div className={`step a ${isSmallScreen ? 'mobile' : ''}`}>
                    <div className={`number-text-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                        <img src={step1Num} className='scan-number' alt='scan number'></img>
                        <div className={`step-text a ${isSmallScreen ? 'mobile' : ''}`}>{t.onePage.scans}</div>
                    </div>
                    <img src={isSmallScreen ? step1Mobile : step1} className={`scan-img a ${isSmallScreen ? 'mobile' : ''}`} alt='scan img'></img>
                </div>
                <div className={`step b ${isSmallScreen ? 'mobile' : 'pad'}`}>
                    <img src={isSmallScreen ? step2Mobile : step2} className={`scan-img b ${isSmallScreen ? 'mobile' : ''}`} alt='scan img'></img>
                    <div className='number-text-wrapper-2'>
                        <div className={`step-text ${isSmallScreen ? 'mobile' : ''}`}>{t.onePage.cut}</div>
                        <img src={step2Num} className='scan-number' alt='scan number'></img>
                    </div>
                </div>
                <div className={`step c ${isSmallScreen ? 'mobile' : 'pad'}`}>
                    <div className='number-text-wrapper-3'>
                        <img src={step3Num} className='scan-number' alt='scan number'></img>
                        <div className={`step-text ${isSmallScreen ? 'mobile' : ''}`}>{t.onePage.getReport}</div>
                    </div>
                    <img src={isSmallScreen ? step3Mobile : step3} className={`scan-img c ${isSmallScreen ? 'mobile' : ''}`} alt='scan img'></img>
                </div>
            </div>

            {/* price */}
            <div className={isSmallScreen ? 'price-wrapper-mobile' : 'price-wrapper'} ref={sectionPricesRef}>
                {!isSmallScreen &&
                    <img src={OnePage4} className='one-page-img2' alt='img'></img>
                }
                <div className={`price-text-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                    <div className={`one-page-text-wrapper ${isSmallScreen ? 'mobile' : ''} small`}>
                        <div className=''>{t.onePage.sale}</div>
                    </div>
                    <div className={`one-page-text-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                        {t.onePage.scansFree}
                    </div>
                    <img src={isSmallScreen ? OnePageArrowPriceMobile : OnePageArrowPrice} className={isSmallScreen ? 'middle-arrow-mobile' : 'middle-arrow'} alt='arrow'></img>
                </div>
            </div>

            <div className='open-account'>
                <button className='header-btn' onClick={() => { window.location.pathname = '/register'; localStorage.setItem('isFirstTime', JSON.stringify(false)); }}>{t.onePage.openAcount}</button>
            </div>

            {/* video */}
            <div className="player-wrapper" ref={sectionVideoRef}>
                <ReactPlayer
                    className="react-player"
                    url={videoUrl}
                    width="auto"
                    height="100vh"
                    controls
                />
            </div>

            {/* questions and answers */}
            <div className='one-page-text' ref={sectionQuestionRef}>{t.onePage.questionsAndAnswer}</div>
            <div className={`quest-and-answ ${isSmallScreen ? 'mobile' : ''}`}>
                <div className={`data-wrapper-one-page  ${isSmallScreen ? 'mobile' : ''}`}>
                    {qAndaIndexes.map((index) => {
                        return (
                            <div>
                                <div type='button' className={`question  ${answerIndex == index ? 'open' : ''} ${answerIndex == index + 1 ? 'no-border' : ''}  ${isSmallScreen ? 'mobile' : ''}`} onClick={() => setAnswerIndex(answerIndex == index ? 0 : index)}>
                                    <div type='button'>{t.onePageQuestions[index]}</div>
                                    <img src={answerIndex == index ? OnePageArrowUp : OnePageArrow} alt='arrow'></img>
                                </div>
                                {answerIndex == index && <div className='answer'> {t.onePageAnswers[index]} </div>}
                            </div>
                        )
                    })}
                </div>
                <img src={OnePage3} className={isSmallScreen ? 'one-page-img-3-mobile' : 'one-page-img-3'} alt='one page image'></img>
            </div>

            <div className='open-account b'>
                <div className='header-btn' onClick={() => scrollToSection(sectionPricesRef)}>לפרטים נוספים</div>
            </div>

            {/* about */}
            <div ref={sectionAboutRef} className='one-page-text'>{t.onePage.about}</div>
            <div className='about-area'>
                <div className={isSmallScreen ? 'about-container-mobile' : 'about-container'}>
                    <div className={isSmallScreen ? 'about-wrapper-mobile' : `about-wrapper `}>
                        <div className='about-header'>{t.onePage.aboutHeader}</div>
                        {t.onePage.aboutText}
                    </div>
                </div>
            </div>

            {/* Partners */}
            <div className='one-page-text'>{t.onePage.ourPartners}</div>
            <div className={`ot-vehadar-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                <img src={otVehadar} alt='sponser image'></img>
                <div>
                    <div className='ot-vehadar-text-blue'>{t.onePage.otVehadar}</div>
                    <div className={`ot-vehadar-text ${isSmallScreen ? 'mobile' : ''}`}>{isSmallScreen ? t.onePage.otVehadarTextMobile : t.onePage.otVehadarText}</div>
                </div>
            </div>

            {/* concat */}
            <div ref={sectionConcatRef} className={'one-page-text'}>{t.onePage.contact}</div>
            <div className={`contact-wrapper-one-page  ${isSmallScreen ? 'mobile' : ''}`}>
                {/* contact-details */}
                <div className={`contact-details-one-page ${isSmallScreen ? 'mobile' : ''}  `}>
                    <div className={`padding-2 ${isSmallScreen ? 'mobile' : ''}`}>

                        <div className='bold-text-one-page'>{t.contact.details}</div>
                        <div className={`detailes-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                            <div className='phone-wrapper '>
                                <p className='bold-text-small '>{t.contact.phone1}</p>
                                <div className='d-flex ' >
                                    <div className={`blue-text show-image-phone pt-2 pb-2  ${currentLanguage == 'en-US' ? 'pr-2' : 'pl-2'}`} type='button'
                                        onClick={() => { navigator.clipboard.writeText(getPhoneNumber()); setIsCopyPhone(true) }}
                                    >{getPhone()}</div>
                                    <img src={isCopyPhone ? confirmedCopy : copy} className='hide-img' alt={isCopyPhone ? 'confirmed copy' : 'copy phone'}></img>
                                </div>
                            </div>

                            <div className='phone-wrapper'>
                                <p className='bold-text-small'>{t.contact.email}</p>
                                <div className='d-flex' >
                                    <div className={`blue-text show-image-mail pt-2 pb-2 ${currentLanguage == 'en-US' ? 'pr-2' : 'pl-2'}`} type='button'
                                        onClick={() => { navigator.clipboard.writeText(getEmail()); setIsCopyEmail(true) }}>{getEmail()}</div>
                                    <img src={isCopyEmail ? confirmedCopy : copy} className='hide-img' alt={isCopyEmail ? 'confirmed copy' : 'copy email'}></img>
                                </div>
                            </div>
                        </div>

                        <div className='bold-text-one-page'>{t.contact.activityTime}</div>
                        <div className={`detailes-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                            <div className='phone-wrapper'>
                                <p className='bold-text-small'>{t.contact.sundayThursday}</p>
                                <p className='blue-text'>8:30-16:00</p>
                            </div>
                            <div className='phone-wrapper'>
                                <p className='bold-text-small'>{t.contact.onFridayAndolHidayEves}</p>
                                <p className='blue-text'>{t.contact.centerIsClosed}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={concatImg} className='contact-img' alt='contact img'></img>
            </div>

            {/* success modal */}
            <Modal
                show={isModalVisible}
                onHide={() => setIsModalVisible(false)}
                centered
                className={`modal`}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='blue-text text-center pt-4'>{t.contact.contactSendSuccessfuly}</div>
                    <div className='text-center pt-4 pb-4'>{t.contact.weTreatContact}</div>
                </Modal.Body>
            </Modal>

            {/* footer */}
            <div className={`footer ${isSmallScreen ? 'mobile' : ''}`}>
                {/* links */}
                <div className={`footer-links-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                    <div className={`footer-links ${isSmallScreen ? 'mobile' : ''}`}>
                        <div className='footer-link' onClick={() => { window.open('https://stamimages.blob.core.windows.net/documents/Terms_of_Use.pdf', "_blank", "noopener noreferrer"); }}>{t.onePage.termsofUse}</div>
                        <div className='footer-link' onClick={() => { window.open('https://stamimages.blob.core.windows.net/documents/Privacy_Policy.pdf', "_blank", "noopener noreferrer"); }}>{t.onePage.privacyPolicy}</div>
                        <div className='footer-link'>{t.onePage.accessibility}</div>
                        <div className='footer-link' onClick={() => { window.open('https://stamimages.blob.core.windows.net/documents/Cancelling_a_transaction.pdf', "_blank", "noopener noreferrer"); }}>{t.onePage.cancelTransaction}</div>
                    </div>
                    <div className={`footer-links ${isSmallScreen ? 'mobile' : ''}`}>
                        <div className='footer-link' onClick={() => scrollToSection(sectionAboutRef)}>{t.onePage.about}</div>
                        <div className='footer-link' onClick={() => scrollToSection(sectionStepsRef)}>{t.onePage.steps}</div>
                        <div className='footer-link' onClick={() => scrollToSection(sectionPricesRef)}>{t.onePage.openningSale}</div>
                        <div className='footer-link' onClick={() => scrollToSection(sectionVideoRef)}>{t.onePage.video} {t.onePage.Instruction}</div>
                        <div className='footer-link' onClick={() => scrollToSection(sectionQuestionRef)}>{t.onePage.questionsAndAnswer}</div>
                    </div>
                </div>

                <div className={`icons-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                    <img src={logo} onClick={() => scrollToSection(sectionHomeRef)} className={`logo-icon ${isSmallScreen ? 'mobile' : ''}`} alt='logo icon'></img>
                    <div className='icons'>
                        <div type='button' onClick={openGmail}>
                            <img className='icon' src={gmail} alt='open gmail'></img>
                        </div>
                        <div type='button' onClick={openWhatsApp}>
                            <img className='icon' src={watsapp} alt='open whatsapp'></img>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}