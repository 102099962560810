// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-profile-wrapper {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100vh;
}

.edit-profile-wrapper.mobile {
  justify-content: unset;
}

.user-edit-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  color: aliceblue;
  border-radius: 0.25rem;
}

.user-wrapper-edit {
  position: relative;
  display: inline-block;
}

.edit-profile-img {
  width: 1.7rem;
  position: absolute;
  top: 76%;
  right: -1%;
  transform: translate(-50%, -50%);
}

.edit-profile-img.ltr {
  left: 5.5%;
  right: unset;
}

.delete-user-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}

.delete-acount-button {
  width: 14.0625rem;
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  background: var(--2, #fa0000);
  color: var(--5, #f2f3f8);
  border: none;
}

.delete-acount-button-mobile {
  width: 10.9375rem;
  padding: 1.15625rem 2.32688rem;
  border-radius: 0.25rem;
  background: var(--2, #fa0000);
  color: var(--5, #f2f3f8);
  border: none;
  margin-top: -2;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/edit-profile/edit-profile-style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,6BAAA;EACA,sBAAA;EACA,aAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,aAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;EACA,gCAAA;AACJ;;AAEA;EACI,UAAA;EACA,YAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;AAEJ;;AAAA;EACI,iBAAA;EACA,kBAAA;EACA,sBAAA;EACA,6BAAA;EACA,wBAAA;EACA,YAAA;AAGJ;;AAAA;EACI,iBAAA;EACA,8BAAA;EACA,sBAAA;EACA,6BAAA;EACA,wBAAA;EACA,YAAA;EACA,cAAA;AAGJ","sourcesContent":[".edit-profile-wrapper {\n    display: flex;\n    justify-content: space-around;\n    flex-direction: column;\n    height: 100vh;\n}\n\n.edit-profile-wrapper.mobile {\n    justify-content: unset;\n}\n\n.user-edit-profile {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 5rem;\n    height: 5rem;\n    color: aliceblue;\n    border-radius: 0.25rem;\n}\n\n.user-wrapper-edit {\n    position: relative;\n    display: inline-block;\n}\n\n.edit-profile-img {\n    width: 1.7rem;\n    position: absolute;\n    top: 76%;\n    right: -1%;\n    transform: translate(-50%, -50%);\n}\n\n.edit-profile-img.ltr {\n    left: 5.5%;\n    right: unset;\n}\n.delete-user-container{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: end;\n}\n.delete-acount-button {\n    width: 14.0625rem;\n    padding: 1rem 2rem;\n    border-radius: 0.25rem;\n    background: var(--2, #fa0000);\n    color: var(--5, #f2f3f8);\n    border: none;\n}\n\n.delete-acount-button-mobile {\n    width: 10.9375rem;\n    padding: 1.15625rem 2.32688rem;\n    border-radius: 0.25rem;\n    background: var(--2, #fa0000);\n    color: var(--5, #f2f3f8);\n    border: none;\n    margin-top: -2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
