import * as ScansApiClient from "../api/scan-api-client";
import {
  SCANS_Of_CURRENT_FOLDER,
  CREATE_SCAN,
  SET_CURRENT_SCAN,
  SET_ACTIVE_IMG_INDEX,
  SET_SEARCH_QUERY,
  SCAN_IDS_TO_UPLOAD,
  FETCH_SCANS,
  DELETE_SCAN,
  FETCH_SCANS_OF_FOLDER,
  UPDATE_SCAN,
  FETCH_ALL_SCANS,
  REPORT_LOGS_NUMBERS
} from "./types";

export const fetchAllReportLogs = () => async dispatch => {
  try {
    const res = await ScansApiClient.fetchAllReportLogs();
    dispatch({
      type: FETCH_ALL_SCANS,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchReportLogsNumbers = () => async dispatch => {
  try {
    const res = await ScansApiClient.fetchReportLogsNumbers();
    dispatch({
      type: REPORT_LOGS_NUMBERS,
      payload: res
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const fetchAllScans = () => async dispatch => {
  try {
    const res = await ScansApiClient.fetchAllScans();
    dispatch({
      type: FETCH_ALL_SCANS,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchScans = idUser => async dispatch => {
  try {
    const res = await ScansApiClient.fetchScans(idUser);
    dispatch({
      type: FETCH_SCANS,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchScansByProjectId = projectId => async dispatch => {
  try {
    const res = await ScansApiClient.fetchScansByProjectId(projectId);
    dispatch({
      type: FETCH_SCANS_OF_FOLDER,
      payload: res.data
    });
    console.log("res data in fetchScansByProjectId", res.data);

    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const createScan = scan => async dispatch => {
  try {
    const res = await ScansApiClient.createScan(scan);
    dispatch({
      type: CREATE_SCAN,
      payload: res
    });
    return res;
  } catch (e) {
    console.log("e", e);
    console.log(e);
  }
};
export const updateScan = scan => async dispatch => {
  try {
    const res = await ScansApiClient.updateScan(scan);
    dispatch({
      type: SCANS_Of_CURRENT_FOLDER,
      payload: res.data
    });
    return res;
  } catch (e) {
    console.log("e", e);
    console.log(e);
  }
};

export const setImages = (imageId, url) => {
  return {
    type: "SET_IMAGES",
    payload: { imageId, url }
  };
};

export const setSearchQuery = query => {
  return {
    type: SET_SEARCH_QUERY,
    payload: { query }
  };
};

export const setCurrentScan = scan => dispatch => {
  dispatch({
    type: SET_CURRENT_SCAN,
    payload: scan
  });
};

export const setActiveImgIndex = index => dispatch => {
  dispatch({
    type: SET_ACTIVE_IMG_INDEX,
    payload: index
  });
};

export const setScansFolder = scans => async dispatch => {
  try {
    dispatch({
      type: SCANS_Of_CURRENT_FOLDER,
      payload: scans
    });
  } catch (e) {
    console.log(e);
  }
};

export const scansIdsToUpload = scans => async dispatch => {
  try {
    dispatch({
      type: SCAN_IDS_TO_UPLOAD,
      payload: scans
    });
    return scans;
  } catch (e) {
    console.log(e);
  }
};

export const deleteScan = (scanId, userId) => async dispatch => {
  try {
    const res = await ScansApiClient.deleteScan(scanId, userId);
    console.log("res", res);

    dispatch({
      type: DELETE_SCAN,
      payload: res
    });
    return res.scansOfFolder;
  } catch (e) {
    console.log(e);
  }
};
export const deleteScans = (
  scansIdsList,
  userId,
  sumToPay
) => async dispatch => {
  console.log("deleteScans", scansIdsList, userId, sumToPay);
  try {
    const res = await ScansApiClient.deleteScans({
      scansIdsList: scansIdsList,
      userId: userId,
      sumToPay: sumToPay
    });

    dispatch({
      type: DELETE_SCAN,
      payload: res
    });
    return res;
  } catch (e) {
    console.log(e);
  }
};
