// import React, { useEffect, useRef, useState } from "react";
// import {
//     createScan,
//     deleteScans,
//     scansIdsToUpload,
//     setScansFolder,
// } from "../store/action";
// import { useDispatch, useSelector } from "react-redux";
// import { Form, Field } from "react-final-form";
// import {
//     dataURLtoBlob,
//     nisPerAffairInTeffilin,
//     nisPerMezuza,
//     nisPerSheetOfSefetTora,
//     smallScreen,
//     useLanguage,
// } from "../../../utils/constants";
// import addFile from "../../../utils/assets/add-file.svg";
// import trashcan from "../../../utils/assets/trashcan.svg";
// import trashcanActive from "../../../utils/assets/trashcan-active.svg";
// import plusScan from "../../../utils/assets/plus-scan.svg";
// import plusScanActive from "../../../utils/assets/plus-scan-active.svg";
// import "./upload-scan-style.scss";
// import { useNavigate } from "react-router-dom";
// import imgArrow from "../../../utils/assets/img-arrow.svg";
// import imgArrowContainer from "../../../utils/assets/img-arrow-container.svg";
// import imgArrowContainerCrop from "../../../utils/assets/img-arrow-container-crop.svg";
// import imgArrowContainerCropRotate from "../../../utils/assets/img-arrow-container-crop-rotate.svg";
// import imgArrowRotate from "../../../utils/assets/img-arrow-rotate.svg";
// import crop from "../../../utils/assets/crop.svg";
// import cropActive from "../../../utils/assets/crop-active.svg";
// import grayLine from "../../../utils/assets/gray-line.svg";
// import x from "../../../utils/assets/x.svg";
// import v from "../../../utils/assets/v.svg";
// import rotate90 from "../../../utils/assets/rotate-90.svg";
// import rotateAdd1 from "../../../utils/assets/rotate-add-deg.svg";
// import rotateLess1 from "../../../utils/assets/rotate-less-deg.svg";
// import info from "../../../utils/assets/info.svg";
// import infoMobile from "../../../utils/assets/info.svg";
// import rotate from "../../../utils/assets/rotate.svg";
// import rotateActive from "../../../utils/assets/rotate-90.svg";
// import Header from "../../header/header";
// import { Cropper } from "react-advanced-cropper";
// import "react-advanced-cropper/dist/style.css";
// import 'react-advanced-cropper/dist/themes/corners.css';
// // import 'react-advanced-cropper/dist/themes/bubble.css';

// import ReactGA from 'react-ga4';

// import {
//     getCurrentUserById,
//     updateUserBalanceInProgress,
//     updateUserDontShowAgain,
//     updateUserLastPaymentAndBalance,
// } from "../../user/store/action";
// import { createReport, setConfirmedScansIds } from "../../report/store/action";
// import Modal from "react-bootstrap/Modal";
// import "react-html5-camera-photo/build/css/index.css";
// import EXIF from 'exif-js';
// import ScanSlider from "../../../utils/scan-slider/scan-slider";
// import { getMezuzaText, getMezuzaTextArray, getMezuzaTextByLines, getMezuzaTextWithoutSpaces, tefillinKadesh, tefillinKadeshArray, tefillinShamoa, tefillinShamoaArray, tefillinShma, tefillinShmaArray, tefillinVehaya, tefillinVehayaArray, tefillinVehayaWithLines } from '../../../utils/constants';
// import { Stage, Layer, Image as KonvaImage, Rect, Transformer } from 'react-konva';
// import levenshtein from 'js-levenshtein';
// import Tesseract from 'tesseract.js';

// const UploadScan = () => {
//     const currentUserId = JSON.parse(localStorage.getItem("currentUser"))?.id;
//     const currentProjectId = JSON.parse(sessionStorage.getItem("currentProjectId"));
//     const currentProjectName = JSON.parse(sessionStorage.getItem("currentProjectName"));
//     const scanType = JSON.parse(sessionStorage.getItem("scanType"));
//     const currentUser = useSelector((store) => store.userReducer.currentUser);
//     const t = useLanguage();
//     const isSmallScreen = smallScreen();
//     const dispatch = useDispatch();
//     const [files, setFiles] = useState([]);
//     const [filesToCrop, setFilesToCrop] = useState([]);
//     const [upload, setUpload] = useState(false);
//     const [pictue, setPicture] = useState(false);
//     const [resolution, setResolution] = useState();
//     const [croppedImage, setCroppedImage] = useState("");
//     const [imageQualityMessage, setImageQualityMessage] = useState("");
//     const [selectedImage, setSelectedImage] = useState(null);
//     const [imagesNum, setImageNum] = useState(0);
//     const [addingImages, setAddingImages] = useState(false);
//     const [wait, setWait] = useState(false);
//     const [addingImagesPicture, setAddingImagesPicture] = useState(false);
//     const [indexMouseHover, setIndexMouseHover] = useState(-1);
//     const navigate = useNavigate();
//     const cropperRef = useRef(null);
//     const isClickOkButton = useRef(false);
//     const [isCropping, setIsCropping] = useState(false);
//     const [isRotate, setIsRotate] = useState(true);
//     const [isCroppingButton, setIsCroppingButton] = useState(false);
//     const [disabledPage, setDisabledPage] = useState(false);
//     const isCurrentUserEffect = useRef(true);
//     const [sumToPay, setSumToPay] = useState(0);
//     const isBalance = useRef(true);
//     const [spinner, setSpinner] = useState(0);
//     const [isShowModal, setIsShowModal] = useState(false);
//     const fileInputRef = useRef(null);
//     const fileInput1Ref = useRef(null);
//     const priceList = {
//         mezuza: nisPerMezuza(),
//         tefillin: nisPerAffairInTeffilin(),
//         tora: nisPerSheetOfSefetTora(),
//     };
//     const language = useSelector((store) => store.userReducer.language);
//     const [currentLanguage, setCurrentLanguage] = useState(language);
//     const [isShowAreYouSure, setIsShowAreYouSure] = useState(false);
//     const [isShowErrorModal, setIsShowErrorModal] = useState(false);
//     const [isSome, setIsSome] = useState('');
//     const disabledButton = files.length === 0 || !isBalance.current || isCropping
//     const buttonRef = useRef(null);
//     const button2Ref = useRef(null);
//     const recognizeRef = useRef(true)
//     const [loaded, setLoaded] = useState(false);
//     const cv = window.cv;
//     const imagesRotetedIndex = useRef([]);
//     const [activeIndex, setActiveIndex] = useState(0);
//     const timerRef = useRef(null);
//     // const [image, setImage] = useState(null);
//     const image = useRef(null)
//     const [selectedShape, setSelectedShape] = useState(null);
//     const [finalPoints, setFinallPoints] = useState(null);
//     const [loadedImage, setLoadedImage] = useState(null); // Track Konva image load state
//     const mezuzaText = getMezuzaText();
//     const mezuzaTextArray = getMezuzaTextArray();

//     const tefillinShmaTextArray = tefillinShmaArray();
//     const tefillinKadeshTextArray = tefillinKadeshArray();
//     const tefillinVehayaTextArray = tefillinVehayaArray();
//     const tefillinShamoaTextArray = tefillinShamoaArray();
//     const combinedTefillinArray = tefillinShmaTextArray.concat(tefillinKadeshTextArray, tefillinVehayaTextArray, tefillinShamoaTextArray);

//     const teffilinVehayaText = tefillinVehaya();
//     const teffilinKadeshText = tefillinKadesh();
//     const tefillinShmaText = tefillinShma();
//     const tefillinShamoaText = tefillinShamoa();
//     const combinedTefillinText = teffilinVehayaText + teffilinKadeshText + tefillinShmaText + tefillinShamoaText;

//     const imageRef = useRef(null);
//     const cropRef = useRef(null);
//     const croptrRef = useRef(null);
//     const canvasRef = useRef(null);
//     let MAX_IMAGE_WIDTH = 1200;
//     let MAX_IMAGE_HEIGHT = 500;
//     const MIN_RECT_SIZE = 20;
//     const processedCanvasRef = useRef(null);

//     useEffect(() => {
//         setCurrentLanguage(language);
//     }, [language]);

//     useEffect(() => {
//         if (currentUserId && isCurrentUserEffect.current) {
//             dispatch(getCurrentUserById(currentUserId));
//             isCurrentUserEffect.current = false;
//             const bool = currentUser?.balance > 0;
//             if (bool)
//                 isBalance.current = bool;
//         }
//     }, [currentUserId]);

//     useEffect(() => {
//         checkImageResolution(croppedImage);
//     }, [croppedImage]);

//     useEffect(() => {
//         setImageNum(files.length);
//         if (files.length && selectedImage == null) {
//             setSelectedImage(files[0]);
//         }

//         setSumToPay(files.length);
//         const bool = sumToPay > 0 ? currentUser?.balance - sumToPay >= 0 : currentUser?.balance > 0;
//         if (bool) {
//             isBalance.current = bool;
//         }
//     });

//     useEffect(() => {
//         setSumToPay(files.length);
//     }, [files.length]);

//     const capturePhoto = (dataUri) => {
//         try {
//             const imgSrc = dataUri;
//             checkImageResolution(imgSrc);
//             setFiles((prevFiles) => [
//                 ...prevFiles,
//                 {
//                     url: imgSrc,
//                     rotation: 0,
//                 },
//             ]);
//             setSelectedImage({ url: imgSrc, rotation: 0, type: "" });
//             setAddingImages(false);
//             setAddingImagesPicture(false);
//             setSumToPay(sumToPay + files.length);
//             isBalance.current = currentUser?.balance - sumToPay >= 0;
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     const handlePictureClick = () => {
//         setUpload(false);
//         setPicture(true);
//         if (addingImages == true) setAddingImagesPicture(true);
//     };

//     const handleUploadClick = () => {
//         console.log('addingImages', addingImages);
//         if (fileInputRef.current) {
//             fileInputRef.current.click();
//         }
//         setPicture(false);
//         // setIsShowModal(false);
//         // if (addingImages == true) setAddingImagesUpload(true);
//         if (currentUser?.Role == 'user')
//             ReactGA.event({
//                 category: 'Button',
//                 action: 'לחיצה על העלאת תמונות מהגלריה',
//                 label: 'עמוד העלאת סריקות',
//                 value: 1
//             });
//     };

//     const handleUploadClick1 = () => {
//         console.log('addingImages', addingImages);
//         if (fileInput1Ref.current) {
//             fileInput1Ref.current.click();
//         }
//         setPicture(false);
//         // setIsShowModal(false);
//         // if (addingImages == true) setAddingImagesUpload(true);
//         if (currentUser?.Role == 'user')
//             ReactGA.event({
//                 category: 'Button',
//                 action: 'לחיצה על העלאת תמונות מהגלריה',
//                 label: 'עמוד העלאת סריקות',
//                 value: 1
//             });
//     };

//     const handleCrop = () => {
//         const konvaImage = imageRef.current;
//         const image = konvaImage.attrs.image;

//         if (!image) {
//             console.error('Image not loaded properly');
//             return;
//         }

//         const canvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');

//         const scaleX = image.naturalWidth / konvaImage.width();
//         const scaleY = image.naturalHeight / konvaImage.height();

//         const scaledPoints = finalPoints.map((p) => ({
//             x: p.x * scaleX,
//             y: p.y * scaleY,
//         }));

//         // Calculate rotation angle from the first two points
//         const angle = Math.atan2(
//             scaledPoints[1].y - scaledPoints[0].y,
//             scaledPoints[1].x - scaledPoints[0].x
//         );

//         // Rotate points around the center of the crop to calculate precise bounding box
//         const centerX = (scaledPoints[0].x + scaledPoints[2].x) / 2;
//         const centerY = (scaledPoints[0].y + scaledPoints[2].y) / 2;

//         const rotatedPoints = scaledPoints.map(({ x, y }) => {
//             const dx = x - centerX;
//             const dy = y - centerY;
//             return {
//                 x: centerX + dx * Math.cos(-angle) - dy * Math.sin(-angle),
//                 y: centerY + dx * Math.sin(-angle) + dy * Math.cos(-angle),
//             };
//         });

//         // Calculate new bounding box from rotated points
//         const minX = Math.min(...rotatedPoints.map(p => p.x));
//         const minY = Math.min(...rotatedPoints.map(p => p.y));
//         const maxX = Math.max(...rotatedPoints.map(p => p.x));
//         const maxY = Math.max(...rotatedPoints.map(p => p.y));

//         const cropWidth = maxX - minX;
//         const cropHeight = maxY - minY;

//         // Set canvas dimensions to match bounding box exactly
//         canvas.width = cropWidth;
//         canvas.height = cropHeight;

//         // Align canvas by translating to the top-left of bounding box
//         ctx.translate(-minX, -minY);

//         // Apply rotation to align correctly in the canvas
//         ctx.translate(centerX, centerY);
//         ctx.rotate(-angle);
//         ctx.translate(-centerX, -centerY);

//         // Draw the full image, then clip to display only cropped region without spacing
//         ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);

//         // Export cropped image as a data URL
//         const croppedDataUrl = canvas.toDataURL('image/jpeg', 1.0);

//         const updatedFiles = [...files];
//         console.log(selectedImage);

//         if (selectedImage !== null) {
//             const originalImageIndex = files.findIndex(file => file.url === selectedImage?.url);
//             if (originalImageIndex !== -1) {
//                 updatedFiles[originalImageIndex].url = croppedDataUrl;
//                 updatedFiles[originalImageIndex].rotation = 0;
//             }
//         }

//         setFiles(updatedFiles);

//         if (isCroppingButton || selectedImage?.index == files.length - 1 || selectedImage?.tempIndex == filesToCrop.length - 1) {
//             setIsCropping(false);
//             setIsCroppingButton(false);
//         }
//         else
//             setNextImageToCrop();
//         if (currentUser?.Role == 'user')
//             ReactGA.event({
//                 category: 'Button',
//                 action: 'אישור חיתוך',
//                 label: 'עמוד העלאת סריקות',
//                 value: 1
//             });

//     };

//     const checkImageResolution = (img) => {
//         const image = new Image();
//         image.src = img;
//         image.onload = () => {
//             const imageWidth = image.current.width;
//             const imageHeight = image.current.height;
//             const localResolution = (imageWidth * imageHeight) / 1000000;
//             const minResolution = 1;
//             setResolution(localResolution);
//             if (resolution < minResolution) {
//                 setImageQualityMessage(t.uploadScans.resolutionMessage);
//             } else {
//                 setImageQualityMessage("");
//             }
//         };
//     };

//     const onSubmit = async () => {
//         try {
//             activateAreYouSure()
//             console.log("onSubmit");
//             setIsShowAreYouSure(false);
//             setSpinner(files.length * 3);
//             setDisabledPage(true);
//             console.log("sumToPay", sumToPay);
//             const sumToPayToFunc = sumToPay || files.length * 3;
//             console.log("sumToPayfunc", sumToPayToFunc);
//             const resUpdateUserBalance = await dispatch(
//                 updateUserBalanceInProgress(currentUserId, sumToPayToFunc)
//             );
//             console.log("resUpdateUserBalance", resUpdateUserBalance);
//             if (resUpdateUserBalance && (resUpdateUserBalance?.message == "success" || resUpdateUserBalance?.message == 'admin or premium user')) {
//                 const formData = new FormData();
//                 let rotations = [];
//                 let filesName = [];
//                 for (const dataURL of files) {
//                     if (scanType == "tefillin") {
//                         formData.append("files", dataURLtoBlob(dataURL.url), dataURL.type);
//                     } else {
//                         formData.append("files", dataURLtoBlob(dataURL.url));
//                     }
//                     rotations.push(dataURL.rotation);
//                     filesName.push(dataURL.fileName);
//                 }
//                 console.log('rotations', rotations);
//                 console.log('filesName', filesName);
//                 formData.append("userId", currentUserId);
//                 formData.append("name", t.scanTypesSingle[scanType]);
//                 formData.append("filesName", JSON.stringify(filesName));
//                 formData.append("relatedProject", currentProjectId);
//                 formData.append("type", scanType);
//                 formData.append("sumToPay", sumToPay);
//                 formData.append("createdAt", new Date());
//                 formData.append("updatedAt", new Date());
//                 console.log("formData", formData);
//                 if (formData) {
//                     const resScan = await dispatch(createScan(formData));
//                     console.log("resScan✌️✌️✌️", resScan);
//                     if (resScan && resScan.message == "scan Created") {
//                         console.log("scans uploaded successfuly", resScan.scansListOfFolder);
//                         const resReport = await createReportFunc(resScan.scansListOfFolder, rotations);
//                         console.log("resreport😊😊😊", resReport);
//                         if (resReport && resReport?.data?.report) {
//                             const correctScans = resScan.data.filter(
//                                 (scan) => !resReport.data.incorrectScansIds.includes(scan._id)
//                             );
//                             console.log("correctScans😉😉😉😉", correctScans.length, correctScans);
//                             if (correctScans && correctScans.length > 0) {
//                                 console.log("דוח נוצר");
//                                 setFiles([]);
//                                 setUpload(false);
//                                 setPicture(false);
//                                 setCroppedImage("");
//                                 setResolution(null);
//                                 setImageQualityMessage("");
//                                 setSelectedImage(null);
//                                 let refoundSum = 0;
//                                 let aaaa = false;
//                                 let bbbb = '';
//                                 if (correctScans.length < resScan.data.length) {
//                                     dispatch(setScansFolder(correctScans));
//                                     const ids = resReport.data.incorrectScansIds;
//                                     const failedScansNames = resScan.data
//                                         .filter((scan) => ids.includes(scan._id))
//                                         .map((scan) => scan.name);
//                                     const aaa = failedScansNames.join(", ");
//                                     console.log('failedScansNames', failedScansNames);
//                                     console.log('aaa', aaa);
//                                     const bbb = failedScansNames.length > 1 ? ` הסריקות ${aaa}, לא עלו.` : ` הסריקה ${aaa} לא עלתה.`;
//                                     console.log('bbb', bbb);
//                                     setIsSome(bbb)
//                                     refoundSum = resScan.data.length - correctScans.length;
//                                     console.log("refoundSum", refoundSum);
//                                     setIsShowErrorModal(true)
//                                     aaaa = true;
//                                     bbbb = bbb;
//                                 }
//                                 setDisabledPage(false);
//                                 sessionStorage.setItem('isShowErrorModal', JSON.stringify(aaaa))
//                                 const newScansIds = [];
//                                 resScan.scansListOfFolder.map((scan) => { newScansIds.push(scan._id) })
//                                 await dispatch(scansIdsToUpload(newScansIds));

//                                 navigate("/scans", {
//                                     state: {
//                                         scans: resScan.scansListOfFolder,
//                                         type: scanType,
//                                         name: currentProjectName,
//                                         isShowErrorModal: aaaa,
//                                         isSome: bbbb
//                                     },
//                                 });
//                                 await dispatch(
//                                     updateUserLastPaymentAndBalance(
//                                         currentUserId,
//                                         sumToPay,
//                                         refoundSum
//                                     )
//                                 );

//                             }
//                             else {
//                                 setIsShowErrorModal(true)
//                                 setDisabledPage(false);
//                             }
//                         }
//                         else {
//                             console.log("222222222222222222222222222222");
//                             let extractedIds = [];
//                             console.log("דוח לא נוצר");
//                             if (resReport && resReport?.data?.incorrectScansIds) {
//                                 console.log("3333333333333333333333333333333333");
//                                 console.log("😘😘", resReport?.data?.incorrectScansIds);
//                                 extractedIds = resReport?.data?.incorrectScansIds;
//                             }
//                             // else {
//                             //     console.log("44444444444444444444444444444444444");
//                             //     extractedIds = resScan.data.map((item) => item._id);
//                             // }
//                             console.log("ids של סריקות למחיקה", extractedIds);
//                             const res = await dispatch(
//                                 deleteScans(extractedIds, currentUserId, sumToPay)
//                             );
//                             console.log("res of deleteScans", res);
//                             // if (res) await dispatch(setCurrentUser(res.user));
//                             setIsShowErrorModal(true)
//                             setDisabledPage(false);
//                         }
//                     } else {
//                         console.log("סריקות לא עלו");
//                         setIsShowErrorModal(true);
//                         setDisabledPage(false);
//                     }
//                 } else {
//                     console.log("no form data");
//                 }
//             } else {
//                 if (resUpdateUserBalance?.message == 'no balance')
//                     alert("יתרה לא מספיקה");
//                 else
//                     alert('שגיאה')
//                 setDisabledPage(false);
//             }
//         } catch (error) {
//             console.log("error in upload scan", error);
//         }
//     };

//     const createReportFunc = async (ScanList, rotations) => {
//         console.log("createReportFunc", ScanList);
//         return new Promise(async (resolve, reject) => {
//             try {
//                 const scansObjects = [];
//                 if (ScanList?.length > 0) {
//                     ScanList.map((scan, index) => {
//                         const obj = {
//                             userId: currentUserId,
//                             scanId: scan._id,
//                             scanName: scan.name,
//                             relatedProject: currentProjectId,
//                             image: scan.images[0],
//                             type: scan.type,
//                             rotation: rotations[index],
//                         };
//                         scansObjects.push(obj);
//                     });
//                     console.log("scansObjects", scansObjects);
//                     const report = await dispatch(
//                         createReport({
//                             scans: scansObjects,
//                             scanType: t.scanTypesSingle[scanType],
//                             locale: language,
//                             userName: currentUser?.customerName,
//                             projectName: currentProjectName,
//                             projectId: currentProjectId,
//                             userRoleAssiment: currentUser?.Role,
//                         })
//                     );
//                     console.log("node.js answer of upload report", report);
//                     dispatch(setConfirmedScansIds([]));
//                     resolve(report);
//                 } else {
//                     console.log("no allProjectUsersScans");
//                     resolve(null);
//                 }
//             } catch (error) {
//                 console.log(error);
//                 resolve(null);
//             }
//         });
//     };

//     const handleImageClick = (image) => {
//         setSelectedImage(image);
//     };

//     const deleteImg = () => {
//         const deletedImgIndex = selectedImage?.index || 0;
//         const deletedImgTmpIndex = selectedImage?.tempIndex || 0;
//         const updatedFiles = files?.filter((img) => img.url !== selectedImage?.url);
//         const updatedFilesIndexs = updatedFiles.map((file, index) => {
//             return {
//                 ...file,
//                 tempIndex: index,
//                 index: file.index > deletedImgIndex ? file.index - 1 : file.index
//             };
//         })
//         setFiles(updatedFilesIndexs);
//         if (isCropping) {
//             const updatedFilesToCrop = filesToCrop?.filter((img) => img.url !== selectedImage?.url);
//             const updatedFilesToCropIndexs = updatedFilesToCrop.map((file, index) => {
//                 return {
//                     ...file,
//                     tempIndex: index,
//                     index: file.index > deletedImgIndex ? file.index - 1 : file.index
//                 };
//             })
//             setFilesToCrop(updatedFilesToCropIndexs);
//             if (updatedFilesToCrop[0] && !isCroppingButton) {
//                 if (deletedImgTmpIndex == updatedFilesToCrop.length) {
//                     setIsCropping(false)
//                     setSelectedImage(updatedFiles[0]);
//                 }
//                 else
//                     setSelectedImage(updatedFilesToCropIndexs[deletedImgTmpIndex]);
//             }
//             if (isCroppingButton) {
//                 setIsCropping(false)
//                 setSelectedImage(updatedFiles[0]);
//                 setIsCroppingButton(false)
//             }
//             if (!updatedFilesToCrop[0]) {
//                 if (!updatedFiles[0])
//                     cancel();
//                 else
//                     setIsCropping(false)

//             }
//         }
//         if (!isCropping) {
//             if (updatedFiles[0])
//                 setSelectedImage(updatedFiles[0]);
//             else cancel();
//         }

//         if (currentUser?.Role == 'user')
//             ReactGA.event({
//                 category: 'Button',
//                 action: 'מחיקת תמונה במסך החיתוך',
//                 label: 'עמוד העלאת סריקות',
//                 value: 1
//             });

//     };

//     const setPreviousImage = () => {
//         const activeIndex = files.indexOf(selectedImage);
//         const newIndex = activeIndex === 0 ? files.length - 1 : activeIndex - 1;
//         setSelectedImage(files[newIndex]);
//     };

//     const setNextImage = () => {
//         const activeIndex = files.indexOf(selectedImage);
//         const newIndex = activeIndex === files.length - 1 ? 0 : activeIndex + 1;
//         setSelectedImage(files[newIndex]);
//     };

//     const setNextImageToCrop = () => {
//         const activeIndex = selectedImage?.tempIndex;
//         const newIndex = activeIndex === filesToCrop.length - 1 ? 0 : activeIndex + 1;
//         setSelectedImage(filesToCrop[newIndex]);
//         recognizeRef.current = true;
//         imagesRotetedIndex.current = []
//     }

//     const setPreviousImageToCrop = () => {
//         const activeIndex = selectedImage?.tempIndex;
//         const newIndex = activeIndex === 0 ? filesToCrop.length - 1 : activeIndex - 1;
//         setSelectedImage(filesToCrop[newIndex]);
//         imagesRotetedIndex.current = []
//     }

//     const cancel = () => {
//         setFiles([]);
//         setSelectedImage(null);
//         setPicture(false);
//         setUpload(false);
//         setResolution(null);
//         setImageQualityMessage("");
//         setDisabledPage(false);
//         setIsCropping(false);
//         setAddingImages(false);
//         if (currentUser?.Role == 'user')
//             ReactGA.event({
//                 category: 'Button',
//                 action: 'לחיצה על כפתור ביטול סריקה',
//                 label: 'עמוד העלאת סריקות',
//                 value: 1
//             });

//     };

//     const handleFileChange = (e) => {
//         setLoaded(true)
//         setIsCropping(true)
//         setIsShowModal(false)
//         setWait(true)
//         const selectedFiles = e.target.files;
//         if (
//             selectedFiles?.length > 20 ||
//             files?.length + selectedFiles?.length > 20
//         ) {
//             alert("ניתן להעלות עד 20 קבצים בתיקיה אחת.");
//             setLoaded(false)
//         } else {
//             if (selectedFiles.length > 0) {
//                 const sortedSelectedFiles = [...selectedFiles].sort((a, b) => a.lastModified - b.lastModified);

//                 setUpload(true);
//                 const reader = new FileReader();

//                 reader.onloadend = () => {
//                     let array = [];
//                     array = Array.from(sortedSelectedFiles).map((file, index) => {
//                         const originalName = file.name;
//                         const nameWithoutExtension = originalName.substring(0, originalName.lastIndexOf('.'));

//                         const fileReader = new FileReader();
//                         fileReader.readAsDataURL(file);
//                         return new Promise((resolve) => {
//                             fileReader.onloadend = () => {
//                                 resolve({
//                                     index: files.length + index,
//                                     tempIndex: index,
//                                     url: fileReader.result,
//                                     rotation: 0,
//                                     fileName: nameWithoutExtension,
//                                 });
//                             };
//                         });
//                     });

//                     Promise.all(array).then((dataURLs) => {
//                         setFiles((prevFiles) => {
//                             const newFiles = [...prevFiles, ...dataURLs];
//                             setSumToPay((prevSum) => prevSum + dataURLs.length);
//                             isBalance.current = currentUser?.balance - sumToPay >= 0;
//                             return newFiles;
//                         });
//                         setSelectedImage(dataURLs[0]);
//                         setFilesToCrop(dataURLs);
//                     });
//                 };
//                 reader.readAsDataURL(selectedFiles[0]);
//                 timerRef.current = setTimeout(() => {
//                     setLoaded(false)
//                 }, 4000);

//             }
//             setWait(false)
//         }
//     };

//     useEffect(() => {
//         if (isCropping == false) {
//             if (timerRef.current) {
//                 clearTimeout(timerRef.current);
//                 timerRef.current = null;
//             }
//             setLoaded(false)
//         }
//     }, [isCropping]);


//     useEffect(() => {
//         const interval = setInterval(() => {
//             setActiveIndex(prevIndex => (prevIndex + 1) % 3);
//         }, 500);

//         return () => clearInterval(interval);
//     }, []);

//     const cancelCroping = () => {
//         if (isCroppingButton || selectedImage?.index == files.length - 1 || selectedImage?.tempIndex == filesToCrop.length - 1) {
//             setIsCropping(false)
//             setIsCroppingButton(false);
//         }
//         else {
//             setNextImageToCrop();
//         }
//         if (currentUser?.Role == 'user')
//             ReactGA.event({
//                 category: 'Button',
//                 action: 'ביטול חיתוך',
//                 label: 'עמוד העלאת סריקות',
//                 value: 1
//             });

//     };

//     useEffect(() => {
//         try {
//             if (buttonRef.current) {
//                 buttonRef.current.focus();

//                 const handleKeyPress = (event) => {
//                     if (event.key === "Enter") {
//                         if (
//                             disabledButton === false &&
//                             disabledPage === false &&
//                             isClickOkButton.current == false
//                         ) {
//                             openAreYouSure();
//                             // isClickOkButton.current = true;
//                             // buttonRef.current = null;
//                         }
//                     }
//                 };

//                 document.addEventListener("keydown", handleKeyPress);

//                 return () => {
//                     document.removeEventListener("keydown", handleKeyPress);
//                 };
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     }, [disabledButton]);

//     useEffect(() => {
//         try {
//             if (isShowAreYouSure == true) {
//                 button2Ref.current.focus();

//                 const handleKeyPress = (event) => {
//                     if (event.key === "Enter") {
//                         onSubmit();
//                         setIsShowAreYouSure(false);
//                         isClickOkButton.current = true;
//                     }
//                 };

//                 document.addEventListener("keydown", handleKeyPress);

//                 return () => {
//                     document.removeEventListener("keydown", handleKeyPress);
//                 };
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     }, [isShowAreYouSure]);

//     const openAreYouSure = () => {
//         if (currentUser?.dontShowAgainUploadScan == true) {
//             onSubmit()
//         }
//         else {
//             setIsShowAreYouSure(true);
//             if (currentUser?.Role == 'user')
//                 ReactGA.event({
//                     category: 'Button',
//                     action: 'לחיצה על כפתור אשר סריקה',
//                     label: 'עמוד העלאת סריקות',
//                     value: 1
//                 });
//         }
//     };

//     const rotateImage = (degrees) => {
//         const updatedFiles = [...files];
//         if (selectedImage !== null) {
//             let originalImageIndex = -1;
//             files.map((file, index) => {
//                 if (file.url == selectedImage?.url) {
//                     originalImageIndex = index;
//                 }
//             });
//             const newRotation = (updatedFiles[originalImageIndex].rotation + degrees) % 360;
//             updatedFiles[originalImageIndex].rotation = newRotation;
//             setFiles(updatedFiles);
//             if (degrees == 90 && (isCropping || isCroppingButton)) {
//                 // [imageRef.current.attrs.width, imageRef.current.attrs.height] = [imageRef.current.attrs.height, imageRef.current.attrs.width]
//                 processImage(selectedImage.url)
//             }
//         }
//     };

//     useEffect(() => {
//         if (selectedImage && isCropping && isRotate) {
//             processImage(selectedImage?.url)
//         }
//     }, [selectedImage?.url])

//     const activateCancelAreYouSure = () => {
//         if (currentUser?.Role == 'user')
//             ReactGA.event({
//                 category: 'Button',
//                 action: 'ביטול בהאם אתה בטוח',
//                 label: 'עמוד העלאת סריקות',
//                 value: 1
//             });
//     }

//     const activateAreYouSure = () => {
//         if (currentUser?.Role == 'user')
//             ReactGA.event({
//                 category: 'Button',
//                 action: 'אישור בהאם אתה בטוח',
//                 label: 'עמוד העלאת סריקות',
//                 value: 1
//             });
//     }

//     const dontShowAgain = (event) => {
//         const checkbox = event.target;

//         if (checkbox.checked) {
//             dispatch(updateUserDontShowAgain({ userId: currentUserId, parameter: 'dontShowAgainUploadScan' }))
//         }
//     }

//     const processImage = (imageSrc) => {

//         if (!cv) {
//             console.error('OpenCV.js is not loaded');
//             return;
//         }

//         const img = new Image();
//         img.src = imageSrc;

//         img.onload = () => {
//             const div = document.querySelector("#cropperDiv");
//             if (div) {
//                 const rect = div.getBoundingClientRect();

//                 const divWidth = rect.width;
//                 const divHeight = rect.height;

//                 MAX_IMAGE_WIDTH = divWidth;
//                 MAX_IMAGE_HEIGHT = divHeight;
//             }
//             const maxWidth = MAX_IMAGE_WIDTH;
//             const maxHeight = MAX_IMAGE_HEIGHT;

//             let width = img.width;
//             let height = img.height;

//             const rotationAngle = selectedImage.rotation;
//             if (rotationAngle === 90 || rotationAngle === 270) {
//                 [width, height] = [height, width];
//             }

//             if (width > maxWidth || height > maxHeight) {
//                 const aspectRatio = width / height;
//                 if (width > maxWidth) {
//                     width = maxWidth;
//                     height = maxWidth / aspectRatio;
//                 }

//                 if (height > maxHeight) {
//                     height = maxHeight;
//                     width = maxHeight * aspectRatio;
//                 }
//             }
//             if (rotationAngle === 90 || rotationAngle === 270) {
//                 [width, height] = [height, width];
//             }

//             image.current = { img, width, height };
//             const canvas = canvasRef.current;
//             const context = canvas.getContext('2d');
//             context.imageSmoothingEnabled = true;
//             context.imageSmoothingQuality = 'high';
//             canvas.width = width;
//             canvas.height = height;
//             context.clearRect(0, 0, canvas.width, canvas.height);
//             context.drawImage(img, 0, 0, width, height);

//             const src = cv.imread(canvas);
//             if (!src || src.empty()) {
//                 console.error('OpenCV: Failed to read image from canvas');
//                 return;
//             }

//             const gray = new cv.Mat();
//             cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY, 0);
//             cv.threshold(gray, src, 0, 255, cv.THRESH_TOZERO);

//             const blurred = new cv.Mat();
//             cv.GaussianBlur(gray, blurred, new cv.Size(7, 7), 0);

//             const edges = new cv.Mat();
//             cv.Canny(blurred, edges, 90, 205, 3, false);

//             let kernel;
//             if (scanType === 'tefillin') {
//                 kernel = cv.Mat.ones(11, 11, cv.CV_8U);
//             } else {
//                 kernel = cv.Mat.ones(9, 9, cv.CV_8U);
//             }

//             const dilated = new cv.Mat();
//             cv.dilate(edges, dilated, kernel);

//             const contours = new cv.MatVector();
//             const hierarchy = new cv.Mat();
//             cv.findContours(dilated, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);

//             let maxContour = null;
//             let maxArea = 0;
//             for (let i = 0; i < contours.size(); ++i) {
//                 const contour = contours.get(i);
//                 const area = cv.contourArea(contour);
//                 if (area > maxArea) {
//                     maxArea = area;
//                     maxContour = contour;
//                 }
//             }
//             if (maxContour == null) {
//                 console.error('OpenCV: maxContour is null');
//                 return;
//             }
//             let points = [];
//             let angle;
//             if (scanType !== 'tefillin' && maxArea < 100000) {
//                 // Case of cropped images of mezuza
//                 setSelectedShape({
//                     id: 'cropperRect',
//                     attributes: {
//                         x: 0,
//                         y: 0,
//                         width: width,
//                         height: height,
//                         rotation: 0,
//                     },
//                 });
//                 setIsCropping(true);
//             }
//             else {
//                 const rotatedRect = cv.minAreaRect(maxContour);

//                 angle = rotatedRect.angle;
//                 points = cv.RotatedRect.points(rotatedRect);

//                 context.strokeStyle = 'red';
//                 context.lineWidth = 2;
//                 context.beginPath();
//                 context.moveTo(points[0].x, points[0].y);
//                 for (let i = 1; i < 4; i++) {
//                     context.lineTo(points[i].x, points[i].y);
//                 }
//                 context.closePath();
//                 context.stroke();
//                 const sortedPoints = sortPointsFunc(points)
//             }

//             src.delete();
//             gray.delete();
//             blurred.delete();
//             edges.delete();
//             dilated.delete();
//             contours.delete();
//             hierarchy.delete();
//             if (maxContour) maxContour.delete();

//             const sortedPoints = sortPointsFunc(points)

//             recognizeTextWithTesseract(img, width, height, sortedPoints, angle);
//         };
//     };

//     const drawCanvas = (mat, setCanvasImage) => {
//         const canvas = document.createElement('canvas');
//         canvas.width = mat.cols;
//         canvas.height = mat.rows;
//         cv.imshow(canvas, mat);
//         setCanvasImage(canvas.toDataURL());
//     };

//     const recognizeTextWithTesseract = async (image, width, height, documentPoints, angle) => {
//         const canvas = processedCanvasRef.current;
//         const context = canvas.getContext('2d');
//         context.imageSmoothingEnabled = true;
//         context.imageSmoothingQuality = 'high';
//         canvas.width = width;
//         canvas.height = height;
//         context.drawImage(image, 0, 0, width, height);
//         const src = cv.imread(canvas);
//         let gray = new cv.Mat();
//         let dst = new cv.Mat();

//         // Convert the image to grayscale
//         cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY);
//         // Apply binarization with a threshold of 128 and a max value of 255
//         // cv.threshold(gray, dst, 100, 255, cv.THRESH_BINARY);
//         cv.threshold(gray, dst, 0, 255, cv.THRESH_TOZERO); // Keeps values above threshold, sets others to 0

//         cv.imshow(canvas, dst);

//         Tesseract.recognize(
//             canvas,
//             'heb',
//             {
//             }
//         ).then(({ data: { lines, words } }) => {
//             // console.log('Lines recognized:', lines);
//             // console.log('Words recognized:', words);
//             let minX = Infinity;
//             let minY = Infinity;
//             let maxX = -Infinity;
//             let maxY = -Infinity;

//             const canvas = canvasRef.current;
//             const ctx = canvas.getContext('2d')

//             ctx.lineWidth = 2;
//             const pointsArray = [];
//             const notHebrew = '0123456789/*-!@#$%^&&()-=';
//             const hebrewLetters = 'אבגדהוזחטיכלמנסעפצקרשתףךןםץ'

//             const textArrayToLoop = scanType == 'tefillin' ? combinedTefillinArray : mezuzaTextArray;

//             const tefillinArray = combinedTefillinText?.split(/\s+/);
//             const mezuzaArray = mezuzaText?.split(/\s+/)
//             const wordsArray = scanType == 'tefillin' ? tefillinArray : mezuzaArray;

//             lines.forEach((line, lineIndex) => {
//                 // ctx.strokeStyle = 'yellow';
//                 // ctx.strokeRect(line.bbox.x0, line.bbox.y0, line.bbox.x1 - line.bbox.x0, line.bbox.y1 - line.bbox.y0);

//                 let isGoodWordInLine = false;
//                 const notRealWords = []
//                 line.words.forEach((word, wordIndex) => {
//                     const confidence = word.choices[0].confidence;
//                     const specialCharacters = /[0-9/*\-!@#$%^&()=.,\[\]]/g;
//                     const wordLength = word.text.match(specialCharacters)?.length > 1 ? 0 : word.text.replace(specialCharacters, '').length;
//                     const clereWord = word.text.replace(/[0-9/*\-!@#$%^&()=.,\[\]]/g, '');
//                     const isCalculateSimilarity = textArrayToLoop.some((item) => {
//                         const parameter = calculateSimilarity(item.word, word.text)
//                         if (parameter >= item.percent) {
//                             return true;
//                         }
//                         return false;
//                     })

//                     const isWordPresent = wordsArray.includes(clereWord);

//                     const isRealWord = wordLength > 1 && (isWordPresent || confidence > 70 || isCalculateSimilarity);

//                     const { bbox } = word;
//                     if (isRealWord) {
//                         // ctx.fillStyle = 'blue';
//                         // ctx.font = '18px Arial';
//                         // ctx.fillText(lineIndex + word.text, bbox.x0 + 20, bbox.y0); // Draw text slightly above the bounding box
//                         // // ctx.fillText(word.text, bbox.x0 + 20, bbox.y0); // Draw text slightly above the bounding box

//                         // isGoodWordInLine = true;
//                         // ctx.strokeStyle = 'blue';

//                         // ctx.strokeRect(bbox.x0, bbox.y0, bbox.x1 - bbox.x0, bbox.y1 - bbox.y0);

//                         // Find the overall bounding box
//                         minX = Math.min(minX, bbox.x0);
//                         minY = Math.min(minY, bbox.y0);
//                         maxX = Math.max(maxX, bbox.x1);
//                         maxY = Math.max(maxY, bbox.y1);

//                         pointsArray.push([bbox.x0, bbox.y0]); // Top-left
//                         pointsArray.push([bbox.x1, bbox.y0]); // Top-right
//                         pointsArray.push([bbox.x1, bbox.y1]); // Bottom-right
//                         pointsArray.push([bbox.x0, bbox.y1]); // Bottom-left
//                     }
//                     else {
//                         // ctx.fillStyle = 'red';
//                         // ctx.font = '18px Arial';
//                         // ctx.fillText(lineIndex + word.text, bbox.x0 + 20, bbox.y0); // Draw text slightly above the bounding box
//                         // ctx.fillText(word.text, bbox.x0 + 20, bbox.y0); // Draw text slightly above the bounding box

//                         notRealWords.push(wordIndex)
//                         // ctx.strokeStyle = 'pink';
//                         // ctx.strokeRect(bbox.x0, bbox.y0, bbox.x1 - bbox.x0, bbox.y1 - bbox.y0);
//                     }
//                 });

//                 if (isGoodWordInLine) {//add the word in the line that not recognized
//                     //todo instead of loop on notRealWords, take the line bbox
//                     //chaeck if the result is the same

//                     //todo instead of loop on notRealWords, take the line bbox
//                     //chaeck if the result is the same
//                     // isGoodWordInLine = true;
//                     // ctx.strokeStyle = 'purple';
//                     // ctx.strokeRect(bbox.x0, bbox.y0, bbox.x1 - bbox.x0, bbox.y1 - bbox.y0);

//                     // Find the overall bounding box
//                     // const { bbox } = line;
//                     // minX = Math.min(minX, bbox.x0);
//                     // minY = Math.min(minY, bbox.y0);
//                     // maxX = Math.max(maxX, bbox.x1);
//                     // maxY = Math.max(maxY, bbox.y1);

//                     // pointsArray.push([bbox.x0, bbox.y0]);
//                     // pointsArray.push([bbox.x1, bbox.y0]);
//                     // pointsArray.push([bbox.x1, bbox.y1]);
//                     // pointsArray.push([bbox.x0, bbox.y1]);
//                     notRealWords.forEach((index) => {
//                         const word = line.words[index]
//                         const specialCharacters = /[0-9/*\-!@#$%^&()=.,]/g;
//                         const wordLength = word.text.match(specialCharacters)?.length > 1
//                             ? 0
//                             : word.text.replace(specialCharacters, '').length;

//                         if (wordLength > 1) {

//                             const { bbox } = word;
//                             isGoodWordInLine = true;
//                             // ctx.strokeStyle = 'purple';
//                             // ctx.strokeRect(bbox.x0, bbox.y0, bbox.x1 - bbox.x0, bbox.y1 - bbox.y0);

//                             // Find the overall bounding box
//                             minX = Math.min(minX, bbox.x0);
//                             minY = Math.min(minY, bbox.y0);
//                             maxX = Math.max(maxX, bbox.x1);
//                             maxY = Math.max(maxY, bbox.y1);

//                             pointsArray.push([bbox.x0, bbox.y0]);
//                             pointsArray.push([bbox.x1, bbox.y0]);
//                             pointsArray.push([bbox.x1, bbox.y1]);
//                             pointsArray.push([bbox.x0, bbox.y1]);
//                         }
//                     })

//                 }
//             })

//             let finnalPointsToCropper = []
//             if (pointsArray.length === 0) {
//                 finnalPointsToCropper = documentPoints;
//             }
//             else {
//                 const pointsMat = cv.matFromArray(pointsArray.length, 1, cv.CV_32FC2, pointsArray.flat());
//                 const rotatedRect = cv.minAreaRect(pointsMat);
//                 const textPoints = cv.RotatedRect.points(rotatedRect);
//                 const sortedTextPoints = sortPointsFunc(textPoints)
//                 ctx.strokeStyle = 'green';
//                 ctx.lineWidth = 4;
//                 ctx.beginPath();
//                 ctx.moveTo(textPoints[0].x, textPoints[0].y);

//                 for (let i = 1; i < 4; i++) {
//                     ctx.lineTo(textPoints[i].x, textPoints[i].y);
//                 }
//                 ctx.closePath();
//                 ctx.stroke();
//                 pointsMat?.delete();
//                 const overlappingPoints = [
//                     { x: Math.max(documentPoints[0].x, sortedTextPoints[0].x), y: Math.max(documentPoints[0].y, sortedTextPoints[0].y) },
//                     { x: Math.min(documentPoints[1].x, sortedTextPoints[1].x), y: Math.max(documentPoints[1].y, sortedTextPoints[1].y) },
//                     { x: Math.min(documentPoints[2].x, sortedTextPoints[2].x), y: Math.min(documentPoints[2].y, sortedTextPoints[2].y) },
//                     { x: Math.max(documentPoints[3].x, sortedTextPoints[3].x), y: Math.min(documentPoints[3].y, sortedTextPoints[3].y) },
//                 ]

//                 const docWidth = documentPoints[1].x - documentPoints[0].x;
//                 const docHeight = documentPoints[3].y - documentPoints[0].y;
//                 const textWidth = textPoints[1].x - textPoints[0].x;
//                 const textHeight = textPoints[3].y - textPoints[0].y;

//                 if (textWidth < 70 || textHeight < 40) {//todo maybe calculate for verticle and for horizinal in separate numbers
//                     finnalPointsToCropper = documentPoints;
//                 }
//                 else {
//                     const widthDiff = docWidth - textWidth;
//                     const heightDiff = docHeight - textHeight;

//                     const isTefillinValid = docWidth < textWidth && heightDiff > 0 && heightDiff < 20
//                     const isccc = docWidth - textWidth > 2 && heightDiff < 0;
//                     const is = docWidth < textWidth && heightDiff < 0;//todo
//                     const isMezuzaValid = widthDiff < 15 && docHeight > textHeight;

//                     if (scanType === 'tefillin' ? (isTefillinValid || is || isccc) : isMezuzaValid) {
//                         if (scanType == 'tefillin') {
//                             if (isTefillinValid || is)
//                                 finnalPointsToCropper = textPoints;
//                             else
//                                 finnalPointsToCropper = documentPoints
//                         }
//                         else {
//                             finnalPointsToCropper = documentPoints;
//                         }
//                     }
//                     else {
//                         finnalPointsToCropper = overlappingPoints;
//                     }
//                 }
//             }

//             const arrayOfArrays = finnalPointsToCropper.map(obj => Object.values(obj));

//             const newPointsMat = cv.matFromArray(arrayOfArrays.length, 1, cv.CV_32FC2, arrayOfArrays.flat());
//             const newRotatedRect = cv.minAreaRect(newPointsMat);
//             const finalPoints = cv.RotatedRect.points(newRotatedRect);
//             const angle = newRotatedRect.angle;
//             const sortedPoints = sortPointsFunc(finalPoints)
//             setFinallPoints(sortedPoints)

//             setSelectedShape({
//                 id: 'cropperRect',
//                 attributes: angle > 45 ?
//                     {
//                         x: sortedPoints[0].x,
//                         y: sortedPoints[0].y,
//                         width: newRotatedRect.size.height,
//                         height: newRotatedRect.size.width,
//                         rotation: angle - 90,
//                     }
//                     :
//                     {
//                         x: sortedPoints[0].x,
//                         y: sortedPoints[0].y,
//                         width: newRotatedRect.size.width,
//                         height: newRotatedRect.size.height,
//                         rotation: angle,
//                     }
//             });

//             setIsCropping(true);

//             ctx.strokeStyle = 'purple';
//             ctx.lineWidth = 2;
//             ctx.beginPath();
//             ctx.moveTo(finalPoints[0].x, finalPoints[0].y);
//             for (let i = 1; i < 4; i++) {
//                 ctx.lineTo(finalPoints[i].x, finalPoints[i].y);
//             }
//             ctx.closePath();
//             ctx.stroke();

//             ctx.strokeStyle = 'pink';
//             ctx.lineWidth = 3;
//             finnalPointsToCropper.map((point, i) => {
//                 ctx.beginPath();
//                 ctx.moveTo(point.x, point.y);
//                 ctx.lineTo(point.x + 3, point.y + 3);
//                 ctx.stroke();
//             })

//             ctx.lineWidth = 1;
//             ctx.strokeStyle = 'yellow';
//             ctx.beginPath();
//             ctx.moveTo(finnalPointsToCropper[0].x, finnalPointsToCropper[0].y);

//             for (let i = 1; i < 4; i++) {
//                 ctx.lineTo(finnalPointsToCropper[i].x, finnalPointsToCropper[i].y);
//             }
//             ctx.closePath();
//             ctx.stroke();


//         }).catch((err) => {
//             console.error('Error in Tesseract:', err);
//         });
//         // }

//     };

//     const calculateSimilarity = (textPart1, textPart2) => {
//         if (textPart1 && textPart2) {
//             const distance = levenshtein(textPart1, textPart2);
//             const maxLength = Math.max(textPart1.length, textPart2.length);
//             const similarity = ((maxLength - distance) / maxLength) * 100;
//             return similarity;
//         }
//         else return 0;
//     };

//     useEffect(() => {
//         if (image && image.current && image.current.img) {
//             console.log('useEffect', image);
//             const konvaImage = new window.Image();
//             konvaImage.src = image.current.img.src;
//             konvaImage.onload = () => {
//                 setLoadedImage(konvaImage);
//             };
//         }
//     }, [image.current]);

//     useEffect(() => {
//         if (cropRef.current && croptrRef.current) {
//             croptrRef.current.nodes([cropRef.current]);
//             croptrRef.current.getLayer().batchDraw();
//         }
//     }, [isCropping, selectedShape]);

//     const handleShapeChange = (updatedShape) => {
//         console.log('handleShapeChange', updatedShape);
//         setSelectedShape(updatedShape.newItem);
//         const rectangle = updatedShape.newItem.attributes;
//         const polygon = [
//             { x: rectangle.x, y: rectangle.y },
//             { x: rectangle.x + rectangle.width, y: rectangle.y },
//             { x: rectangle.x + rectangle.width, y: rectangle.y + rectangle.height },
//             { x: rectangle.x, y: rectangle.y + rectangle.height }
//         ];

//         setFinallPoints(polygon)
//         console.log(selectedShape);

//     };

//     const sortPointsFunc = (pointsToSort) => {
//         let centroid = { x: 0, y: 0 };
//         for (let i = 0; i < pointsToSort.length; i++) {
//             centroid.x += pointsToSort[i].x;
//             centroid.y += pointsToSort[i].y;
//         }
//         centroid.x /= pointsToSort.length;
//         centroid.y /= pointsToSort.length;
//         function angleFromCentroid(point) {
//             return Math.atan2(point.y - centroid.y, point.x - centroid.x);
//         }
//         const sortedPoints = pointsToSort.sort((a, b) => {
//             return angleFromCentroid(a) - angleFromCentroid(b);
//         });
//         return sortedPoints;
//     }


//     return (
//         <div className={`upload-scan-page-container  ${isSmallScreen ? " mobile" : ""}`}>
//             <div className={` ${isSmallScreen ? "" : "container upload-scan-wrapper"}`}>

//                 <div className={`row pt-2 pb-2 ${currentLanguage == "en-US" ? "pr-0" : "pl-0"}`}>
//                     <Header page={"upload-scan"} context={[t.uploadScans.uploadImages, ">", , t.scanTypes[scanType], ">", currentProjectName,]}></Header>
//                 </div>

//                 {/* header of page */}
//                 <div className="row ">
//                     <div className={`col header-wrapper ${isSmallScreen ? "mobile" : ""}`}>
//                         {/* number of scans or link to payment */}
//                         {isBalance.current == true ? (
//                             <div className={`trash-text ${isSmallScreen ? "mobile" : ""}`}>
//                                 {" "}
//                                 {`${imagesNum} ${t.uploadScans.scans} `}
//                             </div>
//                         ) : (
//                             <u className="go-to-payment" type="button" onClick={() => navigate("/payment-page")} >
//                                 {imagesNum > 0 && t.uploadScans.insufficientBalance}{" "}
//                                 {t.uploadScans.goToPayments}
//                             </u>
//                         )}

//                         {/* actions buttons */}
//                         <div className={`trashcan-wrapper ${isSmallScreen ? "mobile" : ""}`}>
//                             <div className={`trashcan ${isSmallScreen ? "mobile" : ""} ${selectedImage && imageQualityMessage === "" ? "" : "disabled"}`}
//                                 type="button"
//                                 onClick={selectedImage ? () => { setIsCropping(true); setIsCroppingButton(true); } : undefined}
//                                 disabled={!selectedImage || imageQualityMessage != ""}
//                             >
//                                 <img src={selectedImage && imageQualityMessage === "" ? cropActive : crop} className={isSmallScreen ? "trashcan-img-mobile" : "trashcan-img"} ></img>
//                             </div>

//                             <div className={`trashcan ${isSmallScreen ? "mobile" : ""} ${selectedImage ? "" : "disabled"}`}
//                                 type="button"
//                                 onClick={() => rotateImage(90)}
//                             >
//                                 <img src={selectedImage && imageQualityMessage === "" ? rotateActive : rotate} className="rotate-padding"></img>
//                             </div>

//                             <div className={`trashcan ${isSmallScreen ? "mobile" : ""} ${selectedImage != null ? "" : "disabled"}`}
//                                 type="button"
//                                 onClick={deleteImg}
//                             >
//                                 <img src={selectedImage ? trashcanActive : trashcan} ></img>
//                             </div>

//                             <div className={`trashcan plus ${isSmallScreen ? "mobile" : ""} ${selectedImage != null ? "" : "disabled"}`}
//                                 type="button"
//                                 onClick={() => {
//                                     if (files.length > 0) {
//                                         handleUploadClick1();
//                                     }
//                                 }}

//                             >
//                                 <input
//                                     ref={fileInput1Ref}
//                                     type="file"
//                                     style={{ display: "none" }}
//                                     accept=".jpg, .gif, .png"
//                                     onChange={handleFileChange}
//                                     multiple
//                                 />

//                                 <img src={selectedImage ? plusScanActive : plusScan}></img>
//                             </div>
//                         </div>

//                     </div>
//                 </div>

//                 {/* upload scan modal */}
//                 {!pictue && !upload && !addingImages ? (
//                     <label
//                         className={`add-file-wrapper  ${isSmallScreen ? "mobile" : ""} ${isBalance.current ? '' : "disabled"} mt-2 mb-3`}
//                         htmlFor="file-upload"
//                         onClick={handleUploadClick}
//                     >
//                         <input
//                             ref={fileInputRef}
//                             type="file"
//                             style={{ display: "none" }}
//                             accept=".jpg, .gif, .png"
//                             onChange={handleFileChange}
//                             multiple
//                         />

//                         <img src={addFile} className={`add-file-image ${isSmallScreen ? "mobile" : ""}`} />
//                         <div className={`${isBalance.current ? "" : "no-balance"} text-center add-files-text pt-4 ${isSmallScreen ? "small" : ""}`}>
//                             {isBalance.current ? t.uploadScans.addFiles : t.uploadScans.noBalance}
//                         </div>
//                         <div className={`text-center instructions-text pt-4 ${isSmallScreen ? "small" : ""} wrap`}>
//                             {isBalance.current ? t.uploadScans.instructions : ""}
//                         </div>
//                     </label>
//                 ) : (
//                     <div className="add-file-wrapper block"></div>
//                 )}
//                 {loaded == true &&
//                     <div className="slider padding">
//                         <div className={`dot ${activeIndex === 0 ? 'active' : ''}`}></div>
//                         <div className={`dot ${activeIndex === 1 ? 'active' : ''}`}></div>
//                         <div className={`dot ${activeIndex === 2 ? 'active' : ''}`}></div>
//                     </div>
//                 }

//                 {/* <Modal
//                     show={isShowModal}
//                     onHide={() => setIsShowModal(false)}
//                     centered
//                     className={`modal ${isSmallScreen ? 'buttom-modal' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
//                 >
//                     <Modal.Body className='pdf-modal' >
//                         <div className={`img-button mt-2`} onClick={handleUploadClick} >
//                             {t.uploadScans.uploadScansFromGallery}
//                             <img src={plus} className="img-btn-plus"></img>
//                             <input
//                                 ref={fileInputRef}
//                                 type="file"
//                                 style={{ display: "none" }}
//                                 accept=".jpg, .gif, .png"
//                                 onChange={handleFileChange}
//                                 multiple
//                             />
//                         </div>
//                     </Modal.Body>
//                 </Modal > */}


//                 {/* form and galerry */}
//                 <Form onSubmit={onSubmit}>
//                     {({ handleSubmit }) => (
//                         <form onSubmit={handleSubmit}>
//                             <Field name="files">
//                                 {({ input }) => (
//                                     <>
//                                         {(pictue === true || addingImagesPicture == true) && (
//                                             <div>
//                                                 {(!files.length || addingImagesPicture == true) && (
//                                                     <div className="camera-wrapper">
//                                                         here camera component                                                    </div>
//                                                 )}
//                                             </div>
//                                         )}
//                                     </>
//                                 )}
//                             </Field>

//                             <div className="row image-gallery">
//                                 {selectedImage && (
//                                     pictue && addingImagesPicture == true ? (
//                                         <></>
//                                     ) : (
//                                         <div>
//                                             {/* next and previous buttons */}
//                                             <div className={`button-wrapper  ${isSmallScreen ? 'mobile' : ''} ${currentLanguage == "en-US" ? "ltr" : ""}`}>
//                                                 {files.length > 1 && (
//                                                     <div type="button" className="image-container-crop" onClick={setPreviousImage} >
//                                                         <img src={imgArrowContainer} alt="Image 1" className="base-image" />
//                                                         <img src={imgArrow} alt="Image 2" className="overlay-image" />
//                                                     </div>
//                                                 )}
//                                                 {files.length > 1 && (
//                                                     <div type="button" className="image-container-crop" onClick={setNextImage} >
//                                                         <img src={imgArrowContainer} alt="Image 1" className="base-image" />
//                                                         <img src={imgArrowRotate} alt="Image 2" className="overlay-image" />
//                                                     </div>
//                                                 )}
//                                             </div>

//                                             {/* show frame image or cropper */}
//                                             <div className="cropper-container">
//                                                 <Modal
//                                                     fullscreen
//                                                     show={isCropping}
//                                                     // onHide={() => setIsCropping(false)}
//                                                     centered
//                                                     style={
//                                                         isSmallScreen ?
//                                                             {
//                                                                 margin: '0.1rem',
//                                                                 maxWidth: 'calc(100% - 0.2rem)',
//                                                                 maxHeight: 'calc(100% - 0.2rem)',
//                                                                 borderRadius: '0.5rem',
//                                                             }
//                                                             :

//                                                             {
//                                                                 margin: currentLanguage == 'en-US' ? '1rem 1rem 1rem 17rem ' : '1rem 17rem 1rem 1rem',
//                                                                 maxWidth: 'calc(100% - 18rem)',
//                                                                 maxHeight: 'calc(100% - 2rem)',
//                                                                 borderRadius: '0.5rem',
//                                                             }}
//                                                 >
//                                                     <Modal.Header style={isSmallScreen ? {} : { padding: '1rem 19rem 1rem 1rem' }}>
//                                                         <div className={`cropper-header ${currentLanguage == 'en-US' ? "ltr" : ''}`}>

//                                                             {/* <div className="img-number">
//                                 <div type='button' onClick={() => { setIsCropping(false); activateXPress() }}><img src={xBtn}></img></div>
//                                 <div className="">{`${t.scanTypesSingle[scanType]} ${isCroppingButton ? selectedImage?.index + 1 : selectedImage?.tempIndex + 1} / ${isCroppingButton ? files?.length : filesToCrop?.length}`}</div>
//                               </div> */}

//                                                             <div className={`ok-cancel-wrapper  ${isSmallScreen ? 'mobile' : ''}`}>
//                                                                 <div className="button-text-wrapper">
//                                                                     <div className={`trashcan plus ${isSmallScreen ? "mobile" : ""} ${selectedImage && imageQualityMessage === "" ? "" : "disabled"}`}
//                                                                         type="button"
//                                                                         onClick={cancelCroping}
//                                                                     >
//                                                                         <img src={x}></img>
//                                                                     </div>
//                                                                     <div className="crop-text">{t.uploadScans.cancel}</div>
//                                                                 </div>

//                                                                 <div className="button-text-wrapper">
//                                                                     <div className={`trashcan plus ${isSmallScreen ? "mobile" : ""} ${selectedImage && imageQualityMessage === "" ? "" : "disabled"}`}
//                                                                         type="button"
//                                                                         onClick={handleCrop}
//                                                                         disabled={!selectedImage || imageQualityMessage != ""}
//                                                                     >
//                                                                         <img src={v}></img>
//                                                                     </div>
//                                                                     <div className="crop-text"> {t.uploadScans.crop}</div>
//                                                                 </div>

//                                                                 <img src={grayLine}></img>

//                                                                 {/* <div className="button-text-wrapper">
//                                                                     <div
//                                                                         className={`trashcan ${isSmallScreen ? "mobile" : ""} white-crop`}
//                                                                         type="button"
//                                                                         onClick={() => rotateImage(0.5)}
//                                                                     >
//                                                                         <img src={rotateAdd1}></img>
//                                                                     </div>
//                                                                     <div className="crop-text">{t.uploadScans.rotateAdd1}</div>

//                                                                 </div> */}

//                                                                 <div className="button-text-wrapper">
//                                                                     <div
//                                                                         className={`trashcan ${isSmallScreen ? "mobile" : ""} crop`}
//                                                                         type="button"
//                                                                         onClick={() => rotateImage(90)}
//                                                                     >
//                                                                         <img src={rotate90}></img>
//                                                                     </div>
//                                                                     <div className="crop-text">{t.uploadScans.rotate}</div>
//                                                                 </div>

//                                                                 {/* <div className="button-text-wrapper">
//                                                                     <div className={`trashcan ${isSmallScreen ? "mobile" : ""} white-crop`}
//                                                                         type="button"
//                                                                         onClick={() => rotateImage(-0.5)}
//                                                                     >
//                                                                         <img src={rotateLess1}></img>
//                                                                     </div>
//                                                                     <div className="crop-text">{t.uploadScans.rotateLess1}</div>
//                                                                 </div> */}

//                                                                 <div className="button-text-wrapper">
//                                                                     <div className={`trashcan ${isSmallScreen ? "mobile" : ""} crop`}
//                                                                         type="button"
//                                                                         onClick={() => deleteImg()}
//                                                                     >
//                                                                         <img src={trashcanActive}></img>
//                                                                     </div>
//                                                                     <div className="crop-text">{t.uploadScans.delete}</div>
//                                                                 </div>

//                                                             </div>

//                                                             <div className={`crop-instructions ${isSmallScreen ? 'mobile' : ''}`}><img src={info}></img>{t.uploadScans.cropInstructions}</div>

//                                                         </div>
//                                                     </Modal.Header>
//                                                     <Modal.Body style={isSmallScreen ? { padding: 0, margin: '0.5rem 0rem' } : { padding: '1rem 19rem 1rem 1rem' }}>
//                                                         <div id='cropperDiv' className={`cropper-wrapper image-container-cropper`}>

//                                                             <div type="button" className={`image-container-crop big ${isSmallScreen ? 'mobile' : ''}`} onClick={setNextImageToCrop} >
//                                                                 <img src={imgArrowContainerCropRotate} alt="Image 1" className="base-image" />
//                                                             </div>

//                                                             <div type="button" className={`image-container-crop big right ${isSmallScreen ? 'mobile' : ''}`} onClick={setPreviousImageToCrop} >
//                                                                 <img src={imgArrowContainerCrop} alt="Image 1" className="base-image" />
//                                                             </div>
//                                                             <Stage
//                                                                 width={image.current?.width}
//                                                                 height={image.current?.height}
//                                                                 style={{
//                                                                     height: "100%", width: "100%", background: 'white',
//                                                                     transform: `rotate(${selectedImage?.rotation}deg)`
//                                                                 }}
//                                                                 className="main-image z "
//                                                             >
//                                                                 <Layer
//                                                                 // rotation={selectedImage?.rotation || 0}
//                                                                 // offsetX={image.current?.width / 2 || 0}
//                                                                 // offsetY={image.current?.height / 2 || 0}
//                                                                 // x={image.current?.width / 2 || 0}
//                                                                 // y={image.current?.height / 2 || 0}
//                                                                 >
//                                                                     {loadedImage && (
//                                                                         <KonvaImage
//                                                                             image={loadedImage}
//                                                                             ref={imageRef}
//                                                                             x={0}
//                                                                             y={0}
//                                                                             width={image.current?.width}
//                                                                             height={image.current?.height}
//                                                                             draggable={true}
//                                                                         />
//                                                                     )}

//                                                                     {isCropping && selectedShape && (
//                                                                         <>
//                                                                             <Rect
//                                                                                 ref={cropRef}
//                                                                                 x={selectedShape.attributes.x}
//                                                                                 y={selectedShape.attributes.y}
//                                                                                 width={selectedShape.attributes.width}
//                                                                                 height={selectedShape.attributes.height}
//                                                                                 rotation={selectedShape.attributes.rotation}
//                                                                                 stroke="red"
//                                                                                 strokeWidth={1}
//                                                                                 draggable
//                                                                                 onTransformEnd={(e) => {
//                                                                                     const node = cropRef.current;
//                                                                                     const scaleX = node.scaleX();
//                                                                                     const scaleY = node.scaleY();
//                                                                                     node.scaleX(1);
//                                                                                     node.scaleY(1);

//                                                                                     const newWidth = Math.max(MIN_RECT_SIZE, node.width() * scaleX);
//                                                                                     const newHeight = Math.max(MIN_RECT_SIZE, node.height() * scaleY);

//                                                                                     const newShape = {
//                                                                                         ...selectedShape,
//                                                                                         attributes: {
//                                                                                             ...selectedShape.attributes,
//                                                                                             x: node.x(),
//                                                                                             y: node.y(),
//                                                                                             width: newWidth,
//                                                                                             height: newHeight,
//                                                                                             rotation: node.rotation(),
//                                                                                         },
//                                                                                     };

//                                                                                     handleShapeChange({
//                                                                                         id: selectedShape.id,
//                                                                                         newItem: newShape,
//                                                                                     });
//                                                                                 }}
//                                                                                 onDragEnd={(e) => {
//                                                                                     const node = cropRef.current;
//                                                                                     const x = Math.max(0, Math.min(node.x(), image.current.width - node.width()));
//                                                                                     const y = Math.max(0, Math.min(node.y(), image.current.height - node.height()));

//                                                                                     const newShape = {
//                                                                                         ...selectedShape,
//                                                                                         attributes: {
//                                                                                             ...selectedShape.attributes,
//                                                                                             x,
//                                                                                             y,
//                                                                                         },
//                                                                                     };

//                                                                                     handleShapeChange({
//                                                                                         id: selectedShape.id,
//                                                                                         newItem: newShape,
//                                                                                     });
//                                                                                 }}
//                                                                             />

//                                                                             <Transformer
//                                                                                 ref={croptrRef}
//                                                                                 rotateEnabled={false}
//                                                                                 flipEnabled={true}
//                                                                                 boundBoxFunc={(oldBox, newBox) => {
//                                                                                     if (newBox.width < MIN_RECT_SIZE || newBox.height < MIN_RECT_SIZE) {
//                                                                                         return oldBox;
//                                                                                     }
//                                                                                     return newBox;
//                                                                                 }}
//                                                                             />
//                                                                         </>
//                                                                     )}
//                                                                 </Layer>
//                                                             </Stage>


//                                                         </div>
//                                                         <canvas ref={canvasRef} style={{ height: "100%", width: "100%", background: 'white', display: 'none', }} />
//                                                         <canvas ref={processedCanvasRef} style={{ display: 'none', }} />

//                                                     </Modal.Body>

//                                                 </Modal>
//                                                 {!isCropping && (
//                                                     <div className={`frame-image`}>
//                                                         <img
//                                                             className={`img-frame ${selectedImage?.rotation == 90 || selectedImage?.rotation == 270 ? 'rotate' : ''}`}
//                                                             style={{ transform: `rotate(${selectedImage?.rotation}deg)` }}
//                                                             src={selectedImage?.url}
//                                                         ></img>
//                                                     </div>
//                                                 )}
//                                             </div>
//                                         </div>
//                                     )
//                                 )
//                                 }
//                                 {/* images gallery */}
//                                 {!isSmallScreen && !(pictue && addingImagesPicture == true) && (
//                                     <div className="row image-list">
//                                         <div className="image-list-inner">
//                                             {files &&
//                                                 files.length > 1 &&
//                                                 files.map((image, index) => (
//                                                     <div
//                                                         type='button'
//                                                         key={index}
//                                                         onMouseOver={() => setIndexMouseHover(index)}
//                                                         onMouseOut={() => setIndexMouseHover(-1)}
//                                                         onClick={() => handleImageClick(image)}
//                                                         className={`image-number-wrapper ${selectedImage?.url === image.url ? "selected" : ""}`}
//                                                     >
//                                                         <div className="gallery-image-wrapper" >
//                                                             <img
//                                                                 src={image.url}
//                                                                 alt={`Image ${index}`}
//                                                                 className={`gallery-image ${isSmallScreen ? "mobile " : ""}`}
//                                                                 style={{ transform: `rotate(${image?.rotation}deg)` }}
//                                                             />
//                                                         </div>
//                                                         <div className="number-in-image">
//                                                             {indexMouseHover == index && (
//                                                                 <div className="num">
//                                                                     {files.length} / {index + 1}
//                                                                 </div>
//                                                             )}
//                                                         </div>
//                                                     </div>
//                                                 ))}
//                                         </div>
//                                     </div>
//                                 )}
//                             </div>

//                         </form>
//                     )}
//                 </Form>
//                 {/* confirm scan modal */}
//                 <div className="row mb-2">
//                     <div
//                         className={`container frame-a ${currentLanguage == "en-US" ? "ltr" : ""
//                             } ${isSmallScreen ? "col-11 mobile" : "col-4"} `}
//                     >
//                         <div className={`frame-b ${isSmallScreen ? "mobile" : ""}`}>
//                             <div
//                                 type="button"
//                                 onClick={disabledButton ? undefined : openAreYouSure}
//                                 disabled={disabledButton}
//                                 className={`btn-ok ${isSmallScreen ? "mobile" : ""} ${disabledButton ? "disabled" : ""}`}
//                                 ref={disabledButton || !isBalance.current ? undefined : buttonRef}
//                             >
//                                 <div className={`btn-ok-text ${isSmallScreen ? "mobile" : ""} ${disabledButton ? "disabled" : ""}`}>
//                                     {t.uploadScans.confirmScan}
//                                 </div>
//                             </div>
//                             <div
//                                 type="button"
//                                 className={`btn-cancel ${isSmallScreen ? "mobile" : ""}`}
//                                 onClick={cancel}
//                             >
//                                 <div className={`btn-ok-text cancel ${isSmallScreen ? "mobile " : ""}`}>
//                                     {t.uploadScans.cancel}
//                                 </div>
//                             </div>
//                         </div>
//                         <div type="button" className={`back ${isSmallScreen ? "mobile" : ""}`}>
//                             <div
//                                 className={`back-text ${isSmallScreen ? "mobile" : ""}`}
//                                 onClick={() => navigate(-1)}
//                             >
//                                 {t.uploadScans.back}
//                             </div>
//                         </div>
//                     </div>
//                 </div>


//                 {/* error modal */}
//                 <Modal
//                     show={isShowErrorModal}
//                     onHide={() => setIsShowErrorModal(false)}
//                     centered
//                     className={`modal ${isSmallScreen ? "" : `right-margin ${currentLanguage == "en-US" ? "ltr" : ""}`}`}
//                 >
//                     <Modal.Header closeButton>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <div className="images-not-uploaded">
//                             {isSome ? isSome : t.uploadScans.imagesNotUpload}
//                         </div>
//                         {isSmallScreen ? t.uploadScans.imagesNotUploadMessageMobile : t.uploadScans.imagesNotUploadMessage}
//                     </Modal.Body>
//                 </Modal>


//                 {/* are you sure modal */}
//                 <Modal
//                     show={isShowAreYouSure}
//                     onHide={() => { setIsShowAreYouSure(false); activateCancelAreYouSure() }}
//                     centered
//                     className={`modal ${isSmallScreen ? "" : `right-margin ${currentLanguage == "en-US" ? "ltr" : ""}`}`}
//                 >
//                     <Modal.Body>
//                         <div
//                             type="button"
//                             className={isSmallScreen ? "are-you-sure mobile" : "are-you-sure"}
//                         >
//                             <div className={`back-text ${isSmallScreen ? "mobile" : ""}`}>
//                                 {t.uploadScans.areYouSure}
//                             </div>
//                         </div>
//                         <div className={`frame-b ${isSmallScreen ? "mobile" : ""} ${currentLanguage == "en-US" ? "" : "reverse"}`}>
//                             <div
//                                 type="button"
//                                 ref={button2Ref}
//                                 className={isSmallScreen ? "btn-ok mobile col-6 " : files.length > 0 ? "btn-ok col-6" : "btn-ok disabled col-6"}
//                                 onClick={onSubmit}
//                             >
//                                 <div className="ok-button">
//                                     <div>{t.uploadScans.ok}</div>
//                                     {currentUser?.Role == 'user' &&
//                                         <div className={`${currentLanguage == "en-US" ? "" : "direction-rtl reverse"}`} >{`${sumToPay} ${t.payment.scans}`}</div>
//                                     }
//                                 </div>
//                             </div>
//                             <div
//                                 type="button"
//                                 className={isSmallScreen ? "btn-cancel mobile col-6" : "btn-cancel col-6"}
//                                 onClick={() => { setIsShowAreYouSure(false); activateCancelAreYouSure(); }}
//                             >
//                                 <div className={isSmallScreen ? "btn-ok-text cancel mobile " : "btn-ok-text cancel"}>
//                                     {t.uploadScans.cancel}
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="dont-show">
//                             {t.uploadScans.dontShowAgain}
//                             <input type="checkbox" onChange={(e) => dontShowAgain(e)}></input>
//                         </div>
//                     </Modal.Body>
//                 </Modal>
//             </div>

//             {/* spinner */}
//             {disabledPage == true &&
//                 <ScanSlider images={files} scanType={scanType}></ScanSlider>
//             }
//         </div >
//     );
// };
// export default UploadScan;


import React, { useEffect, useRef, useState } from "react";
import {
    createScan,
    deleteScans,
    scansIdsToUpload,
    setScansFolder,
} from "../store/action";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field } from "react-final-form";
import {
    dataURLtoBlob,
    nisPerAffairInTeffilin,
    nisPerMezuza,
    nisPerSheetOfSefetTora,
    smallScreen,
    useLanguage,
} from "../../../utils/constants";
import addFile from "../../../utils/assets/add-file.svg";
import trashcan from "../../../utils/assets/trashcan.svg";
import trashcanActive from "../../../utils/assets/trashcan-active.svg";
import plusScan from "../../../utils/assets/plus-scan.svg";
import plusScanActive from "../../../utils/assets/plus-scan-active.svg";
import "./upload-scan-style.scss";
import { useNavigate } from "react-router-dom";
import imgArrow from "../../../utils/assets/img-arrow.svg";
import imgArrowContainer from "../../../utils/assets/img-arrow-container.svg";
import imgArrowContainerCrop from "../../../utils/assets/img-arrow-container-crop.svg";
import imgArrowContainerCropRotate from "../../../utils/assets/img-arrow-container-crop-rotate.svg";
import imgArrowRotate from "../../../utils/assets/img-arrow-rotate.svg";
import crop from "../../../utils/assets/crop.svg";
import cropActive from "../../../utils/assets/crop-active.svg";
import grayLine from "../../../utils/assets/gray-line.svg";
import x from "../../../utils/assets/x.svg";
import v from "../../../utils/assets/v.svg";
import rotate90 from "../../../utils/assets/rotate-90.svg";
import rotateAdd1 from "../../../utils/assets/rotate-add-deg.svg";
import rotateLess1 from "../../../utils/assets/rotate-less-deg.svg";
import info from "../../../utils/assets/info.svg";
import infoMobile from "../../../utils/assets/info.svg";
import rotate from "../../../utils/assets/rotate.svg";
import rotateActive from "../../../utils/assets/rotate-90.svg";
import Header from "../../header/header";
import { Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import 'react-advanced-cropper/dist/themes/corners.css';
// import 'react-advanced-cropper/dist/themes/bubble.css';
import ReactGA from 'react-ga4';

import {
    getCurrentUserById,
    updateUserBalanceInProgress,
    updateUserDontShowAgain,
    updateUserLastPaymentAndBalance,
} from "../../user/store/action";
import { createReport, setConfirmedScansIds } from "../../report/store/action";
import Modal from "react-bootstrap/Modal";
import "react-html5-camera-photo/build/css/index.css";
import EXIF from 'exif-js';
import ScanSlider from "../../../utils/scan-slider/scan-slider";

const UploadScan = () => {
    const currentUserId = JSON.parse(localStorage.getItem("currentUser"))?.id;
    const currentProjectId = JSON.parse(sessionStorage.getItem("currentProjectId"));
    const currentProjectName = JSON.parse(sessionStorage.getItem("currentProjectName"));
    const scanType = JSON.parse(sessionStorage.getItem("scanType"));
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const t = useLanguage();
    const isSmallScreen = smallScreen();
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [filesToCrop, setFilesToCrop] = useState([]);
    const [upload, setUpload] = useState(false);
    const [pictue, setPicture] = useState(false);
    const [resolution, setResolution] = useState();
    const [croppedImage, setCroppedImage] = useState("");
    const [imageQualityMessage, setImageQualityMessage] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagesNum, setImageNum] = useState(0);
    const [addingImages, setAddingImages] = useState(false);
    const [wait, setWait] = useState(false);
    const [addingImagesPicture, setAddingImagesPicture] = useState(false);
    const [indexMouseHover, setIndexMouseHover] = useState(-1);
    const navigate = useNavigate();
    const webcamRef = useRef(null);
    const cropperRef = useRef(null);
    const canvasRef = useRef(null);
    const isClickOkButton = useRef(false);
    const [isCropping, setIsCropping] = useState(false);
    const [isRotate, setIsRotate] = useState(true);
    const [isCroppingButton, setIsCroppingButton] = useState(false);
    const [disabledPage, setDisabledPage] = useState(false);
    const isCurrentUserEffect = useRef(true);
    const [sumToPay, setSumToPay] = useState(0);
    const isBalance = useRef(true);
    const [spinner, setSpinner] = useState(0);
    const [isShowModal, setIsShowModal] = useState(false);
    const fileInputRef = useRef(null);
    const fileInput1Ref = useRef(null);
    const bool = useRef(false);
    const [coords, setCoords] = useState({});
    const priceList = {
        mezuza: nisPerMezuza(),
        tefillin: nisPerAffairInTeffilin(),
        tora: nisPerSheetOfSefetTora(),
    };
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const [isShowAreYouSure, setIsShowAreYouSure] = useState(false);
    const [isShowErrorModal, setIsShowErrorModal] = useState(false);
    const [isSome, setIsSome] = useState('');
    const disabledButton = files.length === 0 || !isBalance.current || isCropping
    const buttonRef = useRef(null);
    const button2Ref = useRef(null);
    const recognizeRef = useRef(true)
    const [loaded, setLoaded] = useState(false);
    const cv = window.cv;
    const imagesRotetedIndex = useRef([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const timerRef = useRef(null);

    useEffect(() => {
        setCurrentLanguage(language);
    }, [language]);

    useEffect(() => {
        if (currentUserId && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
            const bool = currentUser?.balance > 0;
            if (bool)
                isBalance.current = bool;
        }
    }, [currentUserId]);

    useEffect(() => {
        checkImageResolution(croppedImage);
    }, [croppedImage]);

    useEffect(() => {
        setImageNum(files.length);
        if (files.length && selectedImage == null) {
            setSelectedImage(files[0]);
        }

        setSumToPay(files.length);
        const bool = sumToPay > 0 ? currentUser?.balance - sumToPay >= 0 : currentUser?.balance > 0;
        if (bool) {
            isBalance.current = bool;
        }
    });

    useEffect(() => {
        setSumToPay(files.length);
    }, [files.length]);

    const capturePhoto = (dataUri) => {
        try {
            const imgSrc = dataUri;
            checkImageResolution(imgSrc);
            setFiles((prevFiles) => [
                ...prevFiles,
                {
                    url: imgSrc,
                    rotation: 0,
                },
            ]);
            setSelectedImage({ url: imgSrc, rotation: 0, type: "" });
            setAddingImages(false);
            setAddingImagesPicture(false);
            setSumToPay(sumToPay + files.length);
            isBalance.current = currentUser?.balance - sumToPay >= 0;
        } catch (error) {
            console.log(error);
        }
    };

    const handlePictureClick = () => {
        setUpload(false);
        setPicture(true);
        if (addingImages == true) setAddingImagesPicture(true);
    };

    const handleUploadClick = () => {
        console.log('addingImages', addingImages);
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
        setPicture(false);
        // setIsShowModal(false);
        // if (addingImages == true) setAddingImagesUpload(true);
        if (currentUser?.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'לחיצה על העלאת תמונות מהגלריה',
                label: 'עמוד העלאת סריקות',
                value: 1
            });
    };
    const handleUploadClick1 = () => {
        console.log('addingImages', addingImages);
        if (fileInput1Ref.current) {
            fileInput1Ref.current.click();
        }
        setPicture(false);
        // setIsShowModal(false);
        // if (addingImages == true) setAddingImagesUpload(true);
        if (currentUser?.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'לחיצה על העלאת תמונות מהגלריה',
                label: 'עמוד העלאת סריקות',
                value: 1
            });
    };

    const handleCrop = () => {
        if (cropperRef.current) {
            console.log('cropperRef.current', cropperRef.current);
            console.log('aaaaaaaaaaaaaaaa', cropperRef.current.getStencilCoordinates);

            const croppedCanvas = cropperRef.current.getCanvas();
            const dataURL = croppedCanvas.toDataURL("image/jpeg");
            // checkImageResolution(dataURL);
            // if (imageQualityMessage == "") {
            const updatedFiles = [...files];

            if (selectedImage !== null) {
                const originalImageIndex = files.findIndex(file => file.url === selectedImage?.url);
                if (originalImageIndex !== -1) {
                    updatedFiles[originalImageIndex].url = dataURL;
                    updatedFiles[originalImageIndex].rotation = 0;
                }
            }

            setFiles(updatedFiles);

            if (isCroppingButton || selectedImage?.index == files.length - 1 || selectedImage?.tempIndex == filesToCrop.length - 1) {
                setIsCropping(false);
                setIsCroppingButton(false);
            }
            else
                setNextImageToCrop();
            if (currentUser?.Role == 'user')
                ReactGA.event({
                    category: 'Button',
                    action: 'אישור חיתוך',
                    label: 'עמוד העלאת סריקות',
                    value: 1
                });

        }
    };

    const checkImageResolution = (img) => {
        const image = new Image();
        image.src = img;
        image.onload = () => {
            const imageWidth = image.width;
            const imageHeight = image.height;
            const localResolution = (imageWidth * imageHeight) / 1000000;
            const minResolution = 1;
            setResolution(localResolution);
            if (resolution < minResolution) {
                setImageQualityMessage(t.uploadScans.resolutionMessage);
            } else {
                setImageQualityMessage("");
            }
        };
    };

    const onSubmit = async () => {
        try {
            activateAreYouSure()
            console.log("onSubmit");
            setIsShowAreYouSure(false);
            setSpinner(files.length * 3);
            setDisabledPage(true);
            console.log("sumToPay", sumToPay);
            const sumToPayToFunc = sumToPay || files.length * 3;
            console.log("sumToPayfunc", sumToPayToFunc);
            const resUpdateUserBalance = await dispatch(
                updateUserBalanceInProgress(currentUserId, sumToPayToFunc)
            );
            console.log("resUpdateUserBalance", resUpdateUserBalance);
            if (resUpdateUserBalance && (resUpdateUserBalance?.message == "success" || resUpdateUserBalance?.message == 'admin or premium user')) {
                const formData = new FormData();
                let rotations = [];
                let filesName = [];
                for (const dataURL of files) {
                    if (scanType == "tefillin") {
                        formData.append("files", dataURLtoBlob(dataURL.url), dataURL.type);
                    } else {
                        formData.append("files", dataURLtoBlob(dataURL.url));
                    }
                    rotations.push(dataURL.rotation);
                    filesName.push(dataURL.fileName);
                }
                console.log('rotations', rotations);
                console.log('filesName', filesName);
                formData.append("userId", currentUserId);
                formData.append("name", t.scanTypesSingle[scanType]);
                formData.append("filesName", JSON.stringify(filesName));
                formData.append("rotations", JSON.stringify(rotations));
                formData.append("relatedProject", currentProjectId);
                formData.append("type", scanType);
                formData.append("sumToPay", sumToPay);
                formData.append("createdAt", new Date());
                formData.append("updatedAt", new Date());
                console.log("formData", formData);
                if (formData) {
                    const resScan = await dispatch(createScan(formData));
                    console.log("resScan✌️✌️✌️", resScan);
                    if (resScan && resScan.message == "scan Created") {
                        console.log("scans uploaded successfuly", resScan.scansListOfFolder);
                        const resReport = await createReportFunc(resScan.scansListOfFolder, rotations);
                        console.log("resreport😊😊😊", resReport);
                        if (resReport && resReport?.data?.report) {
                            const correctScans = resScan.data.filter(
                                (scan) => !resReport.data.incorrectScansIds.includes(scan._id)
                            );
                            console.log("correctScans😉😉😉😉", correctScans.length, correctScans);
                            if (correctScans && correctScans.length > 0) {
                                console.log("דוח נוצר");
                                setFiles([]);
                                setUpload(false);
                                setPicture(false);
                                setCroppedImage("");
                                setResolution(null);
                                setImageQualityMessage("");
                                setSelectedImage(null);
                                let refoundSum = 0;
                                let aaaa = false;
                                let bbbb = '';
                                if (correctScans.length < resScan.data.length) {
                                    dispatch(setScansFolder(correctScans));
                                    const ids = resReport.data.incorrectScansIds;
                                    const failedScansNames = resScan.data
                                        .filter((scan) => ids.includes(scan._id))
                                        .map((scan) => scan.name);
                                    const aaa = failedScansNames.join(", ");
                                    console.log('failedScansNames', failedScansNames);
                                    console.log('aaa', aaa);
                                    const bbb = failedScansNames.length > 1 ? ` הסריקות ${aaa}, לא עלו.` : ` הסריקה ${aaa} לא עלתה.`;
                                    console.log('bbb', bbb);
                                    setIsSome(bbb)
                                    refoundSum = resScan.data.length - correctScans.length;
                                    console.log("refoundSum", refoundSum);
                                    setIsShowErrorModal(true)
                                    aaaa = true;
                                    bbbb = bbb;
                                }
                                setDisabledPage(false);
                                sessionStorage.setItem('isShowErrorModal', JSON.stringify(aaaa))
                                const newScansIds = [];
                                resScan.scansListOfFolder.map((scan) => { newScansIds.push(scan._id) })
                                await dispatch(scansIdsToUpload(newScansIds));

                                navigate("/scans", {
                                    state: {
                                        scans: resScan.scansListOfFolder,
                                        type: scanType,
                                        name: currentProjectName,
                                        isShowErrorModal: aaaa,
                                        isSome: bbbb
                                    },
                                });
                                await dispatch(
                                    updateUserLastPaymentAndBalance(
                                        currentUserId,
                                        sumToPay,
                                        refoundSum
                                    )
                                );

                            }
                            else {
                                setIsShowErrorModal(true)
                                setDisabledPage(false);
                            }
                        }
                        else {
                            console.log("222222222222222222222222222222");
                            let extractedIds = [];
                            console.log("דוח לא נוצר");
                            if (resReport && resReport?.data?.incorrectScansIds) {
                                console.log("3333333333333333333333333333333333");
                                console.log("😘😘", resReport?.data?.incorrectScansIds);
                                extractedIds = resReport?.data?.incorrectScansIds;
                            }
                            // else {
                            //     console.log("44444444444444444444444444444444444");
                            //     extractedIds = resScan.data.map((item) => item._id);
                            // }
                            console.log("ids של סריקות למחיקה", extractedIds);
                            const res = await dispatch(
                                deleteScans(extractedIds, currentUserId, sumToPay)
                            );
                            console.log("res of deleteScans", res);
                            // if (res) await dispatch(setCurrentUser(res.user));
                            setIsShowErrorModal(true)
                            setDisabledPage(false);
                        }
                    } else {
                        console.log("סריקות לא עלו");
                        setIsShowErrorModal(true);
                        setDisabledPage(false);
                    }
                } else {
                    console.log("no form data");
                }
            } else {
                alert("יתרה לא מספיקה");
                setDisabledPage(false);
            }
        } catch (error) {
            console.log("error in upload scan", error);
        }
    };

    const createReportFunc = async (ScanList, rotations) => {
        console.log("createReportFunc", ScanList);
        return new Promise(async (resolve, reject) => {
            try {
                const scansObjects = [];
                if (ScanList?.length > 0) {
                    ScanList.map((scan, index) => {
                        const obj = {
                            userId: currentUserId,
                            scanId: scan._id,
                            scanName: scan.name,
                            relatedProject: currentProjectId,
                            image: scan.images[0],
                            type: scan.type,
                            rotation: rotations[index],
                        };
                        scansObjects.push(obj);
                    });
                    console.log("scansObjects", scansObjects);
                    const report = await dispatch(
                        createReport({
                            scans: scansObjects,
                            scanType: t.scanTypesSingle[scanType],
                            locale: language,
                            userName: currentUser?.customerName,
                            projectName: currentProjectName,
                            projectId: currentProjectId,
                            userRoleAssiment: currentUser?.Role,
                        })
                    );
                    console.log("node.js answer of upload report", report);
                    dispatch(setConfirmedScansIds([]));
                    resolve(report);
                } else {
                    console.log("no allProjectUsersScans");
                    resolve(null);
                }
            } catch (error) {
                console.log(error);
                resolve(null);
            }
        });
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const deleteImg = () => {
        const deletedImgIndex = selectedImage?.index || 0;
        const deletedImgTmpIndex = selectedImage?.tempIndex || 0;
        const updatedFiles = files?.filter((img) => img.url !== selectedImage?.url);
        const updatedFilesIndexs = updatedFiles.map((file, index) => {
            return {
                ...file,
                tempIndex: index,
                index: file.index > deletedImgIndex ? file.index - 1 : file.index
            };
        })
        setFiles(updatedFilesIndexs);
        if (isCropping) {
            const updatedFilesToCrop = filesToCrop?.filter((img) => img.url !== selectedImage?.url);
            const updatedFilesToCropIndexs = updatedFilesToCrop.map((file, index) => {
                return {
                    ...file,
                    tempIndex: index,
                    index: file.index > deletedImgIndex ? file.index - 1 : file.index
                };
            })
            setFilesToCrop(updatedFilesToCropIndexs);
            if (updatedFilesToCrop[0] && !isCroppingButton) {
                if (deletedImgTmpIndex == updatedFilesToCrop.length) {
                    setIsCropping(false)
                    setSelectedImage(updatedFiles[0]);
                }
                else
                    setSelectedImage(updatedFilesToCropIndexs[deletedImgTmpIndex]);
            }
            if (isCroppingButton) {
                setIsCropping(false)
                setSelectedImage(updatedFiles[0]);
                setIsCroppingButton(false)
            }
            if (!updatedFilesToCrop[0]) {
                if (!updatedFiles[0])
                    cancel();
                else
                    setIsCropping(false)

            }
        }
        if (!isCropping) {
            if (updatedFiles[0])
                setSelectedImage(updatedFiles[0]);
            else cancel();
        }

        if (currentUser?.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'מחיקת תמונה במסך החיתוך',
                label: 'עמוד העלאת סריקות',
                value: 1
            });

    };

    const setPreviousImage = () => {
        const activeIndex = files.indexOf(selectedImage);
        const newIndex = activeIndex === 0 ? files.length - 1 : activeIndex - 1;
        setSelectedImage(files[newIndex]);
    };

    const setNextImage = () => {
        const activeIndex = files.indexOf(selectedImage);
        const newIndex = activeIndex === files.length - 1 ? 0 : activeIndex + 1;
        setSelectedImage(files[newIndex]);
    };

    const setNextImageToCrop = () => {
        const activeIndex = selectedImage?.tempIndex;
        const newIndex = activeIndex === filesToCrop.length - 1 ? 0 : activeIndex + 1;
        setSelectedImage(filesToCrop[newIndex]);
        recognizeRef.current = true;
        imagesRotetedIndex.current = []
    }

    const setPreviousImageToCrop = () => {
        const activeIndex = selectedImage?.tempIndex;
        const newIndex = activeIndex === 0 ? filesToCrop.length - 1 : activeIndex - 1;
        setSelectedImage(filesToCrop[newIndex]);
        imagesRotetedIndex.current = []
    }

    const cancel = () => {
        setFiles([]);
        setSelectedImage(null);
        setPicture(false);
        setUpload(false);
        setResolution(null);
        setImageQualityMessage("");
        setDisabledPage(false);
        setIsCropping(false);
        setAddingImages(false);
        if (currentUser?.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'לחיצה על כפתור ביטול סריקה',
                label: 'עמוד העלאת סריקות',
                value: 1
            });

    };

    const handleFileChange = (e) => {
        setLoaded(true)
        setIsCropping(true)
        setIsShowModal(false)
        setWait(true)
        const selectedFiles = e.target.files;
        if (
            selectedFiles?.length > 20 ||
            files?.length + selectedFiles?.length > 20
        ) {
            alert("ניתן להעלות עד 20 קבצים בתיקיה אחת.");
            setLoaded(false)
        } else {
            if (selectedFiles.length > 0) {
                const sortedSelectedFiles = [...selectedFiles].sort((a, b) => a.lastModified - b.lastModified);

                setUpload(true);
                const reader = new FileReader();

                reader.onloadend = () => {
                    let array = [];
                    array = Array.from(sortedSelectedFiles).map((file, index) => {
                        const originalName = file.name;
                        const nameWithoutExtension = originalName.substring(0, originalName.lastIndexOf('.'));

                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(file);
                        return new Promise((resolve) => {
                            fileReader.onloadend = () => {
                                resolve({
                                    index: files.length + index,
                                    tempIndex: index,
                                    url: fileReader.result,
                                    rotation: 0,
                                    fileName: nameWithoutExtension,
                                });
                            };
                        });
                    });

                    Promise.all(array).then((dataURLs) => {
                        setFiles((prevFiles) => {
                            const newFiles = [...prevFiles, ...dataURLs];
                            setSumToPay((prevSum) => prevSum + dataURLs.length);
                            isBalance.current = currentUser?.balance - sumToPay >= 0;
                            return newFiles;
                        });
                        setSelectedImage(dataURLs[0]);
                        setFilesToCrop(dataURLs);
                    });
                };
                reader.readAsDataURL(selectedFiles[0]);
                timerRef.current = setTimeout(() => {
                    setLoaded(false)
                }, 4000);

            }
            setWait(false)
        }
    };

    useEffect(() => {
        if (isCropping == false) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
            setLoaded(false)
        }
    }, [isCropping]);


    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex + 1) % 3);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const cancelCroping = () => {
        if (isCroppingButton || selectedImage?.index == files.length - 1 || selectedImage?.tempIndex == filesToCrop.length - 1) {
            setIsCropping(false)
            setIsCroppingButton(false);
        }
        else {
            setNextImageToCrop();
        }
        if (currentUser?.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'ביטול חיתוך',
                label: 'עמוד העלאת סריקות',
                value: 1
            });

    };

    useEffect(() => {
        try {
            if (buttonRef.current) {
                buttonRef.current.focus();

                const handleKeyPress = (event) => {
                    if (event.key === "Enter") {
                        if (
                            disabledButton === false &&
                            disabledPage === false &&
                            isClickOkButton.current == false
                        ) {
                            openAreYouSure();
                            // isClickOkButton.current = true;
                            // buttonRef.current = null;
                        }
                    }
                };

                document.addEventListener("keydown", handleKeyPress);

                return () => {
                    document.removeEventListener("keydown", handleKeyPress);
                };
            }
        } catch (error) {
            console.log(error);
        }
    }, [disabledButton]);

    useEffect(() => {
        try {
            if (isShowAreYouSure == true) {
                button2Ref.current.focus();

                const handleKeyPress = (event) => {
                    if (event.key === "Enter") {
                        onSubmit();
                        setIsShowAreYouSure(false);
                        isClickOkButton.current = true;
                    }
                };

                document.addEventListener("keydown", handleKeyPress);

                return () => {
                    document.removeEventListener("keydown", handleKeyPress);
                };
            }
        } catch (error) {
            console.log(error);
        }
    }, [isShowAreYouSure]);

    const openAreYouSure = () => {
        if (currentUser?.dontShowAgainUploadScan == true) {
            onSubmit()
        }
        else {
            setIsShowAreYouSure(true);
            if (currentUser?.Role == 'user')
                ReactGA.event({
                    category: 'Button',
                    action: 'לחיצה על כפתור אשר סריקה',
                    label: 'עמוד העלאת סריקות',
                    value: 1
                });
        }
    };

    const rotateImage = (degrees) => {
        const updatedFiles = [...files];
        if (selectedImage !== null) {
            //rotate the image in the cropper
            const cropper = cropperRef.current;
            if (cropper) {
                cropper.rotateImage(degrees);
                cropper.zoomImage(0.1);
            }

            let originalImageIndex = -1;
            files.map((file, index) => {
                if (file.url == selectedImage?.url) {
                    originalImageIndex = index;
                }
            });
            const newRotation = (updatedFiles[originalImageIndex].rotation + degrees) % 360;
            updatedFiles[originalImageIndex].rotation = newRotation;
            setFiles(updatedFiles);
            if (degrees == 90 && (isCropping || isCroppingButton))
                processImage(selectedImage.url)
            if (currentUser?.Role == 'user')
                ReactGA.event({
                    category: 'Button',
                    action: 'סיבוב תמונה',
                    label: 'עמוד העלאת סריקות',
                    value: 1
                });
        }
    };

    const rotateCropper = () => {
        const cropper = cropperRef.current;
        if (cropper) {
            cropper.rotateImage(selectedImage?.rotation);
            processImage(selectedImage?.url)
        }
    }

    //auto crop
    const processImage = (imageSrc) => {
        console.log('processImage🤢🤢🤢🤢🤢🤢🤢🤢');

        try {
            if (!cv) {
                console.error('OpenCV.js is not loaded');
                return;
            }

            const img = new Image();
            img.src = imageSrc;
            console.log('img src');

            img.onload = () => {
                EXIF.getData(img, function () {
                    const orientation = EXIF.getTag(this, 'Orientation');
                    let metadataRotation = 0;

                    switch (orientation) {
                        case 3:
                            metadataRotation = 180;
                            break;
                        case 6:
                            metadataRotation = 90;
                            break;
                        case 8:
                            metadataRotation = 270;
                            break;
                        default:
                            metadataRotation = 0;
                    }
                    console.log('metadataRotation❌', metadataRotation);
                    const canvas = canvasRef.current;
                    const ctx = canvas.getContext('2d');
                    const maxWidth = 1536;
                    const maxHeight = 695;

                    let width = img.width;
                    let height = img.height;

                    if (width > maxWidth || height > maxHeight) {
                        const aspectRatio = width / height;
                        if (width > height) {
                            width = maxWidth;
                            height = maxWidth / aspectRatio;
                        } else {
                            height = maxHeight;
                            width = maxHeight * aspectRatio;
                        }
                    }

                    const isRotatedImage = selectedImage.rotation == 90 || selectedImage.rotation == 270// || metadataRotation === 90 || metadataRotation === 270;
                    console.log('isRotatedImage', isRotatedImage);
                    canvas.width = isRotatedImage ? height : width;
                    canvas.height = isRotatedImage ? width : height;

                    ctx.save();
                    ctx.translate(canvas.width / 2, canvas.height / 2);
                    const rotationAngle = selectedImage?.rotation % 360;
                    ctx.rotate((rotationAngle * Math.PI) / 180);
                    ctx.drawImage(img, -width / 2, -height / 2, width, height);
                    ctx.restore();

                    // Process the image using OpenCV
                    const src = cv.imread(canvas);
                    if (!src || src.empty()) {
                        console.error('OpenCV: Failed to read image from canvas');
                        return;
                    }
                    const gray = new cv.Mat();
                    cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY, 0);
                    const blurred = new cv.Mat();
                    cv.GaussianBlur(gray, blurred, new cv.Size(7, 7), 0);
                    const edges = new cv.Mat();
                    cv.Canny(blurred, edges, 90, 205, 3, false);
                    let kernel;
                    if (scanType == 'tefillin') {
                        kernel = cv.Mat.ones(11, 11, cv.CV_8U);
                    }
                    else {
                        kernel = cv.Mat.ones(9, 9, cv.CV_8U);
                    }

                    const dilated = new cv.Mat();
                    cv.dilate(edges, dilated, kernel);
                    const contours = new cv.MatVector();
                    const hierarchy = new cv.Mat();
                    cv.findContours(dilated, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);

                    let maxContour = null;
                    let maxArea = 0;
                    // console.log('contours.size()', contours.size());
                    for (let i = 0; i < contours.size(); ++i) {
                        const contour = contours.get(i);
                        const area = cv.contourArea(contour);
                        // console.log('area', area);
                        if (area > maxArea) {
                            maxArea = area;
                            maxContour = contour;
                        }
                    }
                    if (maxContour == null) {
                        console.error('OpenCV: maxContour is null');
                        return;
                    }
                    if (scanType != 'tefillin' && maxArea < 100000) {
                        //case of croped images of mezuza 
                        setTimeout(() => {
                            const cropper = cropperRef.current;
                            cropper?.setCoordinates({
                                left: 0,
                                top: 0,
                                width: img.width,
                                height: img.height,
                            });

                        }, 1000);
                    }
                    else {
                        // const rect = cv.boundingRect(maxContour);
                        const rotatedRect = cv.minAreaRect(maxContour);
                        console.log('rotatedRect', rotatedRect);
                        const angle = rotatedRect.angle;
                        const bool = angle < 5 || angle > 85
                        const angleToRotate = bool ? (angle < 5 ? -1 * angle : 90 - angle) : 0;
                        const points = cv.RotatedRect.points(rotatedRect);
                        ctx.strokeStyle = 'red';
                        ctx.lineWidth = 2;
                        ctx.beginPath();
                        ctx.moveTo(points[0].x, points[0].y);
                        for (let i = 1; i < 4; i++) {
                            ctx.lineTo(points[i].x, points[i].y);
                        }
                        ctx.closePath();
                        ctx.stroke();

                        console.log('angle', angle);
                        console.log('angleToRotate', angleToRotate);
                        console.log('points', points);
                        console.log('rotation', selectedImage.rotation);
                        // const rotatedRectX = angle < 5 ? ( Math.abs(points[1].x)) : ( Math.abs(points[0].x));
                        // const rotatedRectY = angle < 5 ? (Math.abs(points[2].y)) : ( Math.abs(points[0].y));
                        //it is the good result:
                        const rotatedRectWidth = angle < 5 ? rotatedRect.size.width : rotatedRect.size.height;
                        const rotatedRectHeight = angle < 5 ? rotatedRect.size.height : rotatedRect.size.width;
                        // const isVerticalAndNoRotation = scanType == 'tefillin' ? (rotatedRectWidth < rotatedRectHeight) ? true : false : false;
                        // const isHorizonalAndNoRotation = scanType == 'tefillin' ? (rotatedRectWidth > rotatedRectHeight && angleToRotate > 1) ? true : false : false;
                        // console.log('isVerticalAndNoRotation', isVerticalAndNoRotation);
                        const isVertical = scanType == 'tefillin' ? (rotatedRectWidth < rotatedRectHeight) ? true : false : false;
                        const isVerticalAnd180Rotation = scanType == 'tefillin' ? (rotatedRectWidth < rotatedRectHeight) && selectedImage.rotation == 180 ? true : false : false;
                        const isHorizonalAndNoRotation = scanType == 'tefillin' ? (rotatedRectWidth > rotatedRectHeight && angleToRotate > 1) ? true : false : false;
                        const isVerticalImg0 = scanType == 'tefillin' ? (img.width < img.height) && selectedImage.rotation == 0 ? true : false : false;
                        const isVerticalImg90 = scanType == 'tefillin' ? (img.width < img.height) && selectedImage.rotation == 90 ? true : false : false;
                        const isVerticalImg270 = scanType == 'tefillin' ? (img.width < img.height) && selectedImage.rotation == 270 ? true : false : false;
                        console.log('isVertical', isVertical);
                        console.log('isHorizonalAndNoRotation', isHorizonalAndNoRotation);
                        const rotatedRectX = angle < 5 ?
                            isVerticalImg90 ? points[0].x * angle
                                : isVerticalImg270 ? points[1].x * angle
                                    : isHorizonalAndNoRotation ? points[1].x + 2 * Math.abs(points[1].x - points[0].x) : points[1].x//
                            : (angleToRotate != 0 ? isHorizonalAndNoRotation ? (points[0].x * angleToRotate)
                                : isHorizonalAndNoRotation ? points[3].x + Math.abs(points[3].x - points[0].x) / 2 : points[3].x //
                                : points[0].x);
                        const rotatedRectY = angle < 5 ? isVerticalAnd180Rotation ? points[1].y * angle : Math.abs(points[2].y) * (isVertical ? angle : 1) : points[0].y;
                        // const rotatedRectY = angle < 5 ? isVerticalAnd180Rotation ? points[1].y * angle : Math.abs(points[2].y) * (isVertical ? angle : 1) : isVerticalImg0 ? points[1].y * angleToRotate : points[0].y;

                        console.log('rotatedRectX', rotatedRectX);
                        console.log('rotatedRectY', rotatedRectY);

                        while (wait == true) {
                            console.log('waitting.....🫷🫷🫷');
                        }
                        console.log('finished🏁🏁🏁');

                        setTimeout(() => {
                            const cropper = cropperRef.current;
                            if (!imagesRotetedIndex?.current?.includes(selectedImage?.url) && scanType == 'tefillin') {
                                setIsRotate(false)
                                cropper?.rotateImage(angleToRotate)
                                imagesRotetedIndex.current = [...imagesRotetedIndex.current, selectedImage?.url];
                                setIsRotate(true)
                            }

                            const cropperState = cropper?.getState();
                            let cropperImgWidth = cropperState?.imageSize.width;
                            let cropperImgHeight = cropperState?.imageSize.height;

                            if (Math.abs(metadataRotation % 180) === 90) {
                                cropperImgWidth = cropperState?.imageSize.height;
                                cropperImgHeight = cropperState?.imageSize.width;
                            }

                            const factorX = cropperImgWidth / width;
                            const factorY = cropperImgHeight / height;

                            cropper?.setCoordinates({
                                left: rotatedRectX * factorX,
                                top: rotatedRectY * factorY,
                                width: rotatedRectWidth * factorX,
                                height: rotatedRectHeight * factorY,
                            });
                            console.log('loaded🏁🏁🏁🏁🏁🏁🏁🏁🏁', loaded)

                        }, 1000);


                    }

                    src.delete();
                    gray.delete();
                    blurred.delete();
                    edges.delete();
                    dilated.delete();
                    contours.delete();
                    hierarchy.delete();
                    if (maxContour)
                        maxContour.delete();
                    setIsRotate(true);
                });
            };
            img.onerror = () => {
                console.error('Failed to load image');
            };

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (selectedImage && isCropping && isRotate) {
            processImage(selectedImage?.url)
        }
    }, [selectedImage?.url])

    const activateCancelAreYouSure = () => {
        if (currentUser?.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'ביטול בהאם אתה בטוח',
                label: 'עמוד העלאת סריקות',
                value: 1
            });
    }
    const activateAreYouSure = () => {
        if (currentUser?.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'אישור בהאם אתה בטוח',
                label: 'עמוד העלאת סריקות',
                value: 1
            });
    }
    const dontShowAgain = (event) => {
        const checkbox = event.target;

        if (checkbox.checked) {
            dispatch(updateUserDontShowAgain({ userId: currentUserId, parameter: 'dontShowAgainUploadScan' }))
        }
    }

    return (
        <div className={`upload-scan-page-container  ${isSmallScreen ? " mobile" : ""}`}>
            <div className={` ${isSmallScreen ? "" : "container upload-scan-wrapper"}`}>

                <div className={`row pt-2 pb-2 ${currentLanguage == "en-US" ? "pr-0" : "pl-0"}`}>
                    <Header page={"upload-scan"} context={[t.uploadScans.uploadImages, ">", , t.scanTypes[scanType], ">", currentProjectName,]}></Header>
                </div>

                {/* header of page */}
                <div className="row ">
                    <div className={`col header-wrapper ${isSmallScreen ? "mobile" : ""}`}>
                        {/* number of scans or link to payment */}
                        {isBalance.current == true ? (
                            <div className={`trash-text ${isSmallScreen ? "mobile" : ""}`}>
                                {" "}
                                {`${imagesNum} ${t.uploadScans.scans} `}
                            </div>
                        ) : (
                            <u className="go-to-payment" type="button" onClick={() => navigate("/payment-page")} >
                                {imagesNum > 0 && t.uploadScans.insufficientBalance}{" "}
                                {t.uploadScans.goToPayments}
                            </u>
                        )}

                        {/* actions buttons */}
                        <div className={`trashcan-wrapper ${isSmallScreen ? "mobile" : ""}`}>
                            <div className={`trashcan ${isSmallScreen ? "mobile" : ""} ${selectedImage && imageQualityMessage === "" ? "" : "disabled"}`}
                                type="button"
                                onClick={selectedImage ? () => { setIsCropping(true); setIsCroppingButton(true); setTimeout(rotateCropper, 100); } : undefined}
                                disabled={!selectedImage || imageQualityMessage != ""}
                            >
                                <img src={selectedImage && imageQualityMessage === "" ? cropActive : crop} className={isSmallScreen ? "trashcan-img-mobile" : "trashcan-img"} ></img>
                            </div>

                            <div className={`trashcan ${isSmallScreen ? "mobile" : ""} ${selectedImage ? "" : "disabled"}`}
                                type="button"
                                onClick={() => rotateImage(90)}
                            >
                                <img src={selectedImage && imageQualityMessage === "" ? rotateActive : rotate} className="rotate-padding"></img>
                            </div>

                            <div className={`trashcan ${isSmallScreen ? "mobile" : ""} ${selectedImage != null ? "" : "disabled"}`}
                                type="button"
                                onClick={deleteImg}
                            >
                                <img src={selectedImage ? trashcanActive : trashcan} ></img>
                            </div>

                            <div className={`trashcan plus ${isSmallScreen ? "mobile" : ""} ${selectedImage != null ? "" : "disabled"}`}
                                type="button"
                                onClick={() => {
                                    if (files.length > 0) {
                                        handleUploadClick1();
                                    }
                                }}

                            >
                                <input
                                    ref={fileInput1Ref}
                                    type="file"
                                    style={{ display: "none" }}
                                    accept=".jpg, .gif, .png"
                                    onChange={handleFileChange}
                                    multiple
                                />

                                <img src={selectedImage ? plusScanActive : plusScan}></img>
                            </div>
                        </div>

                    </div>
                </div>

                {/* upload scan modal */}
                {!pictue && !upload && !addingImages ? (
                    <label
                        className={`add-file-wrapper  ${isSmallScreen ? "mobile" : ""} ${isBalance.current ? '' : "disabled"} mt-2 mb-3`}
                        htmlFor="file-upload"
                        onClick={handleUploadClick}
                    >
                        <input
                            ref={fileInputRef}
                            type="file"
                            style={{ display: "none" }}
                            accept=".jpg, .gif, .png"
                            onChange={handleFileChange}
                            multiple
                        />

                        <img src={addFile} className={`add-file-image ${isSmallScreen ? "mobile" : ""}`} />
                        <div className={`${isBalance.current ? "" : "no-balance"} text-center add-files-text pt-4 ${isSmallScreen ? "small" : ""}`}>
                            {isBalance.current ? t.uploadScans.addFiles : t.uploadScans.noBalance}
                        </div>
                        <div className={`text-center instructions-text pt-4 ${isSmallScreen ? "small" : ""} wrap`}>
                            {isBalance.current ? t.uploadScans.instructions : ""}
                        </div>
                    </label>
                ) : (
                    <div className="add-file-wrapper block"></div>
                )}
                {loaded == true &&
                    <div className="slider padding">
                        <div className={`dot ${activeIndex === 0 ? 'active' : ''}`}></div>
                        <div className={`dot ${activeIndex === 1 ? 'active' : ''}`}></div>
                        <div className={`dot ${activeIndex === 2 ? 'active' : ''}`}></div>
                    </div>
                }

                {/* <Modal
                    show={isShowModal}
                    onHide={() => setIsShowModal(false)}
                    centered
                    className={`modal ${isSmallScreen ? 'buttom-modal' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
                >
                    <Modal.Body className='pdf-modal' >
                        <div className={`img-button mt-2`} onClick={handleUploadClick} >
                            {t.uploadScans.uploadScansFromGallery}
                            <img src={plus} className="img-btn-plus"></img>
                            <input
                                ref={fileInputRef}
                                type="file"
                                style={{ display: "none" }}
                                accept=".jpg, .gif, .png"
                                onChange={handleFileChange}
                                multiple
                            />
                        </div>
                    </Modal.Body>
                </Modal > */}


                {/* form and galerry */}
                <Form onSubmit={onSubmit}>
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Field name="files">
                                {({ input }) => (
                                    <>
                                        {(pictue === true || addingImagesPicture == true) && (
                                            <div>
                                                {(!files.length || addingImagesPicture == true) && (
                                                    <div className="camera-wrapper">
                                                        here camera component                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                            </Field>

                            <div className="row image-gallery">
                                {selectedImage && (
                                    pictue && addingImagesPicture == true ? (
                                        <></>
                                    ) : (
                                        <div>
                                            {/* next and previous buttons */}
                                            <div className={`button-wrapper  ${isSmallScreen ? 'mobile' : ''} ${currentLanguage == "en-US" ? "ltr" : ""}`}>
                                                {files.length > 1 && (
                                                    <div type="button" className="image-container-crop" onClick={setPreviousImage} >
                                                        <img src={imgArrowContainer} alt="Image 1" className="base-image" />
                                                        <img src={imgArrow} alt="Image 2" className="overlay-image" />
                                                    </div>
                                                )}
                                                {files.length > 1 && (
                                                    <div type="button" className="image-container-crop" onClick={setNextImage} >
                                                        <img src={imgArrowContainer} alt="Image 1" className="base-image" />
                                                        <img src={imgArrowRotate} alt="Image 2" className="overlay-image" />
                                                    </div>
                                                )}
                                            </div>

                                            {/* show frame image or cropper */}
                                            <div className="cropper-container">
                                                <Modal
                                                    fullscreen
                                                    show={isCropping}
                                                    // onHide={() => setIsCropping(false)}
                                                    centered
                                                    style={
                                                        isSmallScreen ?
                                                            {
                                                                margin: '0.1rem',
                                                                maxWidth: 'calc(100% - 0.2rem)',
                                                                maxHeight: 'calc(100% - 0.2rem)',
                                                                borderRadius: '0.5rem',
                                                            }
                                                            :

                                                            {
                                                                margin: currentLanguage == 'en-US' ? '1rem 1rem 1rem 17rem ' : '1rem 17rem 1rem 1rem',
                                                                maxWidth: 'calc(100% - 18rem)',
                                                                maxHeight: 'calc(100% - 2rem)',
                                                                borderRadius: '0.5rem',
                                                            }}
                                                >
                                                    <Modal.Header style={isSmallScreen ? {} : { padding: '1rem 19rem 1rem 1rem' }}>
                                                        <div className={`cropper-header ${currentLanguage == 'en-US' ? "ltr" : ''}`}>

                                                            {/* <div className="img-number">
                                <div type='button' onClick={() => { setIsCropping(false); activateXPress() }}><img src={xBtn}></img></div>
                                <div className="">{`${t.scanTypesSingle[scanType]} ${isCroppingButton ? selectedImage?.index + 1 : selectedImage?.tempIndex + 1} / ${isCroppingButton ? files?.length : filesToCrop?.length}`}</div>
                              </div> */}

                                                            <div className={`ok-cancel-wrapper  ${isSmallScreen ? 'mobile' : ''}`}>
                                                                <div className="button-text-wrapper">
                                                                    <div className={`trashcan plus ${isSmallScreen ? "mobile" : ""} ${selectedImage && imageQualityMessage === "" ? "" : "disabled"}`}
                                                                        type="button"
                                                                        onClick={cancelCroping}
                                                                    >
                                                                        <img src={x}></img>
                                                                    </div>
                                                                    <div className="crop-text">{t.uploadScans.cancel}</div>
                                                                </div>

                                                                <div className="button-text-wrapper">
                                                                    <div className={`trashcan plus ${isSmallScreen ? "mobile" : ""} ${selectedImage && imageQualityMessage === "" ? "" : "disabled"}`}
                                                                        type="button"
                                                                        onClick={handleCrop}
                                                                        disabled={!selectedImage || imageQualityMessage != ""}
                                                                    >
                                                                        <img src={v}></img>
                                                                    </div>
                                                                    <div className="crop-text"> {t.uploadScans.crop}</div>
                                                                </div>

                                                                <img src={grayLine}></img>

                                                                <div className="button-text-wrapper">
                                                                    <div
                                                                        className={`trashcan ${isSmallScreen ? "mobile" : ""} white-crop`}
                                                                        type="button"
                                                                        onClick={() => rotateImage(0.5)}
                                                                    >
                                                                        <img src={rotateAdd1}></img>
                                                                    </div>
                                                                    <div className="crop-text">{t.uploadScans.rotateAdd1}</div>

                                                                </div>

                                                                <div className="button-text-wrapper">
                                                                    <div
                                                                        className={`trashcan ${isSmallScreen ? "mobile" : ""} crop`}
                                                                        type="button"
                                                                        onClick={() => rotateImage(90)}
                                                                    >
                                                                        <img src={rotate90}></img>
                                                                    </div>
                                                                    <div className="crop-text">{t.uploadScans.rotate}</div>
                                                                </div>

                                                                <div className="button-text-wrapper">
                                                                    <div className={`trashcan ${isSmallScreen ? "mobile" : ""} white-crop`}
                                                                        type="button"
                                                                        onClick={() => rotateImage(-0.5)}
                                                                    >
                                                                        <img src={rotateLess1}></img>
                                                                    </div>
                                                                    <div className="crop-text">{t.uploadScans.rotateLess1}</div>
                                                                </div>

                                                                <div className="button-text-wrapper">
                                                                    <div className={`trashcan ${isSmallScreen ? "mobile" : ""} crop`}
                                                                        type="button"
                                                                        onClick={() => deleteImg()}
                                                                    >
                                                                        <img src={trashcanActive}></img>
                                                                    </div>
                                                                    <div className="crop-text">{t.uploadScans.delete}</div>
                                                                </div>

                                                            </div>

                                                            <div className={`crop-instructions ${isSmallScreen ? 'mobile' : ''}`}><img src={info}></img>{t.uploadScans.cropInstructions}</div>

                                                        </div>
                                                    </Modal.Header>
                                                    <Modal.Body style={isSmallScreen ? { padding: 0, margin: '0.5rem 0rem' } : { padding: '1rem 19rem 1rem 1rem' }}>
                                                        <div className={`cropper-wrapper image-container-cropper `}>

                                                            <div type="button" className={`image-container-crop big ${isSmallScreen ? 'mobile' : ''}`} onClick={setNextImageToCrop} >
                                                                <img src={imgArrowContainerCropRotate} alt="Image 1" className="base-image" />
                                                            </div>

                                                            <div type="button" className={`image-container-crop big right ${isSmallScreen ? 'mobile' : ''}`} onClick={setPreviousImageToCrop} >
                                                                <img src={imgArrowContainerCrop} alt="Image 1" className="base-image" />
                                                            </div>

                                                            <Cropper
                                                                ref={cropperRef}
                                                                src={selectedImage?.url}
                                                                className={"bg-transparent main-image"}
                                                                style={{ height: "100%", width: "100%", background: 'white' }}
                                                                // stencilProps={{ grid: true }}
                                                                rotateImage={true}
                                                            />

                                                        </div>
                                                        <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
                                                        {/* <canvas ref={canvasRef} style={{ display: '', width: "100%" }}></canvas> */}

                                                    </Modal.Body>

                                                </Modal>
                                                {!isCropping && (
                                                    <div className={`frame-image`}>
                                                        <img
                                                            className={`img-frame ${selectedImage?.rotation == 90 || selectedImage?.rotation == 270 ? 'rotate' : ''}`}
                                                            style={{ transform: `rotate(${selectedImage?.rotation}deg)` }}
                                                            src={selectedImage?.url}
                                                        ></img>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                )
                                }
                                {/* images gallery */}
                                {!isSmallScreen && !(pictue && addingImagesPicture == true) && (
                                    <div className="row image-list">
                                        <div className="image-list-inner">
                                            {files &&
                                                files.length > 1 &&
                                                files.map((image, index) => (
                                                    <div
                                                        type='button'
                                                        key={index}
                                                        onMouseOver={() => setIndexMouseHover(index)}
                                                        onMouseOut={() => setIndexMouseHover(-1)}
                                                        onClick={() => handleImageClick(image)}
                                                        className={`image-number-wrapper ${selectedImage?.url === image.url ? "selected" : ""}`}
                                                    >
                                                        <div className="gallery-image-wrapper" >
                                                            <img
                                                                src={image.url}
                                                                alt={`Image ${index}`}
                                                                className={`gallery-image ${isSmallScreen ? "mobile " : ""}`}
                                                                style={{ transform: `rotate(${image?.rotation}deg)` }}
                                                            />
                                                        </div>
                                                        <div className="number-in-image">
                                                            {indexMouseHover == index && (
                                                                <div className="num">
                                                                    {files.length} / {index + 1}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                        </form>
                    )}
                </Form>
                {/* confirm scan modal */}
                <div className="row mb-2">
                    <div
                        className={`container frame-a ${currentLanguage == "en-US" ? "ltr" : ""
                            } ${isSmallScreen ? "col-11 mobile" : "col-4"} `}
                    >
                        <div className={`frame-b ${isSmallScreen ? "mobile" : ""}`}>
                            <div
                                type="button"
                                onClick={disabledButton ? undefined : openAreYouSure}
                                disabled={disabledButton}
                                className={`btn-ok ${isSmallScreen ? "mobile" : ""} ${disabledButton ? "disabled" : ""}`}
                                ref={disabledButton || !isBalance.current ? undefined : buttonRef}
                            >
                                <div className={`btn-ok-text ${isSmallScreen ? "mobile" : ""} ${disabledButton ? "disabled" : ""}`}>
                                    {t.uploadScans.confirmScan}
                                </div>
                            </div>
                            <div
                                type="button"
                                className={`btn-cancel ${isSmallScreen ? "mobile" : ""}`}
                                onClick={cancel}
                            >
                                <div className={`btn-ok-text cancel ${isSmallScreen ? "mobile " : ""}`}>
                                    {t.uploadScans.cancel}
                                </div>
                            </div>
                        </div>
                        <div type="button" className={`back ${isSmallScreen ? "mobile" : ""}`}>
                            <div
                                className={`back-text ${isSmallScreen ? "mobile" : ""}`}
                                onClick={() => navigate(-1)}
                            >
                                {t.uploadScans.back}
                            </div>
                        </div>
                    </div>
                </div>


                {/* error modal */}
                <Modal
                    show={isShowErrorModal}
                    onHide={() => setIsShowErrorModal(false)}
                    centered
                    className={`modal ${isSmallScreen ? "" : `right-margin ${currentLanguage == "en-US" ? "ltr" : ""}`}`}
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="images-not-uploaded">
                            {isSome ? isSome : t.uploadScans.imagesNotUpload}
                        </div>
                        {isSmallScreen ? t.uploadScans.imagesNotUploadMessageMobile : t.uploadScans.imagesNotUploadMessage}
                    </Modal.Body>
                </Modal>


                {/* are you sure modal */}
                <Modal
                    show={isShowAreYouSure}
                    onHide={() => { setIsShowAreYouSure(false); activateCancelAreYouSure() }}
                    centered
                    className={`modal ${isSmallScreen ? "" : `right-margin ${currentLanguage == "en-US" ? "ltr" : ""}`}`}
                >
                    <Modal.Body>
                        <div
                            type="button"
                            className={isSmallScreen ? "are-you-sure mobile" : "are-you-sure"}
                        >
                            <div className={`back-text ${isSmallScreen ? "mobile" : ""}`}>
                                {t.uploadScans.areYouSure}
                            </div>
                        </div>
                        <div className={`frame-b ${isSmallScreen ? "mobile" : ""} ${currentLanguage == "en-US" ? "" : "reverse"}`}>
                            <div
                                type="button"
                                ref={button2Ref}
                                className={isSmallScreen ? "btn-ok mobile col-6 " : files.length > 0 ? "btn-ok col-6" : "btn-ok disabled col-6"}
                                onClick={onSubmit}
                            >
                                <div className="ok-button">
                                    <div>{t.uploadScans.ok}</div>
                                    {currentUser?.Role == 'user' &&
                                        <div className={`${currentLanguage == "en-US" ? "" : "direction-rtl reverse"}`} >{`${sumToPay} ${t.payment.scans}`}</div>
                                    }
                                </div>
                            </div>
                            <div
                                type="button"
                                className={isSmallScreen ? "btn-cancel mobile col-6" : "btn-cancel col-6"}
                                onClick={() => { setIsShowAreYouSure(false); activateCancelAreYouSure(); }}
                            >
                                <div className={isSmallScreen ? "btn-ok-text cancel mobile " : "btn-ok-text cancel"}>
                                    {t.uploadScans.cancel}
                                </div>
                            </div>
                        </div>
                        <div className="dont-show">
                            {t.uploadScans.dontShowAgain}
                            <input type="checkbox" onChange={(e) => dontShowAgain(e)}></input>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

            {/* spinner */}
            {disabledPage == true &&
                <ScanSlider images={files} scanType={scanType}></ScanSlider>
            }
        </div >
    );
};
export default UploadScan;
