
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { smallScreen, useLanguage } from '../../utils/constants';
import './header-style.scss'
import { useNavigate } from 'react-router-dom';
import { setSearchQuery } from '../scans/store/action';
import { changeLanguage, getCurrentUserById } from '../user/store/action';
import { useRef } from 'react';

const Header = ({ page, context, type }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser)
    const isSmallScreen = smallScreen();
    const t = useLanguage()
    const [greeting, setGreeting] = useState('');
    const isCurrentUserEffect = useRef(true);

    const handleSearchInput = (e) => {
        const query = e.target.value;
        dispatch(setSearchQuery(query));
    };

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    useEffect(() => {
        if (currentUserId && !currentUser && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId])

    useEffect(() => {
        const getGreeting = () => {
            const hour = new Date().getHours();
            if (hour >= 5 && hour < 12) {
                setGreeting(t.dashboard.goodMorning);
            } else if (hour >= 12 && hour < 18) {
                setGreeting(t.dashboard.goodAfternoon);
            } else {
                setGreeting(t.dashboard.goodEvening);
            }
        };

        if (page == 'dashboard') {
            getGreeting();
        }

    }, [t]);

    let url = null;
    switch (page) {
        case 'edit-profile':
        case 'contact':
        case 'payment':
        case 'upload-scan':
            url = -1;
            break;
        case 'scans-page':
            url = `/folders?type=${type}`
            break;
        case 'folders':
            url = '/dashboard';
    }

    const changeLanguageFunc = async (newLanguage) => {
        setCurrentLanguage(newLanguage);
        await dispatch(changeLanguage(newLanguage));
    }

    const navigateToHome = () => {
        localStorage.setItem('isFirstTime', JSON.stringify(true));
        navigate('/');
        window.location.reload();
    }

    return (

        <div className={`header ${currentLanguage == 'en-US' ? 'ltr' : ''}`}>

            {/* <div className={`greeting ${isSmallScreen && 'mobile'}`} type={url != null ? 'button' : ''} onClick={() => navigate(url)}>{headerContent}</div> */}
            <div className='greeting-wrapper' type={url != null ? 'button' : ''} onClick={() => navigate(url)}>
                {context ?
                    context?.map((item, index) => (
                        <div key={index} className={`greeting ${item === '>' ? 'blue' : ''} ${isSmallScreen && 'mobile'}`}>
                           {item}
                        </div>
                    ))
                    :
                    <div className={`greeting ${isSmallScreen ? 'mobile' : ''}`}>{greeting}, {currentUser?.customerName}!!</div>
                }
            </div>

            <div className='home-page-link-mobile'>
            {isSmallScreen && (page == 'login' || page == 'register') && <div onClick={navigateToHome} className='home-page-link'>{`לעמוד הבית > `}</div>}
            </div>

            <div className={isSmallScreen ? '' : `search-language ${currentLanguage == 'en-US' ? 'ltr' : ''}`}>
                {!isSmallScreen &&
                    <div className='link-to-home'>
                        {(page == 'login' || page == 'register') && <div onClick={navigateToHome} className='home-page-link'>{`לעמוד הבית > `}</div>}
                        <div className='row lenguage-wrapper'>
                            <div type={language == 'en-US' ? '' : 'button'} className={`col-5 lenguage ${language == 'en-US' ? 'lenguage-active' : ''}`} onClick={() => changeLanguageFunc('en-US')}>EN</div>
                            <div type={language == 'he' ? '' : 'button'} className={`col-7 lenguage ${language == 'he' ? 'lenguage-active' : ''}`} onClick={() => changeLanguageFunc('he')}>עברית</div>
                        </div>
                    </div>
                }

                {/* todo add search button */}
                {/* {(page === 'folders' || page === 'scans-page') &&
                    (<div className={`search-wrapper`}>
                        <div className='search-container'>
                            <img src={search} className={`search-icon ${currentLanguage == 'en-US' ? 'ml-2' : ''}`}></img>
                            <input className={`search-text ${language == 'en-US' ? 'text-left' : ''}`}
                                   placeholder={t.dashboard.search}
                                   value={searchQuery}
                                   onChange={handleSearchInput}
                            ></input>
                        </div>
                    </div>)
                } */}
            </div>

        </div >

    )
};

export default Header;
