
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { emailPatternConst, passwordPatternConst, smallScreen, useLanguage, validateEmail } from '../../../utils/constants';
import './login-style.scss'
import { getCurrentUserById, getUserLogin, isEmailExist, updateUserPassword } from '../store/action';
import Header from '../../header/header';
import logo from '../../../utils/assets/logo.svg'
import LoginWays from '../login-ways/login-ways';
import hidePassword from '../../../utils/assets/hide-password.svg'
import showPasswordImg from '../../../utils/assets/show-password.svg'
import SendEmailResetPassword from '../forgot-password/forgot-password';
import ReactGA from 'react-ga4';
import GoogleSignIn from '../login-ways/google-login-mobile';

export default function LoginForm() {

  const dispatch = useDispatch()
  const t = useLanguage();
  const isSmallScreen = smallScreen()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [isResetPassword, setIsResetPassword] = useState(false)
  const [sendResetPassword, setSendResetPassword] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const resetPassword = location?.state?.password || null;
  const hiddenEmail = location?.state?.hiddenEmail || '';
  const email = location?.state?.email || '';
  const passwordPattern = passwordPatternConst();
  const emailPattern = emailPatternConst();
  const language = useSelector((store) => store.userReducer.language);
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [os, setOs] = useState('');
  const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
  const currentUser = useSelector((store) => store.userReducer.currentUser);
  const isCurrentUserEffect = useRef(true)
  const addUser = location?.state?.addUser || null;
  const isDashboard = localStorage.getItem('isDashboard') === null ? false : JSON.parse(localStorage.getItem('isDashboard'));

  const {
    handleSubmit,
    register,
    watch,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    setCurrentLanguage(language)
  }, [language])

  useEffect(() => {
    if (currentUserId && isCurrentUserEffect.current) {
      dispatch(getCurrentUserById(currentUserId));
      if (addUser == null && currentUser) {
        navigate('/dashboard')
      }
      isCurrentUserEffect.current = false;
    }
  }, [currentUserId])

  useEffect(() => {
    if (currentUserId && currentUser && Object.keys(currentUser).length > 0
      && ['/login', '/register'].includes(window.location.pathname)
      && !addUser) {        
        navigate('/dashboard');
    }
  }, [currentUserId])


  async function onSubmit(values) {
    try {

      if (!resetPassword) {
        const userEmail = watch('email')?.trim()?.toLowerCase();
        console.log('userEmail', userEmail);
        return new Promise(async (resolve) => {
          if (userEmail) {
            const res = await dispatch(isEmailExist({ email: userEmail }))
            console.log('res of isEmailExist', res);
            if (res) {
              if (res.message === 'no password') {
                console.log('❌❌');
                setIsResetPassword(true);
                setError('password', {
                  type: 'manual',
                  message: t.user.noPasswordExist,
                });
                // setSendResetPassword(true)
              }
              else if (res.message === 'no user') {
                setError('email', {
                  type: 'manual',
                  message: 'אימייל לא קיים במערכת',
                });
              }
              else {
                const user = await dispatch(getUserLogin({ email: userEmail, password: watch('password')?.trim() }));
                setCurrentLanguage(language);
                console.log('user😊', user);
                if (user && user.message == 'success') {
                  navigate("/dashboard");
                  if (currentUser.Role == 'user')
                    ReactGA.event({
                      category: 'Button',
                      action: 'לחיצה על כפתור לוגין ידני',
                      label: 'עמוד כניסה',
                      value: 1
                    });
                }
                else {
                  setError('password', {
                    type: 'manual',
                    message: t.user.wrongPassword,
                  });
                }
              }
            }
            else
              navigate('/register', { state: { email: userEmail, password: watch('password') } })
          }
        })
      }

      else {

        if (watch('passwordFromEmail')?.trim() === resetPassword) {
          if (watch('newPassword')?.trim() === watch('validatePassword')?.trim()) {

            const res = await dispatch(updateUserPassword({ email: email, password: watch('newPassword')?.trim() }))
            console.log('👍👍', res);
            if (res) {
              console.log('yes');
              navigate('/dashboard')
            }
            else {
              console.log('no', res.message);
            }
          }
          else {
            setError('validatePassword', {
              type: 'manual',
              message: 'סיסמאות לא תואמות',
            });
          }
        }
        else {
          setError('newPassword', {
            type: 'manual',
            message: 'סיסמה שגויה',
          });
        }
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const a = getOperatingSystem();
    console.log('a', a);
    setOs(a)
  })
  const getOperatingSystem = () => {
    const platform = navigator.platform.toLowerCase();

    if (platform.includes('win')) {
      return 'Windows';
    } else if (platform.includes('mac')) {
      return 'Mac';
    } else if (platform.includes('iphone')) {
      return 'iphone';
    } else if (platform.includes('linux')) {
      return 'Linux';
    } else {
      return platform;
    }
  };

  return (
    <>
      <div className={isSmallScreen ? '' : 'container'}>
        <div className={isSmallScreen ? '' : 'col'}>

          {isSmallScreen && <div type='button' className='login-header' onClick={currentUserId != '' ? () => navigate('/dashboard') : undefined}>
            <img src={logo} className='login-header-logo'></img>
          </div>}

          <form id='login-form' onSubmit={handleSubmit(onSubmit)} className={`container-login ${isSmallScreen ? ' mobile' : ''}`}>

            <div className={`inputs-login `}>

              <div className={currentLanguage == 'en-US' ? 'pr-0' : 'pl-0'}>
                <Header page={'login'} context={[t.user.login]}></Header>
              </div>
              {resetPassword ?
                <div className="row">
                  {/* the code from email */}
                  <div className={`col-12 form-group mt-3`}>
                    <div htmlFor="passwordFromEmail">
                      <div className={isSmallScreen ? '' : 'one-row'}>{t.user.emailVerification} {hiddenEmail}</div>
                    </div>
                    <div
                      type='text'
                      className={`card-input password  pr-1 pl-1 ${errors.passwordFromEmail ? 'is-invalid' : ''}`}
                    >
                      <input
                        id="passwordFromEmail"
                        type={'text'}
                        className='password-input '
                        placeholder={t.user.whatIsTheCode}
                        {...register('passwordFromEmail', {
                          required: t.forms.requireField,
                          validate: (value) => {
                            if (value !== resetPassword) {
                              console.log('value !== resetPassword', value !== resetPassword);

                              return 'קוד אימות לא תקין';
                            }
                          },
                        })}
                      />
                    </div>

                    <div className="invalid-feedback">{errors.passwordFromEmail && errors.passwordFromEmail.message}</div>
                  </div>

                  {/* the new password */}
                  <div className={`col form-group  ${isSmallScreen ? 'col-12' : 'mt-3'}`}>
                    <label htmlFor="newPassword" className='label-row'>
                      <div>{t.user.newPassword}</div><div className='very-small-text'>{t.user.passwordPattern}</div><div style={{ color: 'red' }}>*</div>
                    </label>

                    <div
                      type='text'
                      className={`card-input password  pr-1 pl-1 ${errors.newPassword ? 'is-invalid' : ''}`}
                    >
                      <input
                        id="newPassword"
                        type={showPassword ? 'text' : "password"}
                        className='password-input'
                        {...register('newPassword', {
                          required: t.forms.requireField,
                          pattern: {
                            value: passwordPattern,
                            message: t.forms.invalidPassword,
                          },
                          minLength: { value: 8, message: t.forms.requireFieldCharacters },
                        })}
                      />
                      <img className='password-img ml-3' type='button' src={showPassword ? hidePassword : showPasswordImg} onClick={() => setShowPassword(!showPassword)} />
                    </div>

                    <div className="invalid-feedback">{errors.newPassword && errors.newPassword.message}</div>
                  </div>

                  {/* the confirm password */}
                  <div className={`col form-group  ${isSmallScreen ? 'col-12' : 'mt-3'}`}>
                    <div htmlFor="validatePassword">
                      {t.user.confirmPassword}
                    </div>
                    <div
                      type='text'
                      className={`card-input password  pr-1 pl-1 ${errors.validatePassword ? 'is-invalid' : ''}`}
                    >
                      <input
                        id="validatePassword"
                        className='password-input'
                        type={showConfirmPassword ? 'text' : "password"}
                        {...register('validatePassword', {
                          required: t.forms.requireField,
                          validate: (value) => {
                            if (value.trim() !== watch('newPassword')?.trim()) {
                              return 'הסיסמאות לא תואמות';
                            }
                          },
                          minLength: { value: 8, message: t.forms.requireFieldCharacters },

                        })} />
                      <img className='password-img ml-3' type='button' src={showConfirmPassword ? hidePassword : showPasswordImg} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                    </div>

                    <div className="invalid-feedback">{errors.validatePassword && errors.validatePassword.message}</div>
                  </div>

                </div>
                :
                <div className="row container-email-password">

                  {/* user email */}
                  <div className={`col form-group  ${isSmallScreen ? 'col-12' : 'mt-3'}`}>
                    <div htmlFor="email">
                      {t.user.email}
                    </div>
                    <input
                      id="email"
                      type='text'
                      className={`card-input pr-1 pl-1 ${errors.email ? 'is-invalid' : ''}`}
                      {...register('email', {
                        required: t.forms.requireField,
                        validate: validateEmail,
                      })}
                      defaultValue={email}
                    />
                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                  </div>

                  {/* user password */}
                  <div className={`col form-group  ${isSmallScreen ? 'col-12' : 'mt-3'}`}>
                    <div className="container-password">
                      <div htmlFor="password">
                        {t.user.password}
                      </div>
                      <div type='button' className="forgot-password" onClick={() => { setForgotPassword(true); }}>
                        <div className="link">{isResetPassword ? t.user.resetPassword : t.user.userForgotPassword}</div>
                      </div>
                    </div>
                    <div
                      type='text'
                      className={`card-input password pr-1 pl-1 ${errors.password ? 'is-invalid' : ''}`}
                    >
                      <input
                        id="password"
                        className='password-input'
                        type={showPassword ? 'text' : "password"}
                        {...register('password', {
                          required: t.forms.requireField,
                          minLength: { value: 6, message: t.forms.requireFieldCharacters },
                        })}
                      />
                      <img className='password-img ml-3' type='button' src={showPassword ? hidePassword : showPasswordImg} onClick={() => setShowPassword(!showPassword)} />

                    </div>

                    <div className="invalid-feedback">{errors.password && errors.password.message}</div>
                  </div>

                </div>
              }

              {(forgotPassword == true || sendResetPassword == true) &&
                <SendEmailResetPassword email={watch('email')?.trim()}></SendEmailResetPassword>
              }

              <div className={`form-group text-center ${isSmallScreen ? '' : 'mt-5 mb-0'} `}>
                <button
                  type="submit"
                  className="submit-button"
                // disabled={isSubmitting}
                >
                  {t.user.connect}
                </button>
              </div>

            </div>

            <div className='container-line'>
              <div className='line'></div>{t.user.or}<div className='line'></div>
            </div>

            <div>
              <LoginWays></LoginWays>
              {/* {isSmallScreen && os == 'Linux' ? <GoogleSignIn></GoogleSignIn> : <LoginWays></LoginWays>} */}
            </div>

            <div className='text-center'>{t.user.notHaveAccountYet} <Link to="/register" className='text-decoration-none'>{t.user.signup}</Link></div>

          </form>
        </div>
      </div>
    </>
  )
}
