import { useDispatch, useSelector } from "react-redux";
import Header from "../../header/header";
import { useEffect, useRef, useState } from "react";
import { getAllPayments, setPaymentsWithUserName } from "../../payments/store/action";
import DataManagement from "../data-management/data-management";
import { getUsersById } from "../../user/store/action";
import './payment-management.scss'

export default function PaymentManagement() {
    const dispatch = useDispatch();
    const paymentsListStore = useSelector((store) => store.paymentReducer.payments)
    const paymentsWithUserNameStore = useSelector((store) => store.paymentReducer.paymentsWithUserName)
    const isEffectData = useRef(false);
    const isEffect = useRef(false);
    const [paymentsListUpdate, setPaymentsListUpdate] = useState(paymentsWithUserNameStore)

    useEffect(() => {
        const fetchData = async () => {
            isEffectData.current = true;
            await dispatch(getAllPayments());
        };

        if (isEffectData.current == false && paymentsListStore?.length == 0)
            fetchData();
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            isEffect.current = true;
            let usersIdList = []
            paymentsListStore.forEach(item => {
                usersIdList.push({ id: item.userId })
            });
            const usersList = await dispatch(getUsersById(usersIdList))
            let paymentListWithNames = [...paymentsListStore]
            usersList.map((item, index) => paymentListWithNames[index] = {
                ...paymentListWithNames[index],
                userName: item?.customerName
            })
            setPaymentsListUpdate(paymentListWithNames)
            dispatch(setPaymentsWithUserName(paymentListWithNames))
        }
        if (paymentsListStore && paymentsListStore?.length > 0 && paymentsWithUserNameStore?.length == 0 && isEffect.current == false) { fetchUsers() }
    }, [paymentsListStore])


    const headerList = [{ label: 'שם משתמש' }, { label: 'דרך תשלום' }, { label: 'תאריך רכישה' }, { label: 'סכום' }, { label: 'סטטוס' },]

    return (
        <div className="">
            <Header page={'user-management'} context={['ניהול תשלומים']}></Header>

            {paymentsListUpdate?.length > 0 &&
                <DataManagement
                    dataType={'payment'}
                    dataList={paymentsListUpdate}
                    header={`${paymentsListStore?.length || 0} תשלומים`}
                    headerList={headerList}
                ></DataManagement>}
        </div>
    )
}