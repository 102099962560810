import React, { useEffect, useRef, useState } from 'react';
import './scan-style.scss'
import { deleteScan, scansIdsToUpload, updateScan } from '../store/action';
import { useDispatch, useSelector } from 'react-redux';
import errowUp from '../../../utils/assets/errow-up.svg'
import trash from '../../../utils/assets/trash.svg'
import errowDown from '../../../utils/assets/errow-down.svg'
import ViewImage from '../../../utils/file-azure/view-image';
import './scan-style.scss'
import { BASE_URL, smallScreen, useLanguage } from '../../../utils/constants';
import Modal from 'react-bootstrap/Modal';
import { getCurrentUserById, updateUserDontShowAgain } from '../../user/store/action';
import editScanImg from '../../../utils/assets/edit-scan.svg';
import saveScanEdit from '../../../utils/assets/save-scan-name.svg';

const Scan = ({ scan, checkedScan, scansLength, disabledPage }) => {
    const scansIdsToUploadStore = useSelector((store) => store.scanReducer.scansIdsToUpload)
    const isSmallScreen = smallScreen();
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [img, setImg] = useState(false);
    const [editScan, setEditScan] = useState(false);
    const dispatch = useDispatch()
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const t = useLanguage();
    const [isMouseOver, setIsMouseOver] = useState(null);
    const scanIdToDelete = JSON.parse(sessionStorage.getItem('scanIdToDelete'));
    const [isPressing, setIsPressing] = useState(false);
    const timeoutRef = useRef(null);
    const buttonRef = useRef(null);
    const [isShowAreYouSure, setIsShowAreYouSure] = useState(false);
    const [isCheckedScan, setIsCheckedScan] = useState(checkedScan);
    const [localImageUrl, setLocalImageUrl] = useState('');
    const URL = BASE_URL;
    const isCurrentUserEffect = useRef(false);
    const scanNameNumbers = scan.name.replace(/\D/g, '');
    const scanNameString = scan.name.replace(/[^a-zA-Z\u0590-\u05FF]/g, '') + ' ';
    const [newScanName, setNewScanName] = useState(scanNameNumbers);

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    useEffect(() => {
        if (currentUserId && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId, currentUser])


    const handleImgClick = async () => {
        try {
            setIsAccordionOpen(prevState => !prevState);
            // const currentImageUrl = `https://stamimages.blob.core.windows.net/scans-images/scan${scan._id}.jpg`;
            // const imageId = scan.images[0]._id;
            // console.log('imageId', imageId);
            // if (imageId) {

            //     axios
            //         // .get(currentImageUrl, { responseType: 'arraybuffer' })
            //         .get(`${BASE_URL}/api/file/download/${imageId}`, { responseType: 'arraybuffer' })
            //         .then((response) => {
            //             console.log('response😉😉😉', response);
            //             const url = (window.webkitURL || URL).createObjectURL(new Blob([response.data]));
            //             console.log('url ↪️', url);
            //             setLocalImageUrl(url)
            //             // dispatch(setImageUrl(currentImageUrl))
            //             dispatch(setImageUrl(url))
            //         })
            //         .catch((error) => console.log('error in view image😭😭'))
            // }
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleCheckboxChange = async (event, id) => {
        const checkbox = event.target;
        if (id) {
            const idToAddOrRemove = id;
            if (checkbox.checked) {
                const arr = [...scansIdsToUploadStore, idToAddOrRemove];
                await dispatch(scansIdsToUpload(arr));
                if (scansLength == 1)
                    setIsCheckedScan(true);
            }
            else {
                const arr = scansIdsToUploadStore?.filter((id) => id !== idToAddOrRemove)
                await dispatch(scansIdsToUpload(arr));
                if (scansLength == 1)
                    setIsCheckedScan(false);
            }
        }
    }

    const deleteScanFunc = async () => {
        try {
            disabledPage(true)
            setIsShowAreYouSure(false)
            const res = await dispatch(deleteScan(scanIdToDelete, currentUserId));
            disabledPage(false)
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleTouchStart = () => {
        timeoutRef.current = setTimeout(() => {
            setIsPressing(true);
            sessionStorage.setItem('scanIdToDelete', JSON.stringify(scan._id));
            clearTimeout(timeoutRef.current);
        }, 100);
    };

    const handleTouchEnd = () => {
        clearTimeout(timeoutRef.current);
    };

    const activateShowModal = () => {
        console.log('currentUser', currentUser);

        if (currentUser?.dontShowAgainScan == true) {
            deleteScanFunc()
        }
        else {
            setIsShowAreYouSure(true)
        }

    }

    useEffect(() => {
        if (isShowAreYouSure) {

            buttonRef.current.focus();

            const handleKeyPress = (event) => {
                if (event.key === 'Enter') {
                    console.log('dhj');
                    deleteScanFunc()
                }
            };

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }

    }, [isShowAreYouSure]);

    const dontShowAgain = (event) => {
        const checkbox = event.target;

        if (checkbox.checked) {
            dispatch(updateUserDontShowAgain({ userId: currentUserId, parameter: 'dontShowAgainScan' }))
        }
    }

    const updateScanFunc = async () => {
        console.log('newScanName', newScanName);
        const nameToSave = scanNameString + newScanName;
        setEditScan(false)
        let scanToSave = { ...scan };
        scanToSave.name = nameToSave;
        console.log('scanToSave', scanToSave);
        await dispatch(updateScan(scanToSave))
    }

    return (
        <>
            <div className={`card-custom-scan ${isSmallScreen ? 'cardMobile' : 'card'} ${currentLanguage == 'en-US' ? 'ltr' : ''}`}
                style={{ backgroundColor: isAccordionOpen ? "#ebedfc" : "white" }}
                data-toggle="collapse"
                data-target={`#collapse-${scan._id}`}
                aria-expanded="false"
                aria-controls={`collapse-${scan._id}`}
                alt="Errow Up"
                onClick={() => { handleImgClick(); }}
                onMouseOver={() => {
                    setIsMouseOver(scan._id);
                    sessionStorage.setItem('scanIdToDelete', JSON.stringify(scan._id));
                }}
                onMouseOut={() => setIsMouseOver(null)}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchCancel={handleTouchEnd}
            >
                <div className={isSmallScreen ? 'ContainerProjectMobile' : 'ContainerProject'} id={`heading-${scan._id}`} type='button'>

                    <div className={`name-input`}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <input
                                type="checkbox"
                                className='scan-checkbox'
                                onChange={(e) => { handleCheckboxChange(e, scan._id); e.stopPropagation(); }}
                                checked={isCheckedScan != null ? isCheckedScan : scansIdsToUploadStore.includes(scan._id)}
                            />
                        </div>
                        {scanNameString}
                        {editScan ?
                            <div class="input-container" onClick={(e) => e.stopPropagation()}>
                                <input
                                    placeholder={scanNameNumbers}
                                    className="edit-scan-input"
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                        setNewScanName(e.target.value)
                                    }}
                                />
                                <img class="icon" onClick={() => { updateScanFunc() }} src={saveScanEdit} alt="Save Icon" />
                            </div>
                            : scanNameNumbers
                        }
                    </div>

                    <div className='trash-wrapper'>

                        {((!isSmallScreen && isMouseOver == scan._id) || (isSmallScreen && isPressing)) && (
                            <div type='button' onClick={(e) => { e.stopPropagation(); setEditScan(true) }}>
                                <img src={editScanImg}></img>
                            </div>
                        )}
                        {((!isSmallScreen && isMouseOver == scan._id) || (isSmallScreen && isPressing)) && (
                            <div type='button' onClick={(e) => { e.stopPropagation(); activateShowModal() }}>
                                <img src={trash} />
                            </div>
                        )}

                        <img
                            src={isAccordionOpen ? errowUp : errowDown}
                            className={isSmallScreen ? "float-start ErrowUpMobile clickAccordion" : "float-start ErrowUp clickAccordion"}
                        />
                    </div>
                </div>
            </div >
            {isAccordionOpen &&
                <div id={`collapse-${scan._id}`} className="collapse image-collapse">
                    <div className={`file ${scan.type == 'tefillin' ? '' : 'mezuza'} ${currentLanguage == 'en-US' ? 'ltr' : ''}`} onClick={() => setImg(true)} data-toggle="modal" data-target="#imageModal">
                        <div className="image-container">
                            <ViewImage scanId={scan._id} imageId={scan.images[0]._id} coords={{}} showSmallImage={true} />
                        </div>
                    </div>
                    {/* view image modal */}
                    <Modal
                        show={img}
                        onHide={() => setImg(false)}
                        centered
                        dialogClassName={`${(scan.type == 'tefillin') && isSmallScreen ? 'my-modal-tefillin-mobile' : `${scan.type == 'tefillin' ? 'my-modal-tefillin' : `modal ${isSmallScreen ? '' : 'right-margin'} ${currentLanguage == 'en-US' ? 'ltr' : ''}`} } `}`}
                    >
                        {/* <ImageCanvas width={'100%'} height={'100%'} coords={{}} /> */}
                        <ViewImage scanId={scan._id} imageId={scan.images[0]._id} coords={{}} scanType={scan.type} showSmallImage={false}></ViewImage>
                    </Modal>


                </div>
            }

            {/* are you sure modal */}
            <Modal
                show={isShowAreYouSure}
                onHide={() => setIsShowAreYouSure(false)}
                centered
                className={`modal ${isSmallScreen ? '' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
            >
                <Modal.Body >
                    <div type='button' className={isSmallScreen ? 'are-you-sure mobile' : 'are-you-sure'} >
                        <div className={`back-text ${isSmallScreen ? 'mobile' : ''}`} >{t.uploadScans.areYouSure}</div>
                    </div>
                    <div className={`frame-b ${isSmallScreen ? 'mobile' : ''} ${currentLanguage == 'en-US' ? '' : 'reverse'}`}>
                        <div type="button" className={`btn-ok ${isSmallScreen ? 'mobile' : ''} col-6 `} ref={buttonRef} onClick={deleteScanFunc}>
                            <div className='ok-button'>
                                <div>{t.uploadScans.ok}</div>
                            </div>
                        </div>
                        <div type="button" className={isSmallScreen ? 'btn-cancel mobile col-6' : 'btn-cancel col-6'} onClick={() => setIsShowAreYouSure(false)}>
                            <div className={isSmallScreen ? 'btn-ok-text cancel mobile ' : 'btn-ok-text cancel'} >{t.uploadScans.cancel}</div>
                        </div>
                    </div>

                    <div className="dont-show">
                        {t.uploadScans.dontShowAgain}
                        <input type="checkbox" onChange={(e) => dontShowAgain(e)}></input>
                    </div>

                </Modal.Body>
            </Modal>

        </>
    )
}
export default Scan;