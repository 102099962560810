import React, { useEffect, useRef, useState } from 'react';
import plus from '../../utils/assets/mini-plus.svg'
import mobilePlus from '../../utils/assets/mobile-plus.svg'
import arrow1 from '../../utils/assets/arrow1.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NewScan from '../scans/new-scan/new-scan';
import { useDispatch, useSelector } from 'react-redux';
import { smallScreen } from '../../utils/constants';
import { useLanguage } from '../../utils/constants';
import { fetchProjectsByType, setProjectsOfType } from '../projects/store/action';
import Header from '../header/header';
import { getCurrentUserById, updateUserPhone } from '../user/store/action';
import './dashboard-style.scss'
import SendOpenningEmail from '../../utils/send-email/send-email';
import ReactGA from 'react-ga4';
import Modal from 'react-bootstrap/Modal';

export default function Dashboard() {
    const isSmallScreen = smallScreen();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const isNewUserStorage = sessionStorage.getItem('isNewUser');    
    const projectsSortByType = useSelector((store) => store.projectReducer.projectsSortByType);
    const typesList = ['mezuza', 'tefillin', 'torah', 'ketuvim'];
    const isCurrentUserEffect = useRef(true)
    const isProjectsEffect = useRef(true)
    const t = useLanguage();
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const showPhoneModalStorage=sessionStorage.getItem('showPhoneModal')
    const [isShowPhoneModal, setIsShowPhoneModal] = useState(showPhoneModalStorage);
    const [phoneNumber, setPhoneNumber] = useState('');
    
    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    const setProjectOfTypeFunc = async (type) => {
        await dispatch(setProjectsOfType(projectsSortByType[type]));
    }

    useEffect(() => {
        if (currentUserId && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId, currentUser])

    useEffect(() => {
        if (currentUserId && isProjectsEffect.current&&!projectsSortByType.length) {
            console.log('dashboard');
            
            fetchProject();
            async function fetchProject() {
                isProjectsEffect.current = false;
                await dispatch(fetchProjectsByType(currentUserId))
            }
        }
    }, [currentUserId])

    const activatePress = () => {
        if (currentUser.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'לחיצה על רכישת קרדיטים',
                label: 'עמוד בית',
                value: 1
            });
    }    

    const submitForm = async() =>{
        console.log('submitForm');
        if(phoneNumber.length==10){
            setIsShowPhoneModal(false);
            sessionStorage.removeItem('showPhoneModal');
            const user = await dispatch(updateUserPhone({userId:currentUserId,phoneNumber:phoneNumber}))
            
            console.log('save user',user);
        }
        
    }
    console.log(phoneNumber);
    
    return (<>

        <div className={`container page-container ${isSmallScreen ? 'mobile' : ''}`}>
            {/* header */}
            <div className="row dashboard-row header-wrapper">
                <Header page={'dashboard'}></Header>
            </div>
            {/* data */}
            <div className={`row dashboard-row credit-data ${isSmallScreen ? 'mobile' : ''}`}>
                <div className="col-4">
                    <div className={`last-use ${isSmallScreen ? 'mobile' : ''}`}>
                        <div className={`last-use-text ${isSmallScreen ? 'mobile' : ''} `}>{t.dashboard.leftToUse}</div>
                        <div className={`credit-num ${isSmallScreen ? 'mobile' : ''}`}>{currentUser?.balance || 0}</div>
                        <div className={`credit ${isSmallScreen ? 'mobile' : ''}`}>{t.dashboard.scans}</div>
                    </div>
                </div>

                <div className="col-4" type='button' onClick={(e) => { e.stopPropagation(); navigate('/payment-page'); activatePress() }}>
                    <div className={`buy-credit ${isSmallScreen ? 'mobile' : ''}`}>
                        <div className={`new-scan-text dashboard black ${isSmallScreen ? 'mobile' : ''}`}>{t.dashboard.buyCredits}</div>
                        <img src={isSmallScreen ? mobilePlus : plus} className={`${isSmallScreen ? 'plus-mobile' : 'new-scan-btn'}`} alt='new scan button'></img>
                    </div>
                </div>

                <div className="col-4">
                    <NewScan comp="HomePage" ></NewScan>
                </div>

            </div>

            {/* scan types */}
            <div className={`row dashboard-row scan-types-wrapper`}>
                {typesList.map((type, index) => (
                    <div className="col-6">
                        <Link onClick={() => setProjectOfTypeFunc(type)} to={(index === 2 || index === 3) ? '/dashboard' : `/folders?type=${type}`} key={index} className={`link-style ${index === 2 || index === 3 ? 'disabled' : ''}`} >
                            <div className='type-text'>{t.scanTypes[type]}</div>
                            <div className={`type-container-row ${isSmallScreen ? 'mobile' : ''}`} >
                                <div className={`num-folder ${index == 0 || index == 1 ? '' : 'comming-soon '} ${isSmallScreen ? 'mobile' : ''}`}>
                                    {index == 0 || index == 1 ? (projectsSortByType[type]?.length || 0) + ' ' + (t.dashboard.folders) : t.dashboard.comingSoon}
                                </div>
                                {!isSmallScreen && <img src={arrow1} alt='arrow'></img>}
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>

        {isNewUserStorage === currentUser?._id &&
            <SendOpenningEmail id={currentUser?._id} userName={currentUser.customerName} email={currentUser.email} />
        }
        <Modal
            show={isShowPhoneModal}
            onHide={() => {setIsShowPhoneModal(false);sessionStorage.removeItem('showPhoneModal');}}
            centered
            className={`modal ${isSmallScreen ? '' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
        >
            <Modal.Header closeButton>
            </Modal.Header>

            <Modal.Body className='pdf-modal' >
                <div className={isSmallScreen?"":"modal-warning-text"}>היי, שמנו לב שלא הזנת  בפרופיל מספר טלפון</div>
                <div className={isSmallScreen?"":"modal-warning-text"}>חשוב לנו שתכניס כאן את המספר כדי שנוכל להיות בקשר</div>
                <form>
                    <input placeholder='הכנס כאן מספר טלפון' className='add-phone' 
                    onChange={(e)=>{
                        if(e.target.value.length<=10)
                            setPhoneNumber(e.target.value.replace(/\D/g, ''))
                    }
                    }
                    value={phoneNumber}
                    ></input>
                    <div type='button' className={`add-phone-submit ${phoneNumber.length==10?'':'disabled'}`} onClick={submitForm}>אישור</div>
                </form>
            </Modal.Body>
        </Modal>


    </>)
}
