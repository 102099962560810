// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  align-items: center;
  border: 1px solid var(--primary-colors-light, #EBEDFC);
  gap: 1rem;
  padding: 1rem 0;
}

.table-row.no-border {
  border: unset;
}

.data-manage-header {
  color: var(--primary-colors-primary, #536DF6);
  font-weight: 400;
  padding: 1.5rem 0;
}

.table-col {
  flex-grow: 1;
  text-align: center;
  font-size: 0.95rem;
}

.table-col.chooseLine {
  padding-right: 2rem;
  flex-grow: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/user-management/user-management.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,sDAAA;EACA,SAAA;EACA,eAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAGA;EACI,6CAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;;AAGA;EAEI,YAAA;EACA,kBAAA;EACA,kBAAA;AADJ;;AAIA;EACI,mBAAA;EACA,YAAA;AADJ","sourcesContent":[".table-row {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    width: 100%;\n    height: auto;\n    align-items: center;\n    border: 1px solid var(--primary-colors-light, #EBEDFC);\n    gap: 1rem;\n    padding: 1rem 0;\n}\n\n.table-row.no-border {\n    border: unset;\n\n}\n\n.data-manage-header {\n    color: var(--primary-colors-primary, #536DF6);\n    font-weight: 400;\n    padding: 1.5rem 0;\n}\n\n.table-col {\n    // padding-right: 0.5rem;\n    flex-grow: 1;\n    text-align: center;\n    font-size: 0.95rem;\n}\n\n.table-col.chooseLine {\n    padding-right: 2rem;\n    flex-grow: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
