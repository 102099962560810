import { useDispatch, useSelector } from "react-redux"
import { getAllUsers } from "../../user/store/action";
import { useEffect, useState } from "react";
import './admin-dashboard.scss'
import { getAllPayments } from "../../payments/store/action";

export default function AdminDashboard() {
    const dispatch = useDispatch();
    const [usersList, setUsersList] = useState([])
    const usersListStore = useSelector((store) => store.userReducer.users)
    const [paymentsList, setPaymentsList] = useState([])
    const paymentsListStore = useSelector((store) => store.paymentReducer?.payments)

    const getPaymentsList = async () => {
        const paymentsList = await dispatch(getAllPayments())
        const last10payments = paymentsList.slice(-10);
        setPaymentsList(last10payments)
    }

    useEffect(() => {
        getPaymentsList()
    }, [paymentsList])

    useEffect(() => {
        setPaymentsList(paymentsListStore)
    }, [paymentsListStore])


    const getUsersList = async () => {
        const usersList = await dispatch(getAllUsers())
        const last10users = usersList.slice(-8);
        setUsersList(last10users)
    }

    useEffect(() => {
        getUsersList()
    }, [])


    useEffect(() => {
        setUsersList(usersListStore)
    }, [usersListStore])


    return (
        <div className="admin-dashboard-wrapper">
            <div className="admin-dashboard-row">
                <div className="admin-dashboard-col ">
                    <div>משתמשים חדשים /אחרונים/ פעילים</div>
                        <div className={`table-row no-border`}>
                            <div className="bold-text">שם המשתמש</div>
                            <div className="bold-text">Role</div>
                            <div className="bold-text">אימייל</div>
                            <div className="bold-text">תאריך הצטרפות</div>
                            <div className="bold-text">יתרה</div>
                        </div>

                        {usersList.map((user, index) => {
                            const dateOnly = user.createdAt.split('T')[0];
                            return (

                                <div key={index} className={`table-row no-border`}>
                                    <div className="table-col col">{user.customerName}</div>
                                    <div className="table-col col">{user.Role}</div>
                                    <div className="table-col col">{user.email}</div>
                                    <div className="table-col col">{dateOnly}</div>
                                    <div className="table-col col">{user.balance}</div>
                                </div>
                            )
                        }
                        )}
                </div>
                <div className="admin-dashboard-col">השוואת סכומים</div>
            </div>
            <div className="admin-dashboard-row">
                <div className="admin-dashboard-col ">
                    <div>תשלומים אחרונים</div>
                    <div className={`table-row no-border`}>
                        <div className="bold-text ">דרך תשלום</div>
                        <div className="bold-text ">תאריך רכישה</div>
                        <div className="bold-text ">סטטוס</div>
                        <div className="bold-text ">סכום</div>
                    </div>

                    {paymentsList && paymentsList.map((payment, index) => {
                        const dateOnly = payment.createdAt.split('T')[0];
                        return (
                            <div key={index} className={`table-row no-border`}>
                                <div className="table-col col">{payment.paymentOption}</div>
                                <div className="table-col col">{dateOnly}</div>
                                <div className="table-col col">{payment.status}</div>
                                <div className="table-col col">{payment.amount}</div>
                            </div>
                        )
                    }
                    )}
                </div>
                <div className="admin-dashboard-col">הודעות מערכת</div>
            </div>
        </div>
    )
}