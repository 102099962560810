
import React, { useRef, useEffect, useState } from "react"
import './new-scan-style.scss'
import plus from '../../../utils/assets/plus-new-scan1.svg'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { addProject, fetchProjectsByType } from "../../projects/store/action";
import { smallScreen, useLanguage } from "../../../utils/constants";
import { getCurrentUserById, updateUserDontShowAgain, updateUserScanType } from "../../user/store/action";
import Modal from 'react-bootstrap/Modal';
import ReactGA from 'react-ga4';
import Spinner from "../../../utils/spinner/spinner"
import xBtn from '../../../utils/assets/x-btn.svg'
export default function NewScan({ comp, typeFolder, folderName }) {
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const projectsSortByType = useSelector((store) => store.projectReducer.projectsSortByType)
    const dispatch = useDispatch();
    const isSmallScreen = smallScreen();
    const t = useLanguage();
    const [isInputFilled, setIsInputFilled] = useState(false);
    const [folder, setFolder] = useState('');
    const [addFolder, setAddFolder] = useState(false);
    const [addToFolder, setAddToFolder] = useState(folderName ? true : false);
    const [selectedType, setSelectedType] = useState(typeFolder);
    const navigate = useNavigate();
    const scanType = !selectedType ? currentUser?.typeDefultFolder : selectedType;
    const isProjectsEffect = useRef(true);
    const isCurrentUserEffect = useRef(true);
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const [disabledCreateReport, setDisabledCreateReport] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowWarningModal, setIsShowWarningModal] = useState(false);

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    useEffect(() => {
        if (currentUserId && !currentUser && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId])

    useEffect(() => {
        if (currentUserId && isProjectsEffect.current && !projectsSortByType?.length) {
            fetchProjectData();
            async function fetchProjectData() {
                isProjectsEffect.current = false;
                await dispatch(fetchProjectsByType(currentUserId));
            }
        }
    }, [currentUserId])

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setFolder(inputValue);
        setIsInputFilled(inputValue !== '');
    };

    const handleCancel = () => {
        setFolder('');
        setIsInputFilled(false);
        setAddFolder(false);
        setAddToFolder(false);
        setIsShowModal(false)
    };

    function createFolder() {
        try {
            setDisabledCreateReport(true);
            const newFolder = {
                "userId": currentUser._id,
                "name": folder,
                "type": scanType,
            }
            dispatch(addProject(newFolder))
                .then(res => {
                    if (res) {
                        console.log('res');
                        uploadScanToFolder(res, newFolder.name);
                        if (currentUser.Role == 'user')
                            ReactGA.event({
                                category: 'Button',
                                action: 'יצירת תיקייה חדשה הצליח',
                                label: 'עמוד סריקה חדשה',
                                value: 1
                            });
                    }
                    else {
                        setDisabledCreateReport(false);
                        if (currentUser.Role == 'user')
                            ReactGA.event({
                                category: 'Button',
                                action: 'יצירת תיקייה חדשה נכשל',
                                label: 'עמוד סריקה חדשה',
                                value: 1
                            });
                    }
                })
                .catch(error => {
                    console.log(error);
                    setDisabledCreateReport(false);
                });
        }
        catch (error) {
            console.log(error);
            setDisabledCreateReport(false);
        }
    }

    const uploadScanToFolder = (id, name) => {
        sessionStorage.setItem('currentProjectId', JSON.stringify(id));
        sessionStorage.setItem('currentProjectName', JSON.stringify(name));
        sessionStorage.setItem('scanType', JSON.stringify(scanType));
        if (currentUser.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'הוספת סריקה לתיקייה קיימת',
                label: 'עמוד סריקה חדשה',
                value: 1
            });
        navigate('/upload-scan');
        setDisabledCreateReport(false);
        dispatch(updateUserScanType({ userId: currentUserId, scanType: scanType }))
    };

    const activateNewScanPress = () => {
        setIsShowModal(true);
        const bool = currentUser.dontShowAgainWarning == true ? false : true;
        setIsShowWarningModal(bool)
        if (currentUser.Role == 'user')
            ReactGA.event({
                category: 'Button',
                action: 'לחיצה על סריקה חדשה',
                label: 'עמוד סריקה חדשה',
                value: 1
            });
    }

    const dontShowAgain = (event) => {
        const checkbox = event.target;

        if (checkbox.checked) {
            dispatch(updateUserDontShowAgain({ userId: currentUserId, parameter: 'dontShowAgainWarning' }))
        }
    }

    return <>
        {comp !== 'scans-page' &&
            <div className={`new-scan-wrapper ${comp == 'HomePage' ? 'dashboard' : ''} ${isSmallScreen ? 'mobile' : ''}`} type='button' onClick={() => { activateNewScanPress() }}>
                <div className={`new-scan-container  ${isSmallScreen ? comp == 'HomePage' ? 'dashboard mobile' : 'mobile' : ''}`} >
                    <div className={`new-scan-text ${comp == 'HomePage' ? 'dashboard' : ''} ${isSmallScreen ? 'mobile' : ''}`}>{t.dashboard.newScan}</div>
                    <img src={plus} ></img>
                </div>
            </div>
        }

        <Modal
            show={isShowModal}
            onHide={() => setIsShowModal(false)}
            centered
            className={`modal ${isSmallScreen ? 'buttom-modal' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
        >
            <Modal.Body className='pdf-modal' >
                {(!addFolder && !addToFolder) &&
                    <>
                        {comp && ['HomePage', 'ProjectPage', 'scans-page'].includes(comp) && (
                            <>
                                <div className={`bold-text-right ${currentLanguage == 'en-US' ? 'ltr' : ''}`}>{t.newScan.choosescanType}</div>
                                <div className={"continer-button"} >
                                    <div className={isSmallScreen ? `button-cancel-mobile ${selectedType == 'mezuza' || scanType == 'mezuza' ? 'active' : ''} ` : ` button-cancel ${selectedType == 'mezuza' || scanType == 'mezuza' ? 'active' : ''}`}
                                        onClick={() => { setSelectedType('mezuza') }} >
                                        <div className='nice-text'>{t.scanTypesSingle.mezuza}</div>
                                    </div>

                                    <div className={isSmallScreen ? `button-cancel-mobile ${selectedType == 'tefillin' || scanType == 'tefillin' ? 'active' : ''}` : `button-cancel ${selectedType == 'tefillin' || scanType == 'tefillin' ? 'active' : ''}`}
                                        onClick={() => { setSelectedType('tefillin') }} >
                                        <div className="nice-text" >{t.scanTypesSingle.tefillin}</div>
                                    </div>
                                </div>

                            </>
                        )}
                        <div className={`bold-text-right ${currentLanguage == 'en-US' ? 'ltr' : ''}`}>{t.newScan.locateScan}</div>
                        <div className={"continer-button"} >
                            <div className={isSmallScreen ? `button-folder-mobile  ` : ` button-folder  ${projectsSortByType[scanType]?.length > 0 ? 'locate' : 'disabled'} `}
                                onClick={() => scanType && projectsSortByType[scanType]?.length > 0 ? setAddToFolder(true) : null} >
                                <div className={`nice-text ${projectsSortByType[scanType]?.length > 0 ? '' : 'disabled'}  `} >{t.project.AddingToExistingFolder}</div>
                            </div>
                            <div className={isSmallScreen ? `button-folder-mobile ` : ` button-folder locate`}
                                onClick={() => scanType ? setAddFolder(true) : null}                                        >
                                <div className={`nice-text `} >{t.project.newFolder}</div>
                            </div>
                        </div>

                    </>
                }
                {addFolder &&
                    <>
                        <div className={isSmallScreen ? `card-custom-new-scan card-scan-mobile ` : "card-custom-new-scan card-scan"}>
                            <input
                                placeholder={t.project.enterFolderName}
                                value={folder}
                                onChange={handleInputChange}
                                className={`text-input ${currentLanguage == 'en-US' ? 'ltr' : ''}`}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter")
                                        if (isInputFilled) { createFolder(); handleCancel(); }
                                }}
                            />
                        </div>
                        <div className={"continer-button"} >
                            <div className={isSmallScreen ? `button-cancel-mobile` : "button-cancel"} onClick={() => { handleCancel(); }}>
                                <div className="txt-cancle"
                                >{t.project.cancel}</div>
                            </div>

                            <div className={isSmallScreen ? `button-folder-mobile ${isInputFilled && 'active'} ` : ` button-folder ${isInputFilled && 'active'}`}
                                onClick={() => { if (isInputFilled) { createFolder(); handleCancel(); } }}
                            >
                                <div className={`txt-folder ${isInputFilled && 'active'}`} disabled="false">{t.project.createFolder}</div>
                            </div>
                        </div>
                    </>
                }
                {addToFolder &&
                    <>
                        <div type='button' className={'nice-text'} onClick={() => { setAddToFolder(false) }}>{' < ' + t.newScan.chooseExistingFolder}</div>
                        {projectsSortByType[scanType].length > 0 ?
                            <>
                                {projectsSortByType[scanType].map((project, index) => (
                                    <div key={index}
                                        type='button'
                                        id="closeModalButton"
                                        className={isSmallScreen ? 'card-custom-new-scan card-scan-mobile folders-exists' : `card-custom-new-scan folders-exists `}
                                        onClick={() => { uploadScanToFolder(project._id, project.name); handleCancel(); }}
                                        disabled="false"
                                    >
                                        <div className={`txt-folders-exists ${currentLanguage == 'en-US' ? 'ltr' : ''}`}>{project.name} </div>
                                    </div>
                                )
                                )}
                            </>
                            :
                            <div className="txt-folders-exists" >
                                <div>{t.newScan.noAvailableFolders}</div>
                            </div>
                        }
                    </>
                }
            </Modal.Body>
        </Modal >

        {/* warning modal */}
        <Modal
            show={isShowWarningModal}
            onHide={() => setIsShowWarningModal(false)}
            centered
            className={`modal ${isSmallScreen ? "" : `right-margin ${currentLanguage == "en-US" ? "ltr" : ""}`}`}
        >
            <Modal.Body className="modal-warning-wrapper">
                <div className="modal-warning">
                    <div className="img-number">
                        <div type='button' onClick={() => setIsShowWarningModal(false)}><img src={xBtn}></img></div>
                    </div>

                    <div className="modal-warning-header">!משתמש יקר שים לב</div>
                    <div className="modal-warning-text">כדי להימנע משגיאות לא נכונות בדו"ח ולצמצמן</div>
                    <div className={`modal-warning-row  ${isSmallScreen ? 'mobile' : ''}`}>
                        <div className="modal-warning-bold"> :הקפד מאוד לצלם</div>
                        <div className="modal-warning-text"> ,על רקע לבן, צילום ישר </div>
                    </div>
                    <div className="modal-warning-text">.ולא תחת אור ישיר</div>

                    <div className="modal-warning-bold padding-top">במידת הצורך חתוך את התמונה במסך הייעודי  </div>
                    <div className="modal-warning-bold"> כך שישאר רק הטקסט</div>

                    <div className={`modal-warning-button-wrapper`}>
                        <button className="modal-warning-button" onClick={() => setIsShowWarningModal(false)}>הבנתי</button>

                        <div className="dont-show">
                            {t.uploadScans.dontShowAgain}
                            <input type="checkbox" onChange={(e) => dontShowAgain(e)}></input>
                        </div>
                    </div>
                </div>

            </Modal.Body>

        </Modal>

        {/* spinner on disabled */}
        {disabledCreateReport == true && (
            <Spinner value={2} speed={10}></Spinner>
        )}
    </>
}







