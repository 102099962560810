// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.spinner-track {
  background: #ddd;
  height: 8px;
  width: 300px;
  margin: 10px 16rem 10px 0;
  border-radius: 4px;
}
@media screen and (max-width: 720px) {
  .spinner-track {
    margin: 10px 0;
  }
}

.spinner-track.ltr {
  margin: 10px 0 10px 16rem;
}
@media screen and (max-width: 720px) {
  .spinner-track.ltr {
    margin: 10px 0;
  }
}

.spinner-progress {
  background: var(--2, #536DF6);
  height: 100%;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/utils/spinner/spinner-styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AACJ;;AAEA;EACI,gBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;AACJ;AAAI;EANJ;IAOQ,cAAA;EAGN;AACF;;AAAA;EACI,yBAAA;AAGJ;AAFI;EAFJ;IAGQ,cAAA;EAKN;AACF;;AAFA;EACI,6BAAA;EACA,YAAA;EACA,kBAAA;AAKJ","sourcesContent":[".spinner-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 9999;\n}\n\n.spinner-track {\n    background: #ddd;\n    height: 8px;\n    width: 300px; // Adjust the width as needed\n    margin: 10px 16rem 10px 0;\n    border-radius: 4px; // Optional: add rounded corners\n    @media screen and (max-width:720px) {\n        margin: 10px 0;\n    }\n}\n\n.spinner-track.ltr {\n    margin: 10px 0 10px 16rem;\n    @media screen and (max-width:720px) {\n        margin: 10px 0;\n    }\n}\n\n.spinner-progress {\n    background: var(--2, #536DF6);\n    height: 100%;\n    border-radius: 4px; // Optional: add rounded corners\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
