
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { emailPatternConst, getOperatingSystem, passwordPatternConst, smallScreen, useLanguage, validateEmail, validateName } from '../../../utils/constants';
import { createUser, isEmailExist, setCurrentUser } from '../store/action';
import './register-style.scss';
import Header from '../../header/header';
import logo from '../../../utils/assets/logo.svg'
import LoginWays from '../login-ways/login-ways';
import hidePassword from '../../../utils/assets/hide-password.svg';
import showPasswordImg from '../../../utils/assets/show-password.svg';
import ReactGA from 'react-ga4';


export default function Register() {
  const dispatch = useDispatch()
  const t = useLanguage();
  const isSmallScreen = smallScreen();
  const emailPattern = emailPatternConst();
  const passwordPattern = passwordPatternConst();
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const language = useSelector((store) => store.userReducer.language);
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [showInitialPopup, setShowInitialPopup] = useState(false);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isSubmitting },
  } = useForm()

  useEffect(() => {
    setCurrentLanguage(language)
  }, [language])

  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || '';
  const password = location.state?.password || '';
  console.log('errors', errors);
  const [disabledButton, setDisabledButton]= useState(false);

  function onSubmit(values) {
    console.log('onsubmit ↪️↪️↪️');
    
    setDisabledButton(true)
    return new Promise(async (resolve) => {
      const userEmail = watch('email')?.trim()?.toLowerCase();
      const res = await dispatch(isEmailExist({ email: userEmail }))
      console.log('res', res);
      if (res.message === 'no user') {
        values.email = userEmail;
        values.platform= isSmallScreen ? 'mobile':'desktop'+' '+ navigator.platform;
        const user = await dispatch(createUser(values))
        if (user) {
          sessionStorage.setItem('isNewUser', user._id)
          navigate("/dashboard");
        }
      }
      else if (res.message === 'true' || res.message === 'no password') {
        alert(`משתמש קיים. הנך מועבר לעמוד כניסה`)
        navigate("/login", { state: { email: userEmail } });
      }
    })
  }
  console.log(watch('customerName'));
  


  return (
    <div className={isSmallScreen ? '' : 'container'}>
      <div className={isSmallScreen ? '' : 'col'}>

        {isSmallScreen && <div className='login-header'>
          <img src={logo} className='login-header-logo' alt='logo'></img>
        </div>}

        <div className={isSmallScreen ? "scrollable" : ''}>
          <form onSubmit={handleSubmit(onSubmit)} className={`container-register ${isSmallScreen ? 'mobile' : ''}`}>
            <div className="inputs-login">
              <div className={currentLanguage == 'en-US' ? 'pr-0' : 'pl-0'}>
                <Header page={'register'} context={[t.user.letsStart]}></Header>
              </div>

              {/* customerName */}
              <div className={isSmallScreen ? "container-email-password-mobile" : "container-email-password row"}>
                <div className="form-group">
                  <label htmlFor="customerName" className='label-row'>
                    {t.user.customerName} <div style={{ color: 'red' }}>*</div>
                  </label>
                  <input
                    id="customerName"
                    className={`${isSmallScreen ? 'col-12' : 'col pr-1 pl-1'} card-input ${errors.customerName ? 'is-invalid' : ''}`}
                    {...register('customerName', {
                      required: t.forms.requireField,
                      minLength: { value: 2, message: t.forms.invalidCustomerName },
                      validate: validateName,
                    })}
                  />
                  <div className="invalid-feedback">{errors.customerName && errors.customerName.message}</div>
                </div>
              </div>

              {/* email and phone */}
              <div className={isSmallScreen ? "container-email-password-mobile" : "container-email-password row"}>
                {/* user email */}
                <div className="form-group">
                  <label htmlFor="email" className='label-row'>
                    {t.user.email} <div style={{ color: 'red' }}>*</div>
                  </label>
                  <input
                    id="email"
                    className={`${isSmallScreen ? 'col-12' : 'col pr-1 pl-1'} card-input ${errors.email ? 'is-invalid' : ''}`}
                    {...register('email', {
                      required: t.forms.requireField,
                      validate: validateEmail,
                    })}
                    defaultValue={email}
                  />
                  <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                </div>

                {/* user phone number */}
                <div className="form-group">
                  <label htmlFor="phone" className="label-row">
                    <div>{t.user.phone}</div><div className='very-small-text'>{t.user.phoneMessage}</div><div style={{ color: 'red' }}>*</div>
                  </label>
                  <input
                    id="phone"
                    className={`${isSmallScreen ? 'col-12' : 'col pr-1 pl-1'} card-input ${errors.phone ? 'is-invalid' : ''}`}
                    {...register('phone', {
                      required: t.forms.requireField,
                      pattern: {
                        value: /^\s*\d{10,}\s*$/,
                        message: t.forms.invalidPhone,
                      },
                    })}
                  />
                  <div className="invalid-feedback">{errors.phone && errors.phone.message}</div>
                </div>

              </div>

              {/* password and confirm password*/}
              <div className={isSmallScreen ? "container-email-password-mobile" : "container-email-password row"}>
                {/* password */}
                <div className="form-group">
                  <label htmlFor="password" className='label-row'>
                    <div>{t.user.password}</div><div className='very-small-text'>{t.user.passwordPattern}</div><div style={{ color: 'red' }}>*</div>
                  </label>
                  <div
                    type='text'
                    className={`card-input password   ${currentLanguage == 'en-US' ? 'pr-3' : 'pl-3'} ${errors.password ? 'is-invalid' : ''}`}
                  >
                    <input
                      id="password"
                      type={showPassword ? 'text' : "password"}
                      className={`${isSmallScreen ? 'col-12' : 'col pr-1 pl-1'} card-input password ${errors.password ? 'is-invalid' : ''}`}
                      {...register('password', {
                        required: t.forms.requireField,
                        pattern: {
                          value: passwordPattern,
                          message: t.forms.invalidPassword,
                        },
                        minLength: { value: 8, message: t.forms.requireFieldCharacters },
                      })}
                      defaultValue={password}
                    />
                    <img className='password-img ml-3' type='button' src={showPassword ? hidePassword : showPasswordImg} onClick={() => setShowPassword(!showPassword)} alt={showPassword ? 'hide password' : 'show password img'} />
                  </div>
                  <div className="invalid-feedback">{errors.password && errors.password.message}</div>
                </div>

                {/* confirm pasword */}
                <div className="form-group">
                  <label htmlFor="passwordConfirm" className='label-row'>
                    {t.user.confirmPassword}<div style={{ color: 'red' }}>*</div>
                  </label>
                  <div
                    type='text'
                    className={`card-input password  ${currentLanguage == 'en-US' ? 'pr-3' : 'pl-3'} ${errors.passwordConfirm ? 'is-invalid' : ''}`}
                  >
                    <input
                      id="passwordConfirm"
                      type={showConfirmPassword ? 'text' : "password"}
                      className={`${isSmallScreen ? 'col-12' : 'col pr-1 pl-1'} card-input ${errors.passwordConfirm ? 'is-invalid' : ''}`}
                      {...register('passwordConfirm', {
                        required: t.forms.requireField,
                        validate: (value) => {
                          if (value !== watch('password')) {
                            return 'הסיסמאות לא תואמות';
                          }
                          return true;
                        },
                        minLength: { value: 8, message: t.forms.requireFieldCharacters },
                      })}
                      defaultValue={password}
                    />
                    <img className='password-img ml-3' type='button' src={showConfirmPassword ? hidePassword : showPasswordImg} onClick={() => setShowConfirmPassword(!showConfirmPassword)} alt={showConfirmPassword ? 'hide password' : 'show password img'} />
                  </div>
                  <div className="invalid-feedback">{errors.passwordConfirm && errors.passwordConfirm.message}</div>

                </div>

              </div>

              {/* register button */}
              <div className={`form-group text-center mt-3`}>
                <button
                  type="submit"
                  className="submit-button"
                  disabled={disabledButton}
                >
                  {t.user.signup}
                </button>
              </div>

            </div>

            <div className='container-line'>
              <div className='line'></div>{t.user.or}<div className='line'></div>
            </div>

            <div>
              <LoginWays></LoginWays>
            </div>

            <div className='text-center pt-2'>{t.user.alreadyHaveAccount} <Link to="/login" className='text-decoration-none'>{t.user.connect}</Link></div>

          </form >
        </div>
      </div>
    </div>

  )
}
