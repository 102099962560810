
import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { getCurrentUserById } from '../store/action';

export default function SendEmailResetPassword({ email ,userName}) {
  console.log('SendEmailResetPassword',email);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hash = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYTAxZjkwNWQxMTMxYjQ0NDFhZTdmOCIsImlhdCI6MTY3MTQzODIyNCwiZXhwIjoxNjcxNTI0NjI0fQ._8v30tb_0giuh4nXWC0sEhRtD4afurzrhnXI-x89vtg'
  const isEffect = useRef(false);

  function generateRandomCode() {
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const numberChars = "0123456789";

    // Ensure at least one character from each category
    const randomUppercase = uppercaseChars.charAt(Math.floor(Math.random() * uppercaseChars.length));
    const randomLowercase = lowercaseChars.charAt(Math.floor(Math.random() * lowercaseChars.length));
    const randomNumber = numberChars.charAt(Math.floor(Math.random() * numberChars.length));

    // Generate the remaining characters
    const remainingChars = uppercaseChars + lowercaseChars + numberChars;
    let code = randomUppercase + randomLowercase + randomNumber;

    for (let i = 4; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * remainingChars.length);
      code += remainingChars.charAt(randomIndex);
    }

    // Shuffle the code to make it random
    code = shuffleString(code);
    console.log('code',code);
    
    return code;
  }

  function shuffleString(string) {
    const array = string.split('');
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array.join('');
  }

  const generatedCode = generateRandomCode();

  async function sendEmail() {
    try {

      if (email) {
        console.log('email',email);
        const form = document.getElementById('contact-form');

        emailjs.sendForm('service_qswsu7r', 'template_pc6k7ol', form, 'RG7No6OmeqiYqalp_')
          .then(async (result) => {
            console.log('email send',result);
            const hiddenEmail = hideEmail(email)
            navigate('/login', { state: { email: email, hiddenEmail: hiddenEmail, password: generatedCode } })
          }, (error) => {
            //todo show the error to user
            console.log('error send email',error.text);
          });
      }
      else {
        navigate('/register', { state: { email: email } })
      }
    }
    catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {
    if (isEffect.current == false) {
      isEffect.current = true;
      sendEmail();
    }
  }, [email]);

  function hideEmail(email) {
    const atIndex = email.indexOf('@');
    if (atIndex !== -1) {
      const username = email?.slice(0, atIndex);
      const hiddenUsername = username?.slice(0, 3) + '*'.repeat(username.length - 3);
      const domain = email?.slice(atIndex);
      const hiddenEmail = hiddenUsername + domain;
      return hiddenEmail;
    }
    return email;
  }

  return (
    <>
      <form id='contact-form' name="contact-form" onSubmit={sendEmail}>
        <input type="hidden" name="from_name" value={'StamScanner'} />
        <textarea hidden="hidden" name="to_email" id='toEmail' value={email} ></textarea>
        <textarea name='message' id='message' value={generatedCode} hidden='hidden'></textarea>
        <textarea name='user_name' id='user_name' value={userName} hidden='hidden'></textarea>
        <p name="token" value={hash} hidden='hidden'></p>
      </form>
    </>
  );
}
