export const GET_IFRAME='GET_IFRAME'
export const GET_TOKEN='GET_TOKEN'
export const REFUND_TRANSACTION='REFUND_TRANSACTION'
export const CREATE_TRANSACTION_WITH_TOKEN ='CREATE_TRANSACTION_WITH_TOKEN'
export const CREATE_TRANSACTION_PAYPAL ='CREATE_TRANSACTION_PAYPAL'
export const CREATE_DOCUMENT ='CREATE_DOCUMENT'
export const GET_DOCUMENT ='GET_DOCUMENT'
export const GET_PAYPAL_LINK ='GET_PAYPAL_LINK'
export const GET_PAYMENTS ='GET_PAYMENTS'
export const GET_LAST_PAYMENTS ='GET_LAST_PAYMENTS'
export const SET_PAYMENTS_WITH_USER_NAME = 'SET_PAYMENTS_WITH_USER_NAME'
export const SET_CUPON_USES_WITH_USER_NAME ='SET_CUPON_USES_WITH_USER_NAME'
