import { useDispatch, useSelector } from "react-redux"
import { getAllUsers, getLastUsers, getLastUsersNoDispatch, getUsersById, getUsersNamesById } from "../../user/store/action";
import { useEffect, useRef, useState } from "react";
import './admin-dashboard.scss'
import { get10LastPayments, getAllPayments } from "../../payments/store/action";
import Header from "../../header/header";
import { fetchAllReportLogs, fetchReportLogsNumbers } from "../../scans/store/action";
import DataManagement from "../data-management/data-management";
import { useNavigate } from "react-router-dom";

export default function AdminDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const last10usersListStore = useSelector(store => store.userReducer.last10usersList)
    const usersListLengthStore = useSelector(store => store.userReducer.usersListLength)
    const reportLogsNumbersStore = useSelector(store => store.scanReducer.reportLogsNumbers)
    const lastPaymentsStore = useSelector((store) => store.paymentReducer?.lastPayments)
    const [usersList, setUsersList] = useState(last10usersListStore)
    const [usersLength, setUsersLength] = useState(usersListLengthStore || 0)
    const [scansList, setScansList] = useState([])
    const [scansLength, setScansLength] = useState(reportLogsNumbersStore.listLength || 0)
    const [allScansLength, setAllScansLength] = useState(reportLogsNumbersStore.scanListLength || 0)
    const [goodScansLength, setGoodScansLength] = useState(reportLogsNumbersStore.status200 || 0)
    const [failedScansLength, setFailedScansLength] = useState(reportLogsNumbersStore.status400 || 0)
    const [accessFailedScansLength, setAccessFailedScansLength] = useState(reportLogsNumbersStore.status500 || 0)
    const [moreThen15Errors, setMoreThen15Errors] = useState(reportLogsNumbersStore.moreThen15Errors || 0)
    const usersListStore = useSelector((store) => store.userReducer.users)
    const [paymentsList, setPaymentsList] = useState(lastPaymentsStore)
    const isEffect = useRef(false);
    const isEffectPayments = useRef(false);
    const isUsersEffect = useRef(false);

    useEffect(() => {
        const getScansList = async () => {
            const reportLogsData = await dispatch(fetchReportLogsNumbers())
            if(reportLogsData){
                console.log('reportLogsData', reportLogsData);
                setScansLength(reportLogsData?.listLength)
                setScansList(reportLogsData?.last10scans)
                setAllScansLength(reportLogsData?.scanListLength)
                setGoodScansLength(reportLogsData?.status200)
                setFailedScansLength(reportLogsData?.status400)
                setAccessFailedScansLength(reportLogsData?.status500)
                setMoreThen15Errors(reportLogsData?.moreThen15Errors)
            }
        }

        if (isEffect.current == false) {
            isEffect.current = true;
            getScansList()
        }
    }, [])

    useEffect(() => {
        const getPaymentsList = async () => {
            const response = await dispatch(get10LastPayments())
            const last10payments = response;
            let usersIdList = []
            last10payments.forEach(item => {
                usersIdList.push({ id: item.userId })
            });
            const usersList = await dispatch(getUsersNamesById(usersIdList))
            let paymentListWithNames = [...last10payments]
            usersList.map((item, index) => paymentListWithNames[index] = {
                ...paymentListWithNames[index],
                userName: item?.customerName
            })
            
            setPaymentsList(paymentListWithNames)
        }

        if (isEffectPayments.current == false && lastPaymentsStore?.length == 0) {
            isEffectPayments.current = true;
            getPaymentsList()
        }
    }, [])


    useEffect(() => {
        const getUsersList = async () => {
            const response = await dispatch(getLastUsersNoDispatch())//todo
            setUsersLength(response.usersListLength)
            setUsersList(response.last10usersList)
        }

        if (isUsersEffect.current == false && (last10usersListStore?.length == 0 || usersListLengthStore == 0)) {
            isUsersEffect.current = true;
            getUsersList()
        }
    }, [])

    useEffect(() => {
        setUsersList(usersListStore)
    }, [usersListStore])

    useEffect(()=>{
       sessionStorage.setItem('storageNameToSearch','')
    },[])
    const dashboardHeadersList = [
        { title: 'סך הקריאות', value: scansLength, color: 'gold' },
        { title: 'סך המשתמשים ', value: usersLength, color: 'silver' },
        { title: 'סך הסריקות', value: allScansLength, color: 'blue' },
        { title: 'סריקות שהצליחו', value: goodScansLength, color: 'green' },
        { title: 'סריקות שנכשלו', value: failedScansLength, color: 'orange' },
        { title: 'הגישה נכשלה', value: accessFailedScansLength, color: 'yellow' },
        { title: 'מעל 15 שגיאות', value: moreThen15Errors, color: 'red' },
    ]

    const lastDataList = [
        { dataType: 'scan', list: scansList, title: 'סריקות אחרונות', link: '/scan-management', headerList: [{ label: 'שם משתמש' }, { label: 'מס שגיאות' }, { label: 'לינק לסריקה' }, { label: 'לינק לדוח' }, { label: 'דוח מלא' }, { label: 'תאריך הסריקה' }, { label: 'סטטוס' }] },
        { dataType: 'payment', list: paymentsList, title: 'תשלומים אחרונים', link: '/payment-management', headerList: [{ label: 'שם משתמש' }, { label: 'דרך תשלום' }, { label: 'תאריך רכישה' }, { label: 'סכום' }, { label: 'סטטוס' }, { label: 'מחיקה' }] },
        { dataType: 'user', list: usersList, title: 'משתמשים אחרונים', link: '/user-management', headerList: [{ label: 'שם משתמש' }, { label: 'סוג משתמש' }, { label: 'אימייל' }, { label: 'טלפון' }, { label: 'תאריך הצטרפות' }, { label: 'יתרה' }] },
    ]

    return (
        <div className="admin-dashboard-wrapper">
            <Header page={'admin-dashboard'} context={['דאשבורד']}></Header>
            <div className="dashboard-headers-list">
                {dashboardHeadersList.map((obj) => {
                    return (
                        <div className={`dashboard-headers-data col ${obj.color}`}>
                            <div className="title">{obj.title}</div>
                            <div className="big-number">{obj.value}</div>
                        </div>
                    )
                })}
            </div>

            <div className="last-data-container">
                {lastDataList.map((item) => {
                    return (
                        <div className="last-data-wrapper">
                            <div className="row1">
                                <div>{item.title}</div>
                                <div type='button' onClick={() => navigate(item.link)} className="list-link">{`לרשימה המלאה >`}</div>
                            </div>
                            <DataManagement
                                dataType={item.dataType}
                                dataList={item.list}
                                headerList={item.headerList}
                            ></DataManagement>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}