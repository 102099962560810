// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../node_modules/bootstrap/dist/css/bootstrap.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  margin: 0;
  padding: 0;
}

* {
  font-family: "Rubik", sans-serif;
}

.blue-button {
  border-radius: 3.75rem;
  background: var(--primary-colors-primary, #536DF6);
  border: none;
  color: #EBEDFC;
  padding: 0.5rem 0.8rem;
  width: 8rem;
  box-shadow: 0px 8px 25px 0px rgba(70, 78, 251, 0.03);
  margin: 0.2rem;
}

.white-button {
  border-radius: 3.75rem;
  margin: 0.2rem;
  border: 2px solid var(--primary-colors-primary, #536DF6);
  color: #536DF6;
  background: var(--primary-colors-white, #FFF);
  padding: 0.5rem 0.8rem;
  width: 8rem;
  box-shadow: 0px 8px 25px 0px rgba(70, 78, 251, 0.03);
}

.row-with-gap {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-style {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.75rem;
  border: 2px solid var(--primary-colors-light, #EBEDFC);
  background: var(--primary-colors-white, #FFF);
  box-shadow: 0px 8px 25px 0px rgba(70, 78, 251, 0.03);
}

.big-input {
  width: 21rem;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
}

.small-input {
  width: 10rem;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,UAAA;AAAF;;AAGA;EACE,gCAAA;AAAF;;AAGA;EACE,sBAAA;EACA,kDAAA;EACA,YAAA;EACA,cAAA;EACA,sBAAA;EACA,WAAA;EACA,oDAAA;EACA,cAAA;AAAF;;AAGA;EACE,sBAAA;EACA,cAAA;EACA,wDAAA;EACA,cAAA;EACA,6CAAA;EACA,sBAAA;EACA,WAAA;EACA,oDAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,sDAAA;EACA,6CAAA;EACA,oDAAA;AAAF;;AAGA;EACE,YAAA;EACA,iBAAA;EACA,qBAAA;AAAF;;AAGA;EACE,YAAA;EACA,iBAAA;EACA,qBAAA;AAAF","sourcesContent":["@import '~bootstrap/dist/css/bootstrap.min.css';\n\n.App {\n  margin: 0;\n  padding: 0;\n}\n\n* {\n  font-family: 'Rubik', sans-serif;\n}\n\n.blue-button {\n  border-radius: 3.75rem;\n  background: var(--primary-colors-primary, #536DF6);\n  border: none;\n  color: #EBEDFC;\n  padding: 0.5rem 0.8rem;\n  width: 8rem;\n  box-shadow: 0px 8px 25px 0px rgba(70, 78, 251, 0.03);\n  margin: 0.2rem;\n}\n\n.white-button {\n  border-radius: 3.75rem;\n  margin: 0.2rem;\n  border: 2px solid var(--primary-colors-primary, #536DF6);\n  color: #536DF6;\n  background: var(--primary-colors-white, #FFF);\n  padding: 0.5rem 0.8rem;\n  width: 8rem;\n  box-shadow: 0px 8px 25px 0px rgba(70, 78, 251, 0.03);\n}\n\n.row-with-gap {\n  display: flex;\n  flex-direction: row;\n  gap: 1rem;\n}\n\n.header-row {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.input-style {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 3.75rem;\n  border: 2px solid var(--primary-colors-light, #EBEDFC);\n  background: var(--primary-colors-white, #FFF);\n  box-shadow: 0px 8px 25px 0px rgba(70, 78, 251, 0.03);\n}\n\n.big-input {\n  width: 21rem;\n  padding: 0.5rem 0;\n  margin-bottom: 0.5rem;\n}\n\n.small-input {\n  width: 10rem;\n  padding: 0.5rem 0;\n  margin-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
