// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.test {
  display: flex;
  flex-direction: column;
  width: 50vw;
}

.row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/pages/scans/test/test.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,WAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;AAEJ","sourcesContent":[".test{\n    display: flex;\n    flex-direction: column;\n    width: 50vw;\n}\n.row-container{\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
