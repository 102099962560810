import { useEffect, useRef, useState } from "react";
import './data-management.scss'
import Modal from 'react-bootstrap/Modal';
import { get_current_user_by_id } from "../../user/api/user-api-client";
import editUser from '../../../utils/assets/edit-user.svg'
import TableRows from "./utils/table-row/TableRows";
import EditUser from "../edit-user/edit-user";
import xModal from '../../../utils/assets/modal-x.svg'
import { exportToExcel } from "./utils/excel-export/excel-export";

export default function DataManagement({ dataType, dataList, header, headerList, isEditUser }) {
    const [isShowModal, setIsShowModal] = useState('')
    const [isShowSideModal, setIsShowSideModal] = useState('')
    const [user, setUser] = useState({})
    const [DataListToShow, setDataListToShow] = useState([])
    const [FilteredDataListToShow, setFilteredDataListToShow] = useState([])
    const isFirstTime = useRef(true)
    const filterAndSortObject = useRef({})

    useEffect(() => {
        if (dataList && dataList.length > 0 && isFirstTime.current) {
            isFirstTime.current = false
            const sortedDataList = [...dataList].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setDataListToShow(sortedDataList)
            setFilteredDataListToShow(sortedDataList)
        }
    }, [dataList])

    const getUser = async (userId) => {
        setIsShowModal(userId)
        const userData = await get_current_user_by_id({ 'userId': userId });
        setUser(userData.data)
    }

    const updateUserInDataList = (updatedUser) => {
        let filterAndSortObject1 = filterAndSortObject.current;
        const updatedList = DataListToShow.map((item) =>
            item._id === updatedUser._id ? updatedUser : item
        );
        filterAndSortObject1.index -= 1;
        filterAndSort(filterAndSortObject1, updatedList)
    };

    const deleteUserInDataList = (updatedUser) => {
        let filterAndSortObject1 = filterAndSortObject.current;
        const updatedList = DataListToShow.filter((item) => item._id !== updatedUser._id);
        filterAndSortObject1.index -= 1;
        filterAndSort(filterAndSortObject1, updatedList)
    };

    const filterAndSort = async (item, list) => {
        filterAndSortObject.current = item;
        let updatedDataList;
        let sortedItems;
        const type = item.type;
        const subType = item.subOptions[item.index];
        item.index = item.index < item.subOptions.length ? item.index + 1 : 0;
        if (subType) {
            if (item.action == 'sort') {//פונקציות מיון
                if (type == 'createdAt') {
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const dateA = new Date(a.createdAt);
                        const dateB = new Date(b.createdAt);
                        return subType === 'order' ? dateA - dateB : dateB - dateA;
                    });
                }
                else if (type === 'userName') {
                    const isAscending = subType === 'order';
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const nameA = a.userName || '';
                        const nameB = b.userName || '';
                        return isAscending ? nameA.localeCompare(nameB, 'he') : nameB.localeCompare(nameA, 'he');
                    });
                }
                else if (type === 'customerName') {
                    const isAscending = subType === 'order';
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const nameA = a.customerName || '';
                        const nameB = b.customerName || '';
                        return isAscending ? nameA.localeCompare(nameB, 'he') : nameB.localeCompare(nameA, 'he');
                    });
                }
                else if (type == 'errorsNum') {
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const maxErrorsA = Math.max(...a.scansList.map(scan => scan.numOfErrors));
                        const maxErrorsB = Math.max(...b.scansList.map(scan => scan.numOfErrors));
                        return maxErrorsB - maxErrorsA;
                    });
                }
                else if (type == 'balance' || type == 'sum' || type == 'sumAfterCupon') {
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const maxErrorsA = a[type];
                        const maxErrorsB = b[type];
                        return subType == 'order' ? maxErrorsA - maxErrorsB : maxErrorsB - maxErrorsA;
                    });
                }

            }
            else {//פונציות סינון
                console.log('subType', subType);
                if (type == 'statusCode' || type == 'Role' || type == 'cuponCodeName') {
                    if (subType == 'all')
                        sortedItems = list || dataList;
                    else
                        if (type == 'statusCode')
                            sortedItems = [...list || dataList].filter(data => data.scansList.some(scan => scan[type] == subType));
                        else
                            sortedItems = [...list || dataList].filter(data => data[type] == subType);
                }
                else if (type == 'pdfLink' || type == 'phone') {
                    if (subType == 'all')
                        sortedItems = list || dataList
                    else
                        sortedItems = [...list || dataList].filter(data => data[type] && data[type] !== '');
                }
            }
        }
        updatedDataList = sortedItems;
        if (updatedDataList) {
            setDataListToShow(updatedDataList);
            setFilteredDataListToShow(updatedDataList);
        }

    }

    const handleExport = () => {
        exportToExcel(FilteredDataListToShow, 'שימוש בקופון');
    };

    return (
        <div className="data-header-container ">
            <div>
                <div className="header-row">
                    <div className="data-header-wrapper">
                        <div className="data-manage-header">{FilteredDataListToShow && FilteredDataListToShow?.length} מתוך {header}</div>
                    </div>
                    {['cupon', 'payment'].includes(dataType) && <button onClick={handleExport} className="export-button">יצוא לקובץ אקסל</button>}
                </div>
                <div className={`table-row padd`}>
                    {/* <div className="table-col chooseLine header-bold-text "><input type="checkbox"></input></div> */}
                    {headerList.map(item =>
                        <div type={item.action ? 'button' : ''} className="table-col header-bold-text col" onClick={() => item.action ? filterAndSort(item) : undefined}>{item.label}</div>
                    )}
                    {dataType == 'user' && isEditUser && <div className="table-col edit-button header-bold-text "></div>}
                </div>
                <TableRows dataType={dataType} items={FilteredDataListToShow} getUser={getUser} setIsShowModal={setIsShowModal} setIsShowSideModal={setIsShowSideModal} editUser={editUser} isEditUser={isEditUser}></TableRows>

            </div>

            {/* user data modal */}
            <Modal
                show={isShowModal}
                onHide={() => setIsShowModal('')}
                centered
                className={`modal right-margin`}
            >
                <Modal.Body className='user-details-modal' >
                    <div className="modal-title-style">
                        <img src={xModal} type='button' onClick={() => setIsShowModal('')}></img>
                        <div className="bold-text">{'שם משתמש'}</div>
                    </div>

                    <div className="input-style big-input">{user.customerName}</div>
                    <div className="input-style big-input">{user.phone || 'לא קיים טלפון'}</div>
                    <div className="input-style big-input">{user.email}</div>
                    <div className="row-with-gap">
                        <div className="input-style small-input">יתרה: {user.balance}</div>
                        <div className="input-style small-input">שימוש אחרון: {user.lastPayment}</div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* side modal edit */}
            <Modal
                show={isShowSideModal}
                onHide={() => setIsShowSideModal('')}
                centered
                className={`modal side-modal`}
            >
                <Modal.Body className='edit-user-modal-body' >
                    <div className="modal-title-style">
                        <img src={xModal} type='button' onClick={() => setIsShowSideModal('')}></img>
                        <div className="bold-text">{'עריכת משתמש'}</div>
                    </div>
                    <EditUser
                        user={isShowSideModal}
                        closeModal={() => setIsShowSideModal('')}
                        updateUserInDataList={updateUserInDataList}
                        deleteUserInDataList={deleteUserInDataList}
                    ></EditUser>
                </Modal.Body>
            </Modal>

        </div>
    )
}