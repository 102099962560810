import axios from 'axios'
import { BASE_URL } from '../../../utils/constants';

const URL = `${BASE_URL}/api/project/`;

export const projectsFetch = (userId) => {
    return axios.get(`${URL}fetch_all/${userId}`).then((response) => response.data)
}
export const fetchProjectsByType = (userId) => {
    return axios.get(`${URL}fetch_all_by_type/${userId}`).then((response) => response.data)
}

export const addProject = (project) => {
    console.log('addProject');
    return axios.post(`${URL}create_project`, project).then((response) => response.data)
}

export const updateProject = (idProject, project) => {
    return axios.put(`${URL}update_project/${idProject}`, project).then((response) => response.data)
}

export const deleteProject = (projectId, userId) => {
    return axios.delete(`${URL}delete_project/${projectId}/${userId}`).then((response) => response.data)
}
