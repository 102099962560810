// import React, { useEffect, useState } from 'react'
// import google from '../../../utils/assets/googel.svg'
// import facebook from '../../../utils/assets/facebook.svg'
// import AppleLogin from 'react-apple-login'
// import apple from '../../../utils/assets/apple.svg'
// import { BASE_URL, smallScreen, useLanguage } from '../../../utils/constants';
// import { GoogleLogin } from 'react-google-login';
// import { gapi } from 'gapi-script';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import { useDispatch } from 'react-redux';
// import { getUserGoogelLogin } from '../store/action';
// import { useNavigate } from 'react-router-dom'
// import axios from 'axios';
// import './login-ways-style.scss'

// export default function LoginWays() {
//     const navigate = useNavigate();
//     const t = useLanguage();
//     const isSmallScreen = smallScreen();
//     const dispatch = useDispatch()
//     const clientId = '457529097789-6j638vdvdhj87i62je4md3tup9v66sku.apps.googleusercontent.com'
//     useEffect(() => {
//         const initClient = () => {
//             gapi.auth2.init({
//                 clientId: clientId,
//                 scope: ''
//             });
//         };
//         gapi.load('client:auth2', initClient);
//     });

//     const handleGoogleLoginSuccess = async (res) => {
//         const idToken = res.getAuthResponse().id_token;
//         const resGoogleLogin = await dispatch(getUserGoogelLogin(idToken))
//         if (resGoogleLogin) {
//             console.log('resGoogleLogin', resGoogleLogin);
//             if (resGoogleLogin?.isNewUser == true) {
//                 sessionStorage.setItem('isNewUser', 'true')
//             }
//             navigate('/dashboard');
//         }
//     };

//     const handleGoogleLoginFailure = (err) => {
//         console.log('failed', err);
//     };
//     const handleFacebookLoginSuccess = (response) => {
//         console.log('Facebook login success:', response);
//     };

//     const handleFacebookLoginFailure = (error) => {
//         console.error('Facebook login failed:', error);
//     };

//     const handleGoogleLogin = () => {
//         gapi.auth2.getAuthInstance().signIn().then(handleGoogleLoginSuccess, handleGoogleLoginFailure);
//     };

//     return (
//         <div className="login-ways">

//                 <GoogleLogin
//                     clientId={clientId}
//                     onSuccess={handleGoogleLoginSuccess}
//                     onFailure={handleGoogleLoginFailure}
//                     cookiePolicy={'single_host_origin'}
//                     isSignedIn={false}
//                     prompt="select_account"
//                     render={(renderProps) => (
//                         <div className={'login-button'} type='button' onClick={renderProps.onClick}>
//                             <img src={google} alt=''></img>
//                             {t.user.googleLogin}
//                         </div>
//                     )}
//                 />

//             {/* 
//             <div className={isSmallScreen ?'':'login-button'} type='button' onClick={signInWithApple}>
//                 <img src={apple} alt=''></img>
//                 {!isSmallScreen && t.user.appleLogin}
//             </div>

//             <FacebookLogin
//                 appId="1884327455417372" // Replace with your Facebook App ID
//                 fields="name,email,picture"
//                 callback={handleFacebookLoginSuccess}
//                 onFailure={handleFacebookLoginFailure}
//                 render={(renderProps) => (
//                     <div
//                         onClick={renderProps.onClick}
//                         className={`custom-facebook-login-button ${isSmallScreen ?'': 'login-button'}`}
//                         type='button'
//                     >
//                         <img src={facebook} alt=''></img>
//                         {!isSmallScreen && t.user.facebookLogin}
//                     </div>
//                 )}
//             />  */}
//         </div>
//     )
// }



import React, { useEffect, useState } from 'react'
import google from '../../../utils/assets/googel.svg'
import { smallScreen, useLanguage } from '../../../utils/constants';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { useDispatch } from 'react-redux';
import { getUserGoogelLogin } from '../store/action';
import { useNavigate } from 'react-router-dom'
import './login-ways-style.scss'
import ReactGA from 'react-ga4';

export default function LoginWays() {
    const navigate = useNavigate();
    const t = useLanguage();
    const dispatch = useDispatch();
    const clientId = '457529097789-6j638vdvdhj87i62je4md3tup9v66sku.apps.googleusercontent.com'
    const [idToken, setIdToken] = useState('')
    const [res, setRes] = useState('')
    const isDashboard = localStorage.getItem('isDashboard') === null ? false : JSON.parse(localStorage.getItem('isDashboard'));

    useEffect(() => {
        const initClient = () => {
            gapi.auth2.init({
                clientId: clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    });

    const handleGoogleLoginSuccess = async (res) => {
        const idToken = res.getAuthResponse().id_token;
        setIdToken(idToken)
        const resGoogleLogin = await dispatch(getUserGoogelLogin(idToken))
        if (resGoogleLogin) {
            setRes(resGoogleLogin.message)
            console.log('resGoogleLogin', resGoogleLogin);
            if (resGoogleLogin?.isNewUser == true) {                
                sessionStorage.setItem('isNewUser', resGoogleLogin.data._id)
                sessionStorage.setItem('showPhoneModal', 'true')
            }
            if (isDashboard)
                navigate('/admin-dashboard')
            else
                navigate('/dashboard')
        }
    };

    const handleGoogleLoginFailure = (err) => {
        console.log('failed', err);
    };

    return (
        <div>
            <div className="login-ways">
                <GoogleLogin
                    clientId={clientId}
                    onSuccess={handleGoogleLoginSuccess}
                    onFailure={handleGoogleLoginFailure}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={false}
                    prompt="select_account"
                    render={(renderProps) => (
                        <div className={'login-button'} type='button' onClick={renderProps.onClick}>
                            <img src={google} alt='google'></img>
                            {t.user.googleLogin}
                        </div>
                    )}
                />

            </div>
        </div>
    )
}