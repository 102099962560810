
import React, { useEffect } from 'react';
import emailjs from 'emailjs-com';

export default function SendNewEmail({ id, email }) {    
    // console.log("SendNewEmail");
    
    const hash = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYTAxZjkwNWQxMTMxYjQ0NDFhZTdmOCIsImlhdCI6MTY3MTQzODIyNCwiZXhwIjoxNjcxNTI0NjI0fQ._8v30tb_0giuh4nXWC0sEhRtD4afurzrhnXI-x89vtg'

    async function sendEmail() {
        console.log('0');
        
        try {
            if (email) {
                console.log('1');
                
                const form = document.getElementById(`contact-form-${id}`);
                console.log(form);
                
                emailjs.sendForm('service_qswsu7r',  'template_ay6stjc', form, 'RG7No6OmeqiYqalp_')
                    .then(async (result) => {
                        console.log('✅');
                        localStorage.setItem(`email${id}`,email)
                        sessionStorage.removeItem('isNewUser')
                    }, (error) => {
                        console.log('error send email😅😅😅', email, error.text);
                    });
            }
        }
        catch (error) {
            console.log('error🥹🥹🥹', error);
        }
    }

    useEffect(() => {
        const delay = 1000;
        const timeoutId = setTimeout(() => {
            sendEmail();
        }, delay);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <div>
lllllllllllllllllllllllllllllllllllllllllll
        <form id={`contact-form-${id}`} name={`contact-form`} onSubmit={sendEmail}>
            <input type="hidden" name="from_name" value={'StamScanner'} />
            <textarea hidden="hidden" name="to_email" id='toEmail' value={email} ></textarea>
            <p name="token" value={hash} hidden='hidden'></p>
        </form>
        </div>
    );
}
