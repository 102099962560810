import { useDispatch } from "react-redux";
import { getUsersByRole } from "../../pages/user/store/action";
import SendOpenningEmail from "./send-email";
import { useState } from "react";
import SendNewEmail from "./send-new-email";

export default function SendEmailForAllUsers() {
    const dispatch = useDispatch();
    const [emails, setEmails] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [emailComponents, setEmailComponents] = useState([]);


    const prepareUsersEmailList = async (role) => {
        try {
            const users = await dispatch(getUsersByRole(role));
            console.log('users👥', users.length);

            const usersEmailWithIndex = users.map((user, index) => {
                return {
                    index: index,
                    _id: user._id,
                    email: user.email,
                };
            });
            console.log('usersEmailWithIndex', usersEmailWithIndex.length);
           const components= usersEmailWithIndex.map((user, index) => {
                // console.log('🤎');
                
                if (Object.values(localStorage).includes(user.email)) {
                    // console.log('❌'); 
                    return null;
                }
                else {
                    console.log('😊');
                    return (
                        <SendNewEmail key={index} id={user._id} email={user.email}  />
                    )
                }
            });
          setEmailComponents(components.filter(Boolean));


        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }

    const localStorageEmails = () => {
        console.log('localStorage', localStorage.length);
        // console.log('😉', Object.values(localStorage));
        let totalSize = 0;

        // מעבר על כל הפריטים ב-localStorage
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            const value = localStorage.getItem(key);

            // חישוב אורך המפתח והערך בבתים (UTF-16: כל תו = 2 בתים)
            totalSize += (key.length + value.length) * 2;
        }

         console.log(`Total localStorage size: ${(totalSize / 1024).toFixed(2)} KB`);
    }

    const prepareUsersEmailList2 = async () => {
        setIsSending(true);

        try {
            const users = [{ _id: '11', email: 'tzp8281@gmail.com' },
            { _id: '44', email: 'omersvilim5@gmail.com' },
            { _id: '55', email: 'stamscanner2@gmail.com' },
            ]

            console.log('users', users.length, users);
            let emails=''
            const emailComponents = users.map((user, index) => {
                if (Object.values(localStorage).includes(user.email)) {
                    // console.log('❌');
                    // return null;
                }
                else {     
                    console.log('😊');
                    emails+=` ${user.email},`
                    // return (
                        // )
                    }
            });

                console.log('emails',emails);
                <SendNewEmail key={222} id={111} email={emails} />
            setEmails(emailComponents);
        } catch (error) {
            console.error('Error fetching users:', error);
        }

        setIsSending(false); // Reset sending status after processing
    }

    return (
        <>
            <div> 
         <button onClick={() => prepareUsersEmailList('admin')} disabled={isSending}>שלח אימייללמשתמשי אדמין</button>
            </div>
           -----------------------------------------------------------------
            <div>
                <button onClick={() => prepareUsersEmailList('user')} disabled={isSending}>שלח  אימייל לכל המשתמשים✅✅✅</button>
            </div>
           -----------------------------------------------------------------

            <div>
                <button onClick={() => prepareUsersEmailList2()} disabled={isSending} className="btn btn-primary mt-4"> 🤎🤎  </button>
            </div>

            <div>
                <button onClick={localStorageEmails}>הצגת המשתמשים שנשלח אליהם מייל בפועל</button>
            </div>
            {emailComponents}

        </>
    )
}
