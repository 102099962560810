import { useEffect, useMemo, useRef, useState } from "react";
import './data-management.scss'
import Modal from 'react-bootstrap/Modal';
import { get_current_user_by_id } from "../../user/api/user-api-client";
import editUser from '../../../utils/assets/edit-user.svg'
import TableRows from "../utils/table-row/TableRows";
import EditUser from "../edit-user/edit-user";
import xModal from '../../../utils/assets/modal-x.svg'
import { exportToExcel } from "../utils/excel-export/excel-export";
import { getAllUsers, getCurrentUserById, updateUser } from "../../user/store/action";
import { BASE_URL, formatDate, smallScreen, useLanguage } from "../../../utils/constants";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { calculateSumOfAllPayments } from "../../payments/store/action";

export default function DataManagement({ dataType, dataList, header, headerList }) {
    const [isShowModal, setIsShowModal] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [openCuponModal, setOpenCuponModal] = useState(false)
    const [openExportModal, setOpenExportModal] = useState(false)
    const [isShowSideModal, setIsShowSideModal] = useState('')
    const [user, setUser] = useState({})
    const storageNameToSearch = sessionStorage.getItem('storageNameToSearch') || ''
    const [nameToSearch, setNameToSearch] = useState(storageNameToSearch)
    const [DataListToShow, setDataListToShow] = useState([])
    const [FilteredDataListToShow, setFilteredDataListToShow] = useState(dataList)    
    const [activeMonths, setActiveMonths] = useState([])
    const [activeYear, setActiveYear] = useState('')
    const [yearList, setYearList] = useState([])
    const [numberOfAllUserScans, setNumberOfAllUserScans] = useState(0)
    const [sumOfAllPayments, setSumOfAllPayments] = useState(0)
    const isFirstTime = useRef(true)
    const filterAndSortObject = useRef(null)
    const isEffect = useRef(false)
    const t = useLanguage();
    const isSmallScreen = smallScreen();
    const dispatch = useDispatch()
    const [emailsAndIds,setEmailsAndIds]=useState([]);
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser);
    const isCurrentUserEffect = useRef(true)
    const isSuperAdmin = currentUser && currentUser?.Role == 'super-admin' ? true : false;
    
    const [paymentFormData, setPaymentFormData] = useState({
        amount: '',
        paymentOption: '',
        paymentDate:'',
        paymentTime:'',
        id:''
    });
    const [cuponFormData, setCuponFormData] = useState({
        cuponCodeName: '',
        userId: '',
        sum: '',
        sumBeforeCupon: ''
    });
    const sortedDataList = useMemo(() => {
        return dataList?.length > 0
            ? dataList.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            : [];
    }, [dataList]);

    useEffect(() => {
        if (isFirstTime.current && sortedDataList?.length > 0) {            
            isFirstTime.current = false;
            setDataListToShow(sortedDataList);
            setFilteredDataListToShow(sortedDataList);
        }
    }, [sortedDataList]);

    useEffect(()=>{setNameToSearch(storageNameToSearch)},[storageNameToSearch])
    
    useEffect(() => {
        if (currentUserId && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId, currentUser])

    const getUser = async (userId) => {        
        setNumberOfAllUserScans(0)
        setSumOfAllPayments(-1)
        const response = await axios.post(`${BASE_URL}/api/reportlogs/get_scans_number_of_user/`, { 'userId': userId });
        console.log('😎😎😎',response);
        if(response.data.user){
            setIsShowModal(userId)
            setUser(response.data.user)
            setNumberOfAllUserScans(response.data.numberOfAllUserScans)
        }
        else{
            alert('משתמש לא נמצא')
        }
        calculateSumOfAllPaymentsFunc(userId)
    }

    const updateUserInDataList = (updatedUser) => {
        let filterAndSortObject1 = filterAndSortObject.current;

        const updatedList = DataListToShow.map((item) =>
            item._id === updatedUser._id ? updatedUser : item
        );
        if (filterAndSortObject1 != null) {
            filterAndSortObject1.index -= 1;
            filterAndSort(filterAndSortObject1, updatedList)
        }
        else {
            setDataListToShow(updatedList);
            setFilteredDataListToShow(updatedList);
        }
    };

    const deleteUserInDataList = (updatedUser) => {
        let filterAndSortObject1 = filterAndSortObject.current;
        const updatedList = DataListToShow.filter((item) => item._id !== updatedUser._id);
        if (filterAndSortObject1 != null) {
            filterAndSortObject1.index -= 1;
            filterAndSort(filterAndSortObject1, updatedList)
        }
        else {
            setDataListToShow(updatedList);
            setFilteredDataListToShow(updatedList);
        }
    };

    const filterByUserName = async (value) => {
        console.log('filterByUserName',value);
        setNameToSearch(value)
        sessionStorage.setItem('storageNameToSearch', value)
        const filteredList = [...dataList].filter(data => data?.userName?.toLowerCase()?.includes(value?.toLowerCase()) || data?.customerName?.toLowerCase()?.includes(value?.toLowerCase()));
        setDataListToShow(filteredList);
        setFilteredDataListToShow(filteredList);
    }

    const filterAndSort = async (item, list) => {
        filterAndSortObject.current = item;
        let updatedDataList;
        let sortedItems;
        const type = item.type;
        const subType = item.subOptions[item.index];
        item.index = item.index < item.subOptions?.length ? item.index + 1 : 0;
        if (subType) {
            if (item.action == 'sort') {//פונקציות מיון
                if (type == 'createdAt') {
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const dateA = new Date(a.createdAt);
                        const dateB = new Date(b.createdAt);
                        return subType === 'order' ? dateA - dateB : dateB - dateA;
                    });
                }
                else if (type === 'userName') {
                    const isAscending = subType === 'order';
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const nameA = a.userName || '';
                        const nameB = b.userName || '';
                        return isAscending ? nameA.localeCompare(nameB, 'he') : nameB.localeCompare(nameA, 'he');
                    });
                }
                else if (type === 'customerName') {
                    const isAscending = subType === 'order';
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const nameA = a.customerName || '';
                        const nameB = b.customerName || '';
                        return isAscending ? nameA.localeCompare(nameB, 'he') : nameB.localeCompare(nameA, 'he');
                    });
                }
                else if (type == 'errorsNum') {
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const maxErrorsA = Math.max(...a.scansList.map(scan => scan.numOfErrors));
                        const maxErrorsB = Math.max(...b.scansList.map(scan => scan.numOfErrors));
                        return maxErrorsB - maxErrorsA;
                    });
                }
                else if (type == 'balance' || type == 'sum' || type == 'sumBeforeCupon') {
                    sortedItems = [...list || dataList].sort((a, b) => {
                        const maxErrorsA = a[type];
                        const maxErrorsB = b[type];
                        return subType == 'order' ? maxErrorsA - maxErrorsB : maxErrorsB - maxErrorsA;
                    });
                }

            }
            else {//פונציות סינון
                console.log('subType', subType);
                if (type == 'statusCode' || type == 'Role' || type == 'paymentOption') {
                    if (subType == 'all')
                        sortedItems = list || dataList;
                    else
                        if (type == 'statusCode')
                            sortedItems = [...list || dataList].filter(data => data.scansList.some(scan => scan[type] == subType));
                        else
                            sortedItems = [...list || dataList].filter(data => data[type] == subType);
                }
                else if (type == 'pdfLink' || type == 'phone') {
                    if (subType == 'all')
                        sortedItems = list || dataList
                    else
                        sortedItems = [...list || dataList].filter(data => data[type] && data[type] !== '');
                }
            }
        }
        if (nameToSearch)
            sortedItems = [...sortedItems || []].filter(data => data?.userName?.toLowerCase()?.includes(nameToSearch?.toLowerCase()) || data?.customerName?.toLowerCase()?.includes(nameToSearch?.toLowerCase()));

        updatedDataList = sortedItems;
        if (updatedDataList) {
            setDataListToShow(updatedDataList);
            setFilteredDataListToShow(updatedDataList);
        }

    }

    const handleExport = () => {
        console.log('FilteredDataListToShow', FilteredDataListToShow);

      let filterByDate = [...FilteredDataListToShow].filter((item) => {
      let date = new Date(item.createdAt);
    return (
        date.getFullYear() === activeYear &&
        activeMonths.some(month => month.index === date.getMonth() + 1)
    );
    }); 

if (dataType === 'payment') {
    filterByDate = filterByDate.map((item) => ({
        [t.admin.userName]: item.userName,
        [t.admin.status]: item.status === '000' ? 'הצליח' : 'נכשל',
        [t.admin.paymentOption]: item.paymentOption,
        [t.admin.createdAt]: item.createdAt,
        [t.admin.amount]: item.amount,
    }));
} else {
    filterByDate = filterByDate.map((item) => ({
        [t.admin.userName]: item.userName,
        [t.admin.cuponCodeName]: item.cuponCodeName,
        [t.admin.createdAt]: item.createdAt,
        [t.admin.sumBeforeCupon]: item.sumBeforeCupon,
        [t.admin.sum]: item.sum,
    }));
}



        console.log('filterByDate', filterByDate);
        if (filterByDate?.length > 0) {
            if (dataType == 'payment') {
                exportToExcel(filterByDate, `תשלומים ${activeMonths.length>1?'': activeMonths[0].name}`);
            }
            else {
                exportToExcel(filterByDate, `שימוש בקופון ${activeMonths.length>1?'': activeMonths[0].name}`);
            }
        }
        else {
            alert('אין נתונים בטווח הזמן')
        }
    };
    const monthList = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר']

    useEffect(() => {
        const date = new Date()
        const year = date.getFullYear()
        const list = [year, year - 1, year - 2];
        setYearList(list)
        setActiveYear(year)
    }, [])

    useEffect(() => {
        if (nameToSearch != '' && nameToSearch != undefined && FilteredDataListToShow?.length > 0) {
            if (isEffect.current == false) {
                isEffect.current = true;
                filterByUserName(nameToSearch)
            }
        }

    }, [FilteredDataListToShow])

    const onSubmit = async () => {
        try {
            if (isFormValid()) {
                //todo to connect time and date
                console.log(paymentFormData);
                paymentFormData.dateTime=new Date(`${paymentFormData.paymentDate}T${paymentFormData.paymentTime}`)
                console.log('paymentFormData',paymentFormData);
                const response = await axios.post(`${BASE_URL}/api/payment/createTransactionFromDashboard/`, paymentFormData);
                if (response) {
                    alert('התשלום התווסף בהצלחה')
                    setOpenModal(false)
                    // const updatedFilteredList = [...FilteredDataListToShow, response.data];
                    // setFilteredDataListToShow(updatedFilteredList)
                    // const updatedList = [...DataListToShow, response.data];
                    // setDataListToShow(updatedList)

                    setPaymentFormData({
                        amount: '',
                        paymentOption: '',
                        paymentDate:'',
                        paymentTime:'',
                        id:''
                    })
                    window.location.reload();
                }
                else {
                    alert('התשלום לא התווסף')
                }
            }
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const onSubmitCupon = async () => {
        try {
            if (isCuponFormValid()) {
                const response = await axios.post(`${BASE_URL}/api/cuponCodeUses/add/`, cuponFormData);
                if (response) {
                    alert('מימוש התווסף בהצלחה')
                    setOpenCuponModal(false)
                    setCuponFormData({
                        cuponCodeName: '',
                        sum: '',
                        sumBeforeCupon: '',
                    })
                    window.location.reload();
                }
                else {
                    alert('מימוש לא התווסף')
                }
            }
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const isFormValid = () => {
        const { amount, paymentOption,paymentTime,paymentDate,id } = paymentFormData;
        return (
            amount &&
            !isNaN(amount) &&
            Number(amount) > 0 &&
            paymentOption.trim() !== ''&&
            paymentDate&&
            paymentTime&&
            id
        );
    };

    const isCuponFormValid = () => {
        const { cuponCodeName, sum, sumBeforeCupon } = cuponFormData;
        return (
            cuponCodeName &&
            sum &&
            Number(sum) > 0 &&
            sumBeforeCupon &&
            Number(sumBeforeCupon) > 0
        );
    };

    const HandleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);

        setPaymentFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    
    const CuponHandleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);

        setCuponFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const deletePayment = async (paymentId) => {
        const userResponse = window.confirm("האם אתה בטוח?");
        if (userResponse) {
            const response = await axios.post(`${BASE_URL}/api/payment/deletePayment`, { paymentId: paymentId })
            console.log(response);
            alert(response.data.message == 'success' ? 'התשלום נמחק בהצלחה' : 'התשלום לא נמחק')
            window.location.reload();
        }
    }

    const deleteCuponUse = async (cuponUseId) => {
        const userResponse = window.confirm("האם אתה בטוח?");
        if (userResponse) {
            const response = await axios.post(`${BASE_URL}/api/cuponCodeUses/delete`, { cuponUseId: cuponUseId })
            console.log(response);
            alert(response.data.message == 'success' ? ' נמחק בהצלחה' : ' לא נמחק')
            window.location.reload();
        }
    }

    const addActiveMonth = async (name, index) => {
    setActiveMonths(prevMonths => {
        const exists = prevMonths.some(month => month.name === name && month.index === index);

        return exists 
        ? prevMonths.filter(month => !(month.name === name && month.index === index)) // Remove if exists
        : [...prevMonths, { name, index }]; // Add if not exists
    });
    console.log(activeMonths);
    
    };

    const getUsersIdByEmail=async()=>{
        const usersList=await dispatch(getAllUsers())
        const emailsAndIds=[]
        usersList.map((user)=>{
            emailsAndIds.push({email:user.email,id:user._id})
        })
        setEmailsAndIds(emailsAndIds)
    }
    
    useEffect(()=>{
        if(!emailsAndIds.length)
           getUsersIdByEmail()
    },[])

    const [searchTerm, setSearchTerm] = useState("");

    const filteredEmails = emailsAndIds.filter(item =>
        item.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const calculateSumOfAllPaymentsFunc=async(userId)=>{
        const res=await dispatch(calculateSumOfAllPayments({userId:userId}))
        console.log('res',res);
        
        setSumOfAllPayments(res.finallSum)
     }
    return (
        <div className="data-header-container">
            <div>
                {header && <div className="header-row">
                    <div className="data-header-wrapper">
                        <div className="data-manage-header">{FilteredDataListToShow && FilteredDataListToShow?.length} מתוך {header}</div>
                    </div>
                    <div className="row1">
                        {['cupon', 'payment'].includes(dataType) && <button onClick={setOpenExportModal} className="export-button">יצוא לקובץ אקסל</button>}
                        {['payment'].includes(dataType) && <button onClick={() => setOpenModal(true)} className="export-button blue">הוספת עיסקה</button>}
                        {['cupon'].includes(dataType) && <button onClick={() => setOpenCuponModal(true)} className="export-button blue">הוספת מימוש</button>}
                        <input type="text" className='search-input' value={nameToSearch} onChange={(e) => { filterByUserName(e.target.value) }} placeholder="חיפוש"></input>
                    </div>
                </div>}
                <div className={`table-row padd`}>
                    {/* <div className="table-col chooseLine header-bold-text "><input type="checkbox"></input></div> */}
                    {headerList.map(item =>
                        <div type={item.action ? 'button' : ''} className="table-col header-bold-text col" onClick={() => item.action ? filterAndSort(item) : undefined}>{item.label}</div>
                    )}
                    {['user','cupon','payment'].includes(dataType) && isSuperAdmin && <div className="table-col edit-button header-bold-text "></div>}
                </div>
                <TableRows dataType={dataType} items={FilteredDataListToShow} getUser={getUser} deletePayment={deletePayment} deleteCuponUse={deleteCuponUse} setIsShowModal={setIsShowModal} setIsShowSideModal={setIsShowSideModal} editUser={editUser} isSuperAdmin={isSuperAdmin}></TableRows>
           
            </div>


            {/* user data modal */}
            <Modal
                show={isShowModal}
                onHide={() => setIsShowModal('')}
                centered
                className={`modal right-margin`}
            >
                <Modal.Body className='user-details-modal' >
                    <div className="modal-title-style">
                        <img src={xModal} type='button' onClick={() => setIsShowModal('')} alt='x button modal'></img>
                        <div className="bold-text">{'שם משתמש'}</div>
                    </div>

                    <div className="input-style big-input">{user.customerName}</div>
                    <div className="input-style big-input">{user.phone || 'לא קיים טלפון'}</div>
                    <div className="input-style big-input">{user.email}</div>
                    <div className="input-style big-input">{formatDate(user.createdAt)}</div>
                    <div className="input-style big-input">{user.isAddBrokenLetter == true ? 'אות קטועה מופיעה' : 'אות קטועה לא מופיעה'}</div>
                    <div className="row-with-gap">
                        <div className="input-style small-input">יתרה: {user.balance}</div>
                        <div className="input-style small-input">שימוש אחרון: {user.lastPayment}</div>
                    </div>
                    <div className="input-style big-input">סך הסריקות של משתמש זה: {numberOfAllUserScans}</div>
                    {/* {sumOfAllPayments!=-1? */}
                    <div className="input-style big-input">סך התשלומים של משתמש זה: {sumOfAllPayments}</div>
                    {/* :
                    <div className="input-style big-input" type='button' onClick={()=>{calculateSumOfAllPaymentsFunc(user._id)}}>הצגת סך התשלומים של משתמש זה</div>} */}
                </Modal.Body>
            </Modal>

            {/* export data modal */}
            <Modal
                show={openExportModal}
                onHide={() => setOpenExportModal(false)}
                centered
                className={`modal right-margin`}
            >
                <Modal.Body className='user-details-modal' >
                    <div className="modal-title-style">
                        <img src={xModal} type='button' onClick={() => setOpenExportModal(false)} alt='x button modal'></img>
                        <div className="bold-text">{'יצוא לאקסל'}</div>
                    </div>

                    <div>אנא בחר את החודש שתרצה לייצא לאקסל</div>

                    <select id="year-select" className="year-select" onChange={(e) => setActiveYear(e.target.value)}>
                        {yearList.map((year) => {
                            return (
                                <option>{year}</option>
                            )
                        })
                        }
                    </select>

                    <div className="month-wrapper">
                        {monthList.map((month, index) => {
                        const isActive = activeMonths.some(active => active.name === month);

                            return (
                                <button 
                                    className={`month ${isActive ? 'active' : ''}`} 
                                    onClick={() => addActiveMonth(month, index + 1)}
                                >
                                    {month}
                                </button>
                            );
                        })}

                        {/* {monthList.map((month, index) => {
                            return (
                                <button className={`month ${activeMonth.name == month ? 'active' : ''}`} onClick={() => addActiveMonth(month,index + 1 )}>{month}</button>
                            )
                        })} */}
                    </div>

                    <div>
                        <button type="button" className="white-button big" onClick={() => setOpenExportModal(false)} >ביטול</button>
                        <button type="button" className={`blue-button big`} onClick={() => handleExport()}>אישור</button>
                    </div>


                </Modal.Body>
            </Modal>

            {/* side modal edit */}
            <Modal
                show={isShowSideModal}
                onHide={() => setIsShowSideModal('')}
                centered
                className={`modal side-modal`}
            >
                <Modal.Body className='edit-user-modal-body' >
                    <div className="modal-title-style">
                        <img src={xModal} type='button' onClick={() => setIsShowSideModal('')} alt='x button modal'></img>
                        <div className="bold-text">{'עריכת משתמש'}</div>
                    </div>
                    <EditUser
                        user={isShowSideModal}
                        closeModal={() => setIsShowSideModal('')}
                        updateUserInDataList={updateUserInDataList}
                        deleteUserInDataList={deleteUserInDataList}
                    ></EditUser>
                </Modal.Body>
            </Modal>

            <Modal
                show={openModal}
                onHide={() => setOpenModal(false)}
                centered
                className={`modal right-margin`}
            >
                <Modal.Body className='add-cupon-code-modal' >

                    <div className={`add-cupon-code-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                        <div className='cupon-code-header'>הוספת עיסקה באופן ידני </div>
                        <form className='admin-edit-from-wrapper'>
                            {/* add user id, date and time */}
                            <input
                                className="admin-edit-input"
                                type="number"
                                name={'amount'}
                                value={paymentFormData.amount}
                                onChange={HandleChange}
                                placeholder={'סכום העיסקה'}
                            />
                            <input
                                className="admin-edit-input"
                                type="text"
                                name={'paymentOption'}
                                value={paymentFormData.paymentOption}
                                onChange={HandleChange}
                                placeholder={'אמצעי התשלום'}
                            />
                            <input
                            className="admin-edit-input"
                            type="date"
                            name="paymentDate"
                            value={paymentFormData.paymentDate}
                            onChange={HandleChange}
                            placeholder="תאריך התשלום"
                            />
                            <input
                                className="admin-edit-input"
                                type="time"
                                name="paymentTime"
                                value={paymentFormData.paymentTime}
                                onChange={HandleChange}
                                placeholder="שעת התשלום"
                            />
                            <input
                                type="text"
                                className="admin-edit-input"
                                placeholder="חפש אימייל..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />

                            {/* Select Dropdown */}     
                            <select
                                className="admin-edit-input"
                                onChange={HandleChange}
                                name="id"
                            >
                                <option value="">בחר אימייל</option>
                                {filteredEmails.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.email}
                                    </option>
                                ))}
                            </select>

                        </form>
                    </div>

                    <div className='admin-edit-button'>
                        <button
                            type="button"
                            className={`blue-button ${isFormValid() ? '' : 'disabled'} `}
                            onClick={onSubmit}
                            disabled={!isFormValid()}
                        >
                            שמירה
                        </button>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal
                show={openCuponModal}
                onHide={() => setOpenCuponModal(false)}
                centered
                className={`modal right-margin`}
            >
                <Modal.Body className='add-cupon-code-modal' >

                    <div className={`add-cupon-code-wrapper ${isSmallScreen ? 'mobile' : ''}`}>
                        <div className='cupon-code-header'>הוספת מימוש בקוד קופון </div>
                        <form className='admin-edit-from-wrapper'>
                            <input
                                className="admin-edit-input"
                                type="text"
                                name={'cuponCodeName'}
                                value={cuponFormData.cuponCodeName}
                                onChange={CuponHandleChange}
                                placeholder={'שם הקופון'}
                            />
                            <input
                                className="admin-edit-input"
                                type="number"
                                name={'sum'}
                                value={cuponFormData.sum}
                                onChange={CuponHandleChange}
                                placeholder={'סכום אחרי הנחה'}
                            />
                            <input
                                className="admin-edit-input"
                                type="number"
                                name={'sumBeforeCupon'}
                                value={cuponFormData.sumBeforeCupon}
                                onChange={CuponHandleChange}
                                placeholder={'סכום מקורי'}
                            />
                        </form>
                    </div>

                    <div className='admin-edit-button'>
                        <button
                            type="button"
                            className={`blue-button ${isCuponFormValid() ? '' : 'disabled'} `}
                            onClick={onSubmitCupon}
                            disabled={!isCuponFormValid()}
                        >
                            שמירה
                        </button>
                    </div>

                </Modal.Body>
            </Modal>

        </div>
    )
}