// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-container {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: scroll;
}

.sticky-div {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #ffffff;
  padding-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/payment-management/payment-management.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AACA;EACI,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;EACA,MAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;AAEJ","sourcesContent":[".payment-container{\n    display: flex;\n    flex-direction: column;\n    max-height: 100vh;\n    overflow-y: scroll;\n}\n.sticky-div {\n    display: flex;\n    align-items: stretch;\n    flex-direction: row;\n    justify-content: space-between;\n    position: sticky;\n    top: 0;\n    z-index: 1000;\n    background: #ffffff;\n    padding-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
