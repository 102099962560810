import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setScansFolder, fetchScans } from '../../scans/store/action';
import './project-style.scss'
import { smallScreen } from '../../../utils/constants';
import { useLanguage } from '../../../utils/constants';
import { getCurrentUserById, updateUserDontShowAgain } from '../../user/store/action';
import arrowLeft from '../../../utils/assets/errow-left.svg'
import { deleteProject, setProjectId } from '../store/action';
import { useNavigate } from 'react-router-dom';
import trash from '../../../utils/assets/trash.svg'
import Modal from 'react-bootstrap/Modal';
import Spinner from '../../../utils/spinner/spinner';

const Project = ({ proj ,disabledPage}) => {
    const currentUserId = JSON.parse(localStorage.getItem('currentUser'))?.id;
    const currentUser = useSelector((store) => store.userReducer.currentUser)
    const language = useSelector((store) => store.userReducer.language);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const scans = useSelector((store) => store.scanReducer.scans)
    const dispatch = useDispatch();
    const t = useLanguage();
    const isSmallScreen = smallScreen();
    const isCurrentUserEffect = useRef(true)
    const isScansEffect = useRef(true)
    const navigate = useNavigate();
    const [isMouseOver, setIsMouseOver] = useState(null);
    const projectIdToDelete = JSON.parse(sessionStorage.getItem('projectIdToDelete'));
    const buttonRef = useRef(null);
    const [isShowAreYouSure, setIsShowAreYouSure] = useState(false);

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    useEffect(() => {
        if (currentUserId && !currentUser && isCurrentUserEffect.current) {
            dispatch(getCurrentUserById(currentUserId));
            isCurrentUserEffect.current = false;
        }
    }, [currentUserId])


    useEffect(() => {
        if (currentUser && currentUser._id && scans.length == 0 && isScansEffect.current)
            fetchData();
        async function fetchData() {
            await dispatch(fetchScans(currentUser._id));
            isScansEffect.current = false;
        }
    }, [currentUser])


    const allScansInFolder = async () => {
        try {
            const relatedScans = [];
            scans.forEach(scan => {
                if (scan.relatedProject?.includes(proj._id)) {
                    relatedScans.push(scan);
                }
            });
            await dispatch(setProjectId(proj._id));
            console.log('related scane', relatedScans);
            // await dispatch(setScansFolder(relatedScans));
            sessionStorage.setItem('currentProjectId', JSON.stringify(proj._id));
            sessionStorage.setItem('currentProjectName', JSON.stringify(proj.name));
            sessionStorage.setItem('scanType', JSON.stringify(proj.type));

            if (relatedScans) {
                console.log('relatedScans', relatedScans);
                await dispatch(setScansFolder(relatedScans))
                navigate("/scans");
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const deleteProjectFunc = async () => {
        try {
            disabledPage(true);
            setIsShowAreYouSure(false);
            const res = await dispatch(deleteProject(projectIdToDelete, currentUserId));
            console.log('res❣️❣️❣️❣️ ', res);
            disabledPage(false);
        }
        catch (error) {
            console.log(error);
        }
    };
    const activateShowModal = () => {
        console.log('currentUser', currentUser);

        if (currentUser?.dontShowAgainProject == true) {
            deleteProjectFunc()
        }
        else {
            setIsShowAreYouSure(true)
        }

    }

    useEffect(() => {
        if (isShowAreYouSure) {

            buttonRef.current.focus();

            const handleKeyPress = (event) => {
                if (event.key === 'Enter') {
                    deleteProjectFunc()
                }
            };

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }

    }, [isShowAreYouSure]);

    const dontShowAgain = (event) => {
        const checkbox = event.target;

        if (checkbox.checked) {
            dispatch(updateUserDontShowAgain({ userId: currentUserId, parameter: 'dontShowAgainProject' }))
        }
    }

    return (
        <div>
            <div className={`card-custom-project ${isSmallScreen ? 'cardMobile' : 'card'} ${currentLanguage == 'en-US' ? 'ltr' : ''}`}
                type='button'
                onClick={allScansInFolder}
                onMouseOver={() => {
                    setIsMouseOver(proj._id);
                    sessionStorage.setItem('projectIdToDelete', JSON.stringify(proj._id));
                }}
                onMouseOut={() => setIsMouseOver(null)}
            >
                <div className={isSmallScreen ? `ContainerProjectMobile ${currentLanguage == 'en-US' ? 'ltr' : ''}` : 'ContainerProject'} id={`heading-${proj._id}`} >
                    <div className={isSmallScreen ? `write-mobile ${currentLanguage == 'en-US' ? 'text-left' : ''}` : "write"}>
                        {proj.name}
                    </div>

                    <div className='trash-wrapper'>
                        {isMouseOver == proj._id && (
                            <div type='button' onClick={(e) => { e.stopPropagation(); activateShowModal() }}>
                                <img src={trash} />
                            </div>
                        )}
                        <img src={arrowLeft} className={`float-start ErrowUp ${currentLanguage == 'en-US' ? 'ltr' : ''}`} alt="" />
                    </div>

                </div>
            </div>

            {/* are you sure modal */}
            <Modal
                show={isShowAreYouSure}
                onHide={() => setIsShowAreYouSure(false)}
                centered
                className={`modal ${isSmallScreen ? '' : `right-margin ${currentLanguage == 'en-US' ? 'ltr' : ''}`}`}
            >
                <Modal.Body >
                    <div type='button' className={isSmallScreen ? 'are-you-sure mobile' : 'are-you-sure'} >
                        <div className={`back-text ${isSmallScreen ? 'mobile' : ''}`} >{t.uploadScans.areYouSure}</div>
                    </div>
                    <div className={`frame-b ${isSmallScreen ? 'mobile' : ''} ${currentLanguage == 'en-US' ? '' : 'reverse'}`}>
                        <div type="button" className={`btn-ok ${isSmallScreen ? 'mobile' : ''} col-6 `} ref={buttonRef} onClick={deleteProjectFunc}>
                            <div className='ok-button'>
                                <div>{t.uploadScans.ok}</div>
                            </div>
                        </div>
                        <div type="button" className={isSmallScreen ? 'btn-cancel mobile col-6' : 'btn-cancel col-6'} onClick={() => setIsShowAreYouSure(false)}>
                            <div className={isSmallScreen ? 'btn-ok-text cancel mobile ' : 'btn-ok-text cancel'} >{t.uploadScans.cancel}</div>
                        </div>
                    </div>
                    <div className="dont-show">
                        {t.uploadScans.dontShowAgain}
                        <input type="checkbox" onChange={(e) => dontShowAgain(e)}></input>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default Project